td.ant-table-cell-wrapper_disabled {
  background-color: var(--ceeview-background-color-disabled);
}
td.ant-table-cell-wrapper_clickable {
  cursor: pointer;
  color: var(--ceeview-link-color);
}
td.ant-table-cell-wrapper_editable {
  cursor: pointer;
}
td.ant-table-cell-wrapper_editable:hover {
  background-color: green;
}
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table tfoot > tr > th {
  padding: 0 8px;
  height: 40px;
}
.ant-table-wrapper .ant-table-tbody > tr > td,
.ant-table-wrapper .ant-table tfoot > tr > td {
  padding: 0 4px;
  height: 34px;
}
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-wrapper_editing,
.ant-table-wrapper .ant-table tfoot > tr > td.ant-table-cell-wrapper_editing {
  padding: 0;
  display: flex;
}
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-wrapper_editing > *,
.ant-table-wrapper .ant-table tfoot > tr > td.ant-table-cell-wrapper_editing > * {
  flex: 1;
}
