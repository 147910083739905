.api-documentation__method-header {
  display: flex;
  flex-direction: row;
}
.api-documentation__description-label {
  font-weight: bold;
  width: 145px;
  min-width: 145px;
}
.api-documentation__method-header-name {
  font-weight: bold;
  margin-right: 10px;
}
.api-documentation__method-header-description {
  margin-left: auto;
}
.api-documentation__method-parameter-name,
.api-documentation__method-parameter-type,
.api-documentation__method-parameter-description,
.api-documentation__method-parameter-data {
  padding: 0 10px;
  vertical-align: top;
}
.api-documentation__method-parameter-name,
.api-documentation__method-parameter-type {
  width: 150px;
}
.api-documentation__method-parameter-description {
  width: 150px;
}
