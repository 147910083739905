ul,
ol,
dl {
  margin-bottom: 0;
}
.ant-select,
.ant-select-dropdown li {
  font: 12px/1.5 'Oxygen', Arial, sans-serif;
}
.ant-menu-horizontal {
  line-height: 25px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0px 0px;
  padding: 0;
}
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #54595E;
}
.ant-radio-button-wrapper {
  height: 30px;
}
.ant-select-dropdown {
  z-index: 10999;
  position: relative;
}
.ant-app {
  height: 100%;
}
