.action-trigger-switcher__form-entry .qualifier-wizard__icon-label {
  display: flex;
}
.action-trigger-switcher__form-entry .qualifier-wizard__icon-label .label-icon {
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  display: flex;
}
.action-trigger-switcher__form-entry .form-entry__field {
  flex-direction: row-reverse;
  max-width: 190px;
  align-items: center;
}
.action-trigger-switcher__form-entry .form-entry__field .is_major {
  width: 15px;
}
.action-trigger-switcher__form-entry .form-entry__field .cw_status {
  border-radius: 10px;
}
