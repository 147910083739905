.ant-modal-wrapper .ant-modal-wrap {
  pointer-events: none;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper {
  display: flex;
  min-width: 0;
  min-height: 0;
  max-height: 100%;
  height: 100%;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content {
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  width: 100%;
  padding: 0;
  border-radius: 4px;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-close {
  width: 40px;
  height: 40px;
  line-height: 40px;
  top: 0;
  right: 0;
  background-color: #fbfbfb;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-close:hover {
  background-color: hsl(from #fbfbfb h s calc(l - 10));
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-body {
  overflow: auto;
  padding: 0;
  display: flex;
  flex: 1 1 auto;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-body > .ant-modal-wrapper__content {
  display: flex;
  flex: 1;
  flex-flow: column;
  max-width: 100%;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-body > .ant-modal-wrapper__content > .section:not(.section_height_fit-expand-only) {
  flex: 1;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-body > .ant-modal-wrapper__content > .section > .toolbar {
  border-radius: 4px 4px 0 0;
  padding-right: 45px;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-body > .ant-modal-wrapper__content > .section > .toolbar .toolbar__entry_title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-body > .ant-modal-wrapper__content > .section > .toolbar .toolbar__entry_title:first-child {
  margin: 0;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-body > .ant-modal-wrapper__content > .toolbar_appearance_section-header {
  padding-right: 40px;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-footer {
  margin-bottom: 12px;
  padding-right: 12px;
}
.ant-modal-wrapper_custom-footer.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-footer {
  margin-bottom: 0;
  padding-right: 0;
}
.ant-modal-wrapper .ant-modal-wrap .ant-modal .ant-modal-wrapper__wrapper .ant-modal-content .ant-modal-footer > .toolbar {
  margin-left: 12px;
}
.ant-modal-wrapper .ant-modal-wrap .react-resizable {
  display: flex;
  flex-direction: column;
}
.ant-modal-wrapper .ant-modal-wrap .react-resizable__resize-handle {
  position: absolute;
  pointer-events: all;
  z-index: 1;
}
.ant-modal-wrapper .ant-modal-wrap .react-resizable__resize-handle_se {
  height: 10px;
  width: 10px;
  z-index: 2;
  right: 0;
  bottom: 0;
  cursor: se-resize;
}
.ant-modal-wrapper .ant-modal-wrap .react-resizable__resize-handle_sw {
  height: 10px;
  width: 10px;
  z-index: 2;
  left: 0;
  bottom: 0;
  cursor: sw-resize;
}
.ant-modal-wrapper .ant-modal-wrap .react-resizable__resize-handle_nw {
  height: 10px;
  width: 10px;
  z-index: 2;
  left: 0;
  top: 0;
  cursor: nw-resize;
}
.ant-modal-wrapper .ant-modal-wrap .react-resizable__resize-handle_ne {
  height: 10px;
  width: 10px;
  z-index: 2;
  right: 0;
  top: 0;
  cursor: ne-resize;
}
.ant-modal-wrapper .ant-modal-wrap .react-resizable__resize-handle_e {
  height: 100%;
  width: 5px;
  right: 0;
  top: 0;
  cursor: e-resize;
}
.ant-modal-wrapper .ant-modal-wrap .react-resizable__resize-handle_s {
  height: 5px;
  width: 100%;
  left: 0;
  bottom: 0;
  cursor: s-resize;
}
.ant-modal-wrapper .ant-modal-wrap .react-resizable__resize-handle_n {
  height: 5px;
  width: 100%;
  left: 0;
  top: 0;
  cursor: n-resize;
}
.ant-modal-wrapper .ant-modal-wrap .react-resizable__resize-handle_w {
  height: 100%;
  width: 5px;
  left: 0;
  top: 0;
  cursor: w-resize;
}
