.time-widget {
  text-align: center;
}
.time-widget__time {
  font-size: 32px;
  padding: 20px 0;
}
.time-widget__date {
  font-size: 20px;
  padding: 10px 0;
}
.time-widget__timezone {
  font-size: 16px;
  padding: 5px 0;
  border-bottom: 1px solid;
}
.time-widget__last-update {
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  padding: 10px 0 10px 10px;
}
