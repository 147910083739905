.c-grid_fit {
  flex: 1 0 auto;
}
.c-grid .k-grid {
  border: none;
  width: 100%;
  height: 100%;
}
.c-grid .k-grid .k-grid-norecords {
  background-color: #f4f7f9;
  color: #267fb8;
  padding: 15px 0;
}
.c-grid .k-grid .k-grid-norecords .message {
  margin: 0;
}
.c-grid .k-grid.k-grid_no-records-clickable .k-grid-norecords {
  cursor: pointer;
}
.c-grid .k-grid .k-filtercell > .k-operator-hidden {
  padding-right: 0;
}
.c-grid .k-grid .k-filtercell .k-textbox {
  width: 100%;
}
.c-grid .k-grid .drag-source {
  background: left center url("../../../styles/shared/images/app_theme/drag.png") no-repeat;
  width: 15px;
  height: 25px;
  cursor: pointer;
}
