.main-navigation {
  position: relative;
  margin-left: 15px;
}
.main-navigation__menu-wrapper {
  width: 220px;
  background: white;
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.main-navigation__menu {
  width: 100%;
}
.main-navigation__toggle-icon-wrapper {
  cursor: pointer;
  padding: 7px;
  border: solid 1px #257CBA;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #257CBA;
  font-size: 20px;
  overflow: hidden;
}
.main-navigation__toggle-icon-wrapper:hover {
  color: #257CBA;
  background: #DEE8EC;
}
.main-navigation__menu-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.main-navigation__pin,
.main-navigation__spin {
  padding: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
}
.main-navigation__pin:hover,
.main-navigation__spin:hover {
  background: #DEE8EC;
}
.main-navigation__spin {
  font-size: 14px;
}
.main-navigation__icon {
  font-size: 20px;
  line-height: 40px;
  margin-right: 10px;
}
.main-navigation__search {
  min-width: 150px;
  flex-grow: 1;
}
.main-navigation__search-item {
  display: flex;
  gap: 1px;
  height: 20px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.main-navigation__submenu {
  background: red;
}
.main-navigation__submenu .ant-menu-title-content {
  display: inline;
}
.main-navigation__submenu_long.ant-menu-submenu .ant-menu {
  column-count: 3;
  padding: 5px 0;
}
.main-navigation__submenu_long.ant-menu-submenu .ant-menu li.ant-menu-item {
  margin-block: 0;
}
.main-navigation__submenu_long.ant-menu-submenu .ant-menu:before {
  position: absolute;
  visibility: hidden;
}
.main-navigation__search-popup {
  z-index: 100000;
}
.main-navigation__favorites-label {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}
.main-navigation__favorites-title {
  overflow: hidden;
  flex-shrink: 1;
  text-overflow: ellipsis;
  max-width: 200px;
}
.main-navigation__favorites-delete {
  font-size: 18px !important;
  color: #709bba;
}
.main-navigation__favorites-delete:hover {
  color: #69b1ff;
}
.main-navigation_pinned {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  margin-left: 0;
  padding-right: 5px;
}
.main-navigation_pinned .main-navigation__pin .anticon {
  transform: translate(0px, -1px) rotate(-45deg);
}
.main-navigation_pinned .main-navigation__menu-wrapper {
  position: unset;
  width: 100%;
  border: none;
  box-shadow: none;
  padding: 5px 0;
  border-radius: 0;
  flex: 1 0;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.main-navigation_pinned .main-navigation__menu-wrapper_orientation_vertical {
  flex-direction: column;
  justify-content: flex-start;
}
.main-navigation_pinned .main-navigation__menu-wrapper_orientation_vertical .main-navigation__search {
  min-width: 120px;
}
.main-navigation_pinned .main-navigation__menu-wrapper_orientation_horizontal {
  gap: 5px;
  padding-left: 15px;
}
.main-navigation_pinned .main-navigation__menu-wrapper_orientation_horizontal .main-navigation__menu-header {
  padding: 0;
}
.main-navigation_pinned .main-navigation__menu-wrapper_orientation_horizontal .main-navigation__search {
  min-width: 200px;
}
.main-navigation_pinned .main-navigation__menu-wrapper_orientation_horizontal .main-navigation__menu {
  min-width: 545px;
}
.main-navigation_pinned .main-navigation__menu-wrapper_orientation_horizontal .main-navigation__icon_name_home {
  margin: 0px 5px;
}
.main-navigation_pinned .main-navigation__menu-wrapper_orientation_horizontal .main-navigation__icon_name_home + span {
  display: none;
}
.main-navigation_pinned .main-navigation__menu {
  border: none;
  width: auto;
}
.main-navigation_pinned .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu,
.main-navigation_pinned .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 8px;
}
.main-navigation_pinned .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:after,
.main-navigation_pinned .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:after {
  inset-inline: 2px;
}
.main-navigation_pinned .ant-menu-horizontal .ant-menu-item,
.main-navigation_pinned .ant-menu-horizontal .ant-menu-submenu-title {
  height: 100%;
  display: flex;
  align-items: center;
}
.main-navigation_pinned .ant-menu-horizontal .ant-menu-title-content {
  font-weight: bold;
}
