div.new-budget-window__content {
  overflow: visible;
  height: auto;
}
div.new-budget-window__validation-message {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}
div.new-budget-window__validation-message .section {
  width: 100%;
}
div.new-budget-window__validation-message .anticon-info-circle {
  font-size: 20px;
}
