.form-layout {
  display: grid;
  grid-gap: 16px;
}
.form-layout_2-columns {
  grid-template-columns: 1fr 1fr;
}
.form-layout_3-columns {
  grid-template-columns: 1fr 1fr 1fr;
}
