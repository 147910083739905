.home-view {
  display: flex;
  flex-direction: column;
}
.home-view__widgets {
  flex: 0 0 238px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(200px, 1fr);
  grid-gap: 20px;
}
.home-view__widgets_only-bars {
  flex: 0 0 120px;
}
.home-view__main-content {
  margin-top: 15px;
  flex: 1;
}
.home-view .k-input,
.home-view .k-picker {
  width: auto;
}
.home-view .cw_new_tag_indicator {
  margin-top: -3px;
}
.home-view-widget {
  display: flex;
  flex-direction: column;
}
.home-view-widget__footer {
  padding: 0 15px;
  height: 40px;
  background-color: #fbfbfb;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #fff;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
