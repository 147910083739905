.link-cost-profile-row-window__has-hierarchy {
  flex-grow: 0;
}
.link-cost-profile-row-window__loader {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: white;
  position: absolute;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}
