.ai-query-widget-configuration__text-output-container {
  position: relative;
}
.ai-query-widget-configuration__text-output-info {
  position: absolute;
  top: 100px;
  left: 20px;
  right: 20px;
  z-index: var(--ceeview-z-index-popover);
}
