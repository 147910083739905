.event-summary-details {
  margin-top: 0 !important;
}
.event-summary-details__content {
  margin: 0;
}
.event-summary-details__link {
  cursor: pointer;
}
.event-summary-details input[class*="link"] {
  color: #709bba !important;
}
