.ant-select-droppable-wrapper {
  display: flex;
}
.ant-select-droppable-wrapper > .ant-select {
  flex: 1 1 auto;
}
.ceeview-ant-select .ant-select-selection-item-remove {
  padding: 0 5px;
}
.ceeview-ant-select .ant-tag {
  display: flex;
}
.ceeview-ant-select .ant-tag__closable {
  padding-right: 0;
}
.ceeview-ant-select .ant-tag .anticon.anticon-holder {
  margin-right: 5px;
}
.ceeview-ant-select .ant-tag .ant-tag-close-icon {
  padding: 0 6px;
  margin-inline-start: 0;
  margin-top: 2px;
}
.ceeview-ant-select_layout_one-line.ant-select-multiple .ant-select-selection-overflow {
  flex-wrap: nowrap;
  overflow: hidden;
}
.ant-select {
  height: 30px;
}
.ant-select.ant-select-multiple {
  height: fit-content;
}
