.cw_configuration_attachment_notification {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border: 1px solid #d9edf7;
  line-height: 25px;
  border-radius: 5px;
  background-color: #d9edf7;
  font-weight: bold;
  color: #1f6d93;
  cursor: pointer;
}
.cw_configuration_attachment_notification_textarea {
  width: 100%;
  height: 100%;
}
.cw_configuration_attachment_notification_window .k-window-content {
  overflow: hidden;
}
.high-zindex {
  z-index: 100007 !important;
}
.high-zindex.config-editor-window .ant-spin-nested-loading {
  width: 100%;
}
