.user-tags__icon {
  font-size: 21px;
  color: #aaa;
  padding: 2px;
}
.user-tags__icon_active {
  color: #1890ff;
  outline: 2px solid #1890ff;
}
.user-tags__icon-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 13px 8px;
}
.cw_userinfo > .cw_item.user-tags__panel-item {
  padding: 0px;
}
