.profile-metric-window__content {
  display: flex;
}
.profile-metric-window__content > .section > .section__content {
  padding-bottom: 30px;
}
.profile-metric-window__metric-cell {
  position: relative;
  padding-top: 30px;
  display: flex;
  flex-grow: 1;
}
.profile-metric-window__metric-cell .cw_widget_info_sign {
  display: none;
}
.profile-metric-window__metric-cell .cw_section_content {
  height: 100%;
}
.profile-metric-window__metric-cell .period_multi_toggle {
  right: 0;
  top: 0;
}
.profile-metric-window__metric-cell::after {
  content: "";
  display: table;
  clear: both;
}
.profile-metric-window__metric-type {
  position: absolute;
  z-index: 2;
  max-width: 250px;
  min-width: 70px;
}
