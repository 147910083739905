.generic-metric-widget-configuration {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.generic-metric-widget-configuration .form-entry {
  max-width: unset;
}
.generic-metric-widget-configuration .k-multiselect .k-button > span:first-child {
  max-width: 260px !important;
}
.generic-metric-widget-configuration .k-picker .k-input-value-text {
  display: flex;
}
.display-settings-frame__nowrap {
  display: flex;
  flex-direction: row;
}
.display-settings-frame__buffer {
  flex-grow: 1;
  flex-basis: 0;
}
.display-settings-frame .c-checkbox {
  margin-top: 3px;
}
.display-settings-frame__custom-time-selector {
  margin: 10px 0 10px 150px;
}
.display-settings-frame__custom-time-selector .k-datetimepicker:before {
  content: "*";
  position: absolute;
  top: 0px;
  left: 4px;
  color: red;
  line-height: initial;
  z-index: 999909;
}
.metric-window {
  height: 100%;
}
.metric-window__switchContainer {
  margin-left: 5px;
}
.metric-window__switcher {
  display: flex;
}
.metric-window .k-grid-content {
  overflow-y: visible;
}
.metric-window__grid-wrapper {
  height: calc(100% - 80px);
  overflow-y: hidden;
}
.metric-window__grid {
  height: 100%;
}
.metric-window__grid .k-grid-content-locked {
  height: 388px;
}
.metric-window__grid-container {
  height: 100%;
}
.metric-window__toolbar {
  height: 40px;
}
