.sqinfo-form {
  padding: 20px;
}
.sqinfo-form__row {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}
.sqinfo-form__label {
  min-width: 100px;
  width: 100px;
  text-align: right;
}
.sqinfo-form__content {
  margin-left: 10px;
  font-weight: bold;
  word-break: break-all;
}
.sq-form-field-tooltip {
  margin-left: 8px;
}
