.shapes-collection-user-shape {
  position: relative;
  display: flex;
  flex-direction: column;
}
.shapes-collection-user-shape__delete {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
}
.shapes-collection-user-shape__title {
  text-align: center;
  cursor: pointer;
}
