.severity-indicator {
  display: flex;
  align-items: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
}
.severity-indicator_severity_critical {
  background-color: var(--ceeview-severity-critical);
}
.severity-indicator_severity_major {
  background-color: var(--ceeview-severity-major);
}
.severity-indicator_severity_minor {
  background-color: var(--ceeview-severity-minor);
}
.severity-indicator_severity_none {
  background-color: var(--ceeview-severity-none);
}
.severity-indicator_severity_invalid {
  background-color: var(--ceeview-severity-invalid);
}
.severity-indicator > .icon-button {
  margin: 0;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.severity-indicator > .anticon {
  width: 16px;
  height: 16px;
  color: white;
  font-size: 16px;
}
.severity-indicator > .anticon.anticon-tool {
  height: 14px;
  font-size: 14px;
}
.severity-indicator_clickable {
  cursor: pointer;
}
.severity-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.severity-label__label {
  margin-left: 10px;
}
.severity-label_clickable {
  cursor: pointer;
}
.severity-pillbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
  background: #f5f5f5;
  border: 1px solid #d8d8d8;
  padding: 0 1px;
  border-radius: 18px;
  margin: auto;
}
.severity-pillbox__content {
  padding: 0 5px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  min-width: 30px;
}
.severity-pillbox_clickable {
  cursor: pointer;
}
