.dynamic-item-selector .form-entry-new__additional-content {
  right: 50px;
  z-index: 2;
}
.dynamic-item-selector__extra {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5px;
}
.dynamic-item-selector .box-view {
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  z-index: 1;
  margin-right: 37px;
  height: 34px;
  white-space: nowrap;
}
.selector-with-table-wrapper {
  flex-shrink: 1;
}
.selector-with-table-wrapper .ant-select {
  flex-grow: 1;
}
.selector-with-table-wrapper > .section__content {
  align-items: center;
  gap: 5px;
}
.service-summary-severity-indicator__tag-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 2px 0;
  gap: 3px;
}
