.event-summary__assignee-type {
  font-size: 20px;
}
.event-summary_historic-row.severity-indicator {
  position: relative;
}
.event-summary_historic-row.severity-indicator:after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: -4px;
  width: calc(100% + 8px);
  height: 2px;
  background-color: black;
}
