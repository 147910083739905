.ant-tabs-wrapper.ant-tabs {
  font-family: unset;
  font-size: unset;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs-tab {
  background-color: #FFF;
  border-bottom: 1px solid #f0f0f0;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs-nav .ant-tabs-nav-add {
  background-color: #fafafa;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs > .ant-tabs-nav,
.ant-tabs-wrapper.ant-tabs .ant-tabs > div > .ant-tabs-nav {
  padding: 0 15px;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs-content-holder {
  display: flex;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs-content-holder .ant-tabs-content {
  flex: 1 0 auto;
  display: flex;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs-tabpane {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1 1 auto;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs-tabpane_dragging {
  opacity: 0.5;
}
.ant-tabs-wrapper.ant-tabs .ant-tabs-tabpane.ant-tabs-tabpane-hidden {
  display: none;
}
.ant-tabs-dropdown {
  z-index: var(--ceeview-z-index-control-popover);
}
