@keyframes blink {
  0% {
    background: white;
  }
  40% {
    background: #d6f4ff;
  }
  90% {
    background: white;
  }
}
.budget-table {
  --budget-table-font-size: 12px;
  --budget-table-default-font-color: #262626;
  --budget-table-line-1-color: #e8e8e8;
  --budget-table-line-2-color: gray;
  --budget-table-row-height: 40px;
  --budget-table-name-column-width: 250px;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
}
.budget-table .react-resizable {
  position: relative;
  background-clip: padding-box;
}
.budget-table .react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 20;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
.budget-table .ant-table,
.budget-table .ant-table-thead > tr > th {
  font-size: var(--budget-table-font-size);
  color: var(--budget-table-font-color, var(--budget-table-default-font-color));
}
.budget-table .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: var(--budget-table-bg-color, #f5f5f5);
}
.budget-table .ant-table-thead > tr > th {
  background-color: var(--budget-table-bg-color, #fafafa);
}
.budget-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
  border-color: var(--budget-table-line-1-color);
}
.budget-table__expand-button {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #e8e8e8;
  border-radius: 4px;
  background: transparent;
  font-size: 12px;
}
.budget-table__expand-button:hover {
  color: #709bba;
  border: solid 1px #709bba;
}
.budget-table__vcell {
  display: flex;
  padding: 4px 8px;
  border-bottom: 1px solid var(--budget-table-line-1-color);
  border-right: 1px solid var(--budget-table-line-1-color);
  flex-direction: row;
  align-items: center;
  height: var(--budget-table-row-height);
  box-sizing: border-box;
  float: right;
  background-color: var(--budget-table-bg-color, white);
}
.budget-table__vcell_level_1 {
  background-color: var(--budget-table-bg-color, #FAFAFA);
}
.budget-table__vcell_level_2 {
  background-color: var(--budget-table-bg-color, #FAFAFA);
  color: var(--budget-table-font-color, gray);
}
.budget-table__vcell-wrapper {
  overflow: visible;
}
.budget-table__cell_column_name.ant-table-cell {
  left: 50px !important;
}
.budget-table .ant-table-wrapper {
  height: 100%;
}
.budget-table__action-cell-content {
  width: 100%;
  display: grid;
  text-align: right;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 3px;
}
.budget-table__action {
  grid-row: 1/2;
}
.budget-table__action_add {
  grid-column: 5/6;
}
.budget-table__action_import {
  grid-column: 6/7;
}
.budget-table__action_delete {
  grid-column: 7/8;
}
.budget-table__action_threshold {
  grid-column: 1/2;
}
.budget-table__action_cost-metric {
  grid-column: 2/3;
}
.budget-table__action_generic-metric {
  grid-column: 3/4;
}
.budget-table__action_event-summary-icon {
  grid-column: 4/5;
  padding-top: 3px;
}
.budget-table__cell_column_name {
  border-right-color: var(--budget-table-line-2-color) !important;
}
.budget-table__cell_column_action {
  border-left: solid 1px var(--budget-table-line-2-color) !important;
}
.budget-table__cell_column_budget,
.budget-table__cell_column_cost,
.budget-table__cell_column_estimate,
.budget-table__cell_column_split,
.budget-table__cell_column_listingPrice {
  text-align: right;
}
.budget-table__cell.accent-border,
.budget-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th.accent-border {
  border-right-color: var(--budget-table-line-2-color);
}
.budget-table__vcell_level_2 .budget-table__action_add,
.budget-table__vcell_level_2 .budget-table__action_import {
  display: none;
}
.budget-table .budget-table__vcell_column_name,
.budget-table .budget-table__vcell_column_expand,
.budget-table .budget-table__vcell_column_action {
  position: sticky;
}
.budget-table .budget-table__vcell_column_name,
.budget-table .budget-table__vcell_column_expand {
  float: left;
}
.budget-table .budget-table__vcell_column_expand {
  left: 0;
  width: 50px;
  border-right: none;
  border-left: solid 1px transparent;
}
.budget-table .budget-table__vcell_column_expand.budget-table__vcell_level_1 {
  padding-left: 15px;
  width: 65px;
}
.budget-table .budget-table__vcell_column_expand.budget-table__vcell_level_2 {
  padding-left: 30px;
  width: 80px;
}
.budget-table .budget-table__vcell_column_expand.budget-table__vcell_level_3 {
  padding-left: 45px;
  width: 95px;
}
.budget-table .budget-table__vcell_column_expand.budget-table__vcell_level_4 {
  padding-left: 60px;
  width: 110px;
}
.budget-table .budget-table__vcell_column_expand.budget-table__vcell_level_5 {
  padding-left: 75px;
  width: 125px;
}
.budget-table .budget-table__vcell_column_expand.budget-table__vcell_level_6 {
  padding-left: 90px;
  width: 140px;
}
.budget-table .budget-table__vcell_column_expand.budget-table__vcell_level_7 {
  padding-left: 105px;
  width: 155px;
}
.budget-table .budget-table__vcell_column_expand.budget-table__vcell_level_8 {
  padding-left: 120px;
  width: 170px;
}
.budget-table .budget-table__vcell_column_name {
  border-right: solid 1px var(--budget-table-line-2-color);
  left: 50px;
  width: var(--budget-table-name-column-width);
}
.budget-table .budget-table__vcell_column_name.budget-table__vcell_level_1 {
  left: 65px;
  width: calc(var(--budget-table-name-column-width) - 15px * 1);
}
.budget-table .budget-table__vcell_column_name.budget-table__vcell_level_2 {
  left: 80px;
  width: calc(var(--budget-table-name-column-width) - 15px * 2);
}
.budget-table .budget-table__vcell_column_name.budget-table__vcell_level_3 {
  left: 95px;
  width: calc(var(--budget-table-name-column-width) - 15px * 3);
}
.budget-table .budget-table__vcell_column_name.budget-table__vcell_level_4 {
  left: 110px;
  width: calc(var(--budget-table-name-column-width) - 15px * 4);
}
.budget-table .budget-table__vcell_column_name.budget-table__vcell_level_5 {
  left: 125px;
  width: calc(var(--budget-table-name-column-width) - 15px * 5);
}
.budget-table .budget-table__vcell_column_name.budget-table__vcell_level_6 {
  left: 140px;
  width: calc(var(--budget-table-name-column-width) - 15px * 6);
}
.budget-table .budget-table__vcell_column_name.budget-table__vcell_level_7 {
  left: 155px;
  width: calc(var(--budget-table-name-column-width) - 15px * 7);
}
.budget-table .budget-table__vcell_column_name.budget-table__vcell_level_8 {
  left: 170px;
  width: calc(var(--budget-table-name-column-width) - 15px * 8);
}
.budget-table .budget-table__vcell_column_action {
  margin-left: auto;
  right: 0px;
  padding-left: 0;
  padding-right: 0;
  border-left: solid 1px gray;
}
.budget-table .budget-table__vcell_column_action .editable-cell {
  padding-right: 11px;
}
.budget-table__rate-value_negative {
  color: green;
}
.budget-table__rate-value_positive {
  color: red;
}
.budget-table__name-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.budget-table__name-content-main {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.budget-table__total-row .budget-table__vcell {
  background-color: var(--budget-table-bg-color, white);
  border-bottom: solid 1px var(--budget-table-line-2-color);
}
.budget-table__row {
  background-color: var(--budget-table-bg-color, white);
}
.budget-table__row_level_1,
.budget-table__row_level_2 {
  background-color: var(--budget-table-bg-color, #FAFAFA);
}
.budget-table__row_deleted .budget-table__vcell:after {
  content: ' ';
  display: block;
  position: absolute;
  border-bottom: solid 1px var(--budget-table-font-color, var(--budget-table-default-font-color));
  width: 100%;
  left: 0;
  top: 50%;
  pointer-events: none;
}
.budget-table__row_deleted .budget-table__vcell.budget-table__cell_column_action:after {
  display: none;
}
.budget-table__row_highlight .budget-table__vcell {
  animation: blink 0.8s;
  animation-iteration-count: 5;
}
.budget-table__row_selected .budget-table__vcell {
  border-top: solid 1px #00aeef;
  border-bottom: solid 1px #00aeef;
}
.budget-table__row_selected .budget-table__vcell.budget-table__cell_column_expand {
  border-left: solid 1px #00aeef;
}
.budget-table__row_selected .budget-table__vcell.budget-table__cell_column_action {
  border-right: solid 1px #00aeef;
}
.budget-table .ant-table-column-title {
  white-space: nowrap;
}
.budget-table .ant-table-column-title {
  white-space: nowrap;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td.budget-table__cell_column_action {
  border-right: 0;
}
.budget-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr.ant-table-expanded-row > td {
  border-right: 0;
}
.budget-table .editable-cell {
  position: relative;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
.budget-table .editable-cell_editable_false {
  font-weight: bold;
  padding-left: 11px;
  padding-right: 16px;
}
.budget-table .editable-cell_editable_true {
  font-weight: normal;
}
.budget-table .editable-cell__input {
  width: 100%;
}
.budget-table .editable-cell__input_invalid {
  border: solid 1px #E1B4AF;
}
.budget-table .editable-cell__value-wrap {
  text-overflow: ellipsis;
  padding: 6px 12px 5px 12px;
  cursor: pointer;
  display: block;
  align-items: center;
  min-height: 30px;
  overflow: hidden;
}
.budget-table__table_level_2 tr > td.editable-cell,
.budget-table__table_level_1 tr > td.editable-cell {
  font-weight: normal;
}
.budget-table .editable-cell:hover .editable-cell__value-wrap {
  padding: 5px 11px 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.budget-table .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}
.budget-table .ant-table-tbody td.ant-table-row-expand-icon-cell {
  width: 40px;
  padding-left: 14px;
}
.budget-table tr.ant-table-expanded-row > td {
  padding: 0;
}
.budget-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.budget-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: none;
}
.budget-table .ant-table.ant-table-bordered > .ant-table-container {
  border-left: none;
}
.budget-table__asset-link > span {
  margin-top: 3px;
}
.budget-table .ant-table-cell-fix-right-first::after {
  opacity: 0;
}
.budget-table__cell-content {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: bold;
  padding-left: 11px;
  padding-right: 12px;
  white-space: nowrap;
}
.budget-table__cell-content_align_right {
  text-align: right;
}
.budget-table_compact {
  --budget-table-name-column-width: 170px;
}
.budget-table_compact .budget-table__vcell {
  padding: 2px 5px;
}
.budget-table_compact .budget-table__cell-content {
  padding: 2px 5px;
}
.budget-table_compact .ant-table-wrapper .ant-table-thead > tr > th {
  padding: 0 5px;
}
.budget-table_compact .budget-table__cell_column_name.ant-table-cell {
  left: 40px !important;
}
.budget-table_compact .budget-table__vcell_column_expand {
  width: 40px;
}
.budget-table_compact .budget-table__vcell_column_expand.budget-table__vcell_level_1 {
  padding-left: 10px;
  width: 50px;
}
.budget-table_compact .budget-table__vcell_column_expand.budget-table__vcell_level_2 {
  padding-left: 20px;
  width: 60px;
}
.budget-table_compact .budget-table__vcell_column_expand.budget-table__vcell_level_3 {
  padding-left: 30px;
  width: 70px;
}
.budget-table_compact .budget-table__vcell_column_expand.budget-table__vcell_level_4 {
  padding-left: 40px;
  width: 80px;
}
.budget-table_compact .budget-table__vcell_column_expand.budget-table__vcell_level_5 {
  padding-left: 50px;
  width: 90px;
}
.budget-table_compact .budget-table__vcell_column_expand.budget-table__vcell_level_6 {
  padding-left: 60px;
  width: 100px;
}
.budget-table_compact .budget-table__vcell_column_expand.budget-table__vcell_level_7 {
  padding-left: 70px;
  width: 110px;
}
.budget-table_compact .budget-table__vcell_column_expand.budget-table__vcell_level_8 {
  padding-left: 80px;
  width: 120px;
}
.budget-table_compact .budget-table__vcell_column_name {
  left: 40px;
}
.budget-table_compact .budget-table__vcell_column_name.budget-table__vcell_level_1 {
  left: 50px;
  width: calc(var(--budget-table-name-column-width) - 10px * 1);
}
.budget-table_compact .budget-table__vcell_column_name.budget-table__vcell_level_2 {
  left: 60px;
  width: calc(var(--budget-table-name-column-width) - 10px * 2);
}
.budget-table_compact .budget-table__vcell_column_name.budget-table__vcell_level_3 {
  left: 70px;
  width: calc(var(--budget-table-name-column-width) - 10px * 3);
}
.budget-table_compact .budget-table__vcell_column_name.budget-table__vcell_level_4 {
  left: 80px;
  width: calc(var(--budget-table-name-column-width) - 10px * 4);
}
.budget-table_compact .budget-table__vcell_column_name.budget-table__vcell_level_5 {
  left: 90px;
  width: calc(var(--budget-table-name-column-width) - 10px * 5);
}
.budget-table_compact .budget-table__vcell_column_name.budget-table__vcell_level_6 {
  left: 100px;
  width: calc(var(--budget-table-name-column-width) - 10px * 6);
}
.budget-table_compact .budget-table__vcell_column_name.budget-table__vcell_level_7 {
  left: 110px;
  width: calc(var(--budget-table-name-column-width) - 10px * 7);
}
.budget-table_compact .budget-table__vcell_column_name.budget-table__vcell_level_8 {
  left: 120px;
  width: calc(var(--budget-table-name-column-width) - 10px * 8);
}
.budget-table_compact .budget-table__action-cell-content {
  grid-template-columns: repeat(3, 1fr);
}
.budget-table_compact .budget-table__action {
  grid-row: 1/2;
}
.budget-table_compact .budget-table__action_cost-metric {
  grid-column: 1/2;
}
.budget-table_compact .budget-table__action_generic-metric {
  grid-column: 2/3;
}
.budget-table_compact .budget-table__action_event-summary-icon {
  grid-column: 3/4;
  padding-top: 3px;
}
.budget-table__name-content-main_targetType_metric {
  color: #00a716;
}
.geEditor .html-shape-container .ant-table-row-expand-icon {
  box-sizing: content-box;
}
