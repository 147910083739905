.c-textbox {
  width: 100%;
}
.c-textbox[type="number"] {
  -moz-appearance: textfield;
}
.c-textbox__wrapper {
  width: 100%;
}
.c-textbox__wrapper.invalid-field:after input {
  content: "*";
  position: relative;
  right: 10px;
  color: red;
  line-height: initial;
}
.c-textbox__wrapper input::placeholder {
  padding-left: 8px;
  font-size: 12px;
  color: #bfbfbf;
}
.large-class {
  width: 210px;
}
