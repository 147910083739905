.cw_service_details_module .k-splitter,
.cw_sladetails_wrapper .k-splitter,
.cw_content_wrapper .k-splitter,
.cw_reportdetails_wrapper .k-splitter,
.script-wrapper .k-splitter,
.cw_kpidetails_wrapper .k-splitter,
.cw_automation_details .k-splitter,
.application_container .k-splitter,
.cw_agentdetails_wrapper .k-splitter,
.admin_accountdetails_cont .k-splitter,
.ruleset-wrapper .k-splitter,
.cost-profile-details .k-splitter,
.cw_connectivity_monitor .k-splitter {
  overflow-x: visible;
  justify-content: flex-start;
}
.cw_service_details_module .k-splitter-overlay,
.cw_sladetails_wrapper .k-splitter-overlay,
.cw_content_wrapper .k-splitter-overlay,
.cw_reportdetails_wrapper .k-splitter-overlay,
.script-wrapper .k-splitter-overlay,
.cw_kpidetails_wrapper .k-splitter-overlay,
.cw_automation_details .k-splitter-overlay,
.application_container .k-splitter-overlay,
.cw_agentdetails_wrapper .k-splitter-overlay,
.admin_accountdetails_cont .k-splitter-overlay,
.ruleset-wrapper .k-splitter-overlay,
.cost-profile-details .k-splitter-overlay,
.cw_connectivity_monitor .k-splitter-overlay {
  z-index: 4998;
}
.cw_service_details_module .k-ghost-splitbar,
.cw_sladetails_wrapper .k-ghost-splitbar,
.cw_content_wrapper .k-ghost-splitbar,
.cw_reportdetails_wrapper .k-ghost-splitbar,
.script-wrapper .k-ghost-splitbar,
.cw_kpidetails_wrapper .k-ghost-splitbar,
.cw_automation_details .k-ghost-splitbar,
.application_container .k-ghost-splitbar,
.cw_agentdetails_wrapper .k-ghost-splitbar,
.admin_accountdetails_cont .k-ghost-splitbar,
.ruleset-wrapper .k-ghost-splitbar,
.cost-profile-details .k-ghost-splitbar,
.cw_connectivity_monitor .k-ghost-splitbar {
  z-index: 4999;
  opacity: 0.6;
}
.cw_service_details_module .k-splitbar-horizontal .k-collapse-prev,
.cw_sladetails_wrapper .k-splitbar-horizontal .k-collapse-prev,
.cw_content_wrapper .k-splitbar-horizontal .k-collapse-prev,
.cw_reportdetails_wrapper .k-splitbar-horizontal .k-collapse-prev,
.script-wrapper .k-splitbar-horizontal .k-collapse-prev,
.cw_kpidetails_wrapper .k-splitbar-horizontal .k-collapse-prev,
.cw_automation_details .k-splitbar-horizontal .k-collapse-prev,
.application_container .k-splitbar-horizontal .k-collapse-prev,
.cw_agentdetails_wrapper .k-splitbar-horizontal .k-collapse-prev,
.admin_accountdetails_cont .k-splitbar-horizontal .k-collapse-prev,
.ruleset-wrapper .k-splitbar-horizontal .k-collapse-prev,
.cost-profile-details .k-splitbar-horizontal .k-collapse-prev,
.cw_connectivity_monitor .k-splitbar-horizontal .k-collapse-prev,
.cw_service_details_module .k-splitbar-horizontal .k-expand-prev,
.cw_sladetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_content_wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_reportdetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.script-wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_kpidetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_automation_details .k-splitbar-horizontal .k-expand-prev,
.application_container .k-splitbar-horizontal .k-expand-prev,
.cw_agentdetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.admin_accountdetails_cont .k-splitbar-horizontal .k-expand-prev,
.ruleset-wrapper .k-splitbar-horizontal .k-expand-prev,
.cost-profile-details .k-splitbar-horizontal .k-expand-prev,
.cw_connectivity_monitor .k-splitbar-horizontal .k-expand-prev {
  display: block;
  width: 24px;
  height: 24px;
  z-index: 4999;
  border: 1px solid #aaa;
  border-radius: 50%;
  padding-top: 1px;
  margin-top: 11px;
  background: white;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  top: 4%;
  left: -4px;
  font-size: 20px;
  color: #424242;
  top: 0px;
}
.cw_service_details_module .k-splitbar-horizontal .k-expand-prev,
.cw_sladetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_content_wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_reportdetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.script-wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_kpidetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.cw_automation_details .k-splitbar-horizontal .k-expand-prev,
.application_container .k-splitbar-horizontal .k-expand-prev,
.cw_agentdetails_wrapper .k-splitbar-horizontal .k-expand-prev,
.admin_accountdetails_cont .k-splitbar-horizontal .k-expand-prev,
.ruleset-wrapper .k-splitbar-horizontal .k-expand-prev,
.cost-profile-details .k-splitbar-horizontal .k-expand-prev,
.cw_connectivity_monitor .k-splitbar-horizontal .k-expand-prev {
  left: -6px;
}
.cw_service_details_module .placeholder,
.cw_sladetails_wrapper .placeholder,
.cw_content_wrapper .placeholder,
.cw_reportdetails_wrapper .placeholder,
.script-wrapper .placeholder,
.cw_kpidetails_wrapper .placeholder,
.cw_automation_details .placeholder,
.application_container .placeholder,
.cw_agentdetails_wrapper .placeholder,
.admin_accountdetails_cont .placeholder,
.ruleset-wrapper .placeholder,
.cost-profile-details .placeholder,
.cw_connectivity_monitor .placeholder {
  position: absolute;
  height: 100%;
  width: 20px;
  background: white;
  margin-top: -40px;
  z-index: 100;
}
.cw_service_details_module .placeholder .label,
.cw_sladetails_wrapper .placeholder .label,
.cw_content_wrapper .placeholder .label,
.cw_reportdetails_wrapper .placeholder .label,
.script-wrapper .placeholder .label,
.cw_kpidetails_wrapper .placeholder .label,
.cw_automation_details .placeholder .label,
.application_container .placeholder .label,
.cw_agentdetails_wrapper .placeholder .label,
.admin_accountdetails_cont .placeholder .label,
.ruleset-wrapper .placeholder .label,
.cost-profile-details .placeholder .label,
.cw_connectivity_monitor .placeholder .label {
  transform: rotate(90deg) translate(105px, -2px);
  font-weight: bold;
}
.k-ghost-splitbar-horizontal {
  z-index: 10000;
}
