.grid-column-sorting-indicator {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 11px;
  color: #bfbfbf;
}
.grid-column-sorting-indicator__caret-up_selected {
  color: #1677ff;
}
.grid-column-sorting-indicator__caret-down {
  margin-top: -0.3em;
}
.grid-column-sorting-indicator__caret-down_selected {
  color: #1677ff;
}
