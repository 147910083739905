.metrics-conversion-form__validate-button {
  position: absolute;
  top: -26px;
  right: 0px;
  padding: 1px 12px;
}
.metrics-conversion-form__conversion-formula-tooltip {
  position: absolute;
  margin: -23px 0 0 70px;
  font-size: 16px;
}
