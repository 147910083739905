.chat__message-section {
  position: relative;
}
.chat__show-prompts-button-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}
.chat__prompts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3px;
}
.chat__prompt-entry {
  border: 1px solid var(--ant-color-border);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}
.chat__prompt-entry:hover {
  border-color: var(--ant-color-primary-hover);
}
.chat__send-button {
  position: absolute;
  top: 5px;
  right: 5px;
}
