.chat-history__message {
  max-width: 80%;
  margin-bottom: 10px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  white-space: break-spaces;
}
.chat-history__message_direction_to {
  align-self: flex-start;
  color: rgba(0, 0, 0, 0.87);
  background-color: var(--ant-color-bg-base);
}
.chat-history__message_direction_from {
  align-self: flex-end;
  color: #ffffff;
  background-color: var(--ant-color-primary);
}
.chat-history__typing-indicator {
  font-size: smaller;
  width: 80%;
  text-align: center;
}
