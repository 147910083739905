:root {
  --ceeview-font-family: 'Oxygen', Arial, sans-serif;
  --ceeview-font-size: 12px/1.5;
  --ceeview-severity-critical: #da1e28;
  --ceeview-severity-critical-lighter: hsl(from var(--ceeview-severity-critical) h s calc(l + 10));
  --ceeview-severity-critical-darker: hsl(from var(--ceeview-severity-critical) h s calc(l - 10));
  --ceeview-severity-major: #ff832b;
  --ceeview-severity-major-lighter: hsl(from var(--ceeview-severity-major) h s calc(l + 10));
  --ceeview-severity-major-darker: hsl(from var(--ceeview-severity-major) h s calc(l - 10));
  --ceeview-severity-minor: #f1c21b;
  --ceeview-severity-minor-lighter: hsl(from var(--ceeview-severity-minor) h s calc(l + 10));
  --ceeview-severity-minor-darker: hsl(from var(--ceeview-severity-minor) h s calc(l - 10));
  --ceeview-severity-none: #24A148;
  --ceeview-severity-none-lighter: hsl(from var(--ceeview-severity-none) h s calc(l + 10));
  --ceeview-severity-none-darker: hsl(from var(--ceeview-severity-none) h s calc(l - 10));
  --ceeview-severity-invalid: #6f6f6f;
  --ceeview-severity-invalid-lighter: hsl(from var(--ceeview-severity-invalid) h s calc(l + 10));
  --ceeview-severity-invalid-darker: hsl(from var(--ceeview-severity-invalid) h s calc(l - 10));
  --ceeview-text-color: #555;
  --ceeview-link-color: #709bba;
  --ceeview-background-color-disabled: #d8d8d8;
  --ceeview-validation-ok-color: var(--ceeview-severity-none);
  --ceeview-validation-error-color: var(--ceeview-severity-critical);
  --ceeview-validation-unknown-color: var(--ceeview-text-color);
  --ceeview-section-content-padding: 15px;
}
