.numeric-textbox_percent {
  width: 40px;
  height: 20px;
  line-height: 20px;
  border-radius: 3px;
  text-align: center;
  margin-left: 10px;
}
.numeric-textbox_percent .k-input {
  height: 23px;
}
.numeric-textbox_percent .k-numeric-wrap {
  height: 25px;
  width: 43px;
}
.numeric-textbox__wrapper {
  width: 100%;
}
.numeric-textbox__wrapper.invalid-field input {
  background: #F2DEDE;
  color: #B94A48;
  text-shadow: 0 1px 0 #fff;
}
