.metric-value-as-severity__indicators {
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}
.metric-value-as-severity__indicators .form-entry-new {
  height: 24px;
}
.metric-value-as-severity__indicators .form-entry-new .ant-input-number {
  min-width: 35px;
  width: 100%;
}
.metric-value-as-severity__severity-operator {
  font-weight: bold;
  font-size: 14px;
}
