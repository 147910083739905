.c-textarea {
  border: 1px solid #d8d8d8;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.c-textarea_invalid {
  color: #B94A48;
  text-shadow: 0 1px 0 #fff;
}
.c-textarea:focus {
  box-shadow: 0 0 7px 0 #76abd9;
  -webkit-box-shadow: 0 0 7px 0 #76abd9;
  outline: none !important;
}
.c-textarea_height_full {
  height: 100%;
}
