div.stackchart-title {
  float: unset;
  display: flex;
}
div.stackchart-title .title-text {
  flex-grow: 1;
}
div.stackchart-title .multitoggle__item {
  text-indent: 0;
  line-height: 22px;
  padding: 0px 5px;
  font-size: 12px;
  font-weight: 400;
}
.stack_chart__wrapper .cw_section_title {
  width: 90%;
}
.stack_chart__wrapper .cw_toggle_actions {
  margin-top: 4px;
}
.stack_chart__wrapper .cw_section_content {
  overflow-x: hidden;
}
.stack-chart__container {
  width: 100%;
  height: 100%;
}
.stack-chart__container > .section__content > div {
  display: flex;
  flex-shrink: 0;
}
