.dashboards-designer__last-update {
  font-weight: bold;
}
.dashboards-designer__status-bar {
  position: fixed;
  top: 0px;
  height: 52px;
  min-width: 300px;
  padding: 10px;
  background-color: #f3fcff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  color: #3B3E42;
  display: flex;
}
.dashboards-designer__status-bar_mode_locked {
  top: 0px;
}
.dashboards-designer__status-bar_mode_slide {
  top: -25px;
  transition: top 0.5s;
}
.dashboards-designer__status-bar_mode_slide:hover {
  top: 0px;
}
.dashboards-designer__status-bar_mode_off {
  display: none;
}
.dashboards-designer__status-bar_position_left {
  left: 30px;
}
.dashboards-designer__status-bar_position_right {
  right: 30px;
}
.dashboards-designer__status-bar_position_center {
  left: calc(50% - 100px);
}
.ant_menu_container ul {
  background-color: #fbfbfb;
  border: none;
}
.ant_menu_container ul:hover {
  border: 1px solid #808080;
  border-radius: 2px;
}
.ant_menu_container .ant-menu-submenu {
  border-bottom: none !important;
}
.ant_menu_container .anticon {
  margin: 0 5px 0 5px;
}
