.cost-display-threshold-editor {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  overflow: hidden;
}
.cost-display-threshold-editor__header {
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
  background-color: #fbfbfb;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 0 0 4px;
}
.cost-display-threshold-editor__question-sign {
  font-size: 16px;
}
.cost-display-threshold-editor__button {
  height: 30px;
  display: flex;
  align-items: center;
}
.cost-display-threshold-editor__delete {
  font-size: 20px;
  cursor: pointer;
}
.cost-display-threshold-editor__delete:hover {
  color: #709bba;
}
.cost-display-threshold-editor__form {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.cost-display-threshold-editor__field {
  width: 30%;
}
