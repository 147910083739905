.applications-details__summary {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 200px auto;
  grid-template-rows: min-content min-content min-content auto;
  grid-template-areas: 'health-index viewer' 'asset-groups viewer' 'assets viewer' 'reasons reasons';
  min-width: 0;
  min-height: 0;
}
.applications-details__health-index {
  grid-area: health-index;
}
.applications-details__asset-groups {
  grid-area: asset-groups;
}
.applications-details__assets {
  grid-area: assets;
}
.applications-details__viewer {
  grid-area: viewer;
}
.applications-details__viewer-content {
  align-items: center;
  justify-content: center;
}
.applications-details__viewer-content .geEditor {
  flex: 1 0 auto;
}
.applications-details__reasons {
  grid-area: reasons;
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
}
.applications-details__reasons .cw_actions_menu {
  top: -50px;
  left: 35px;
}
.applications-details__summaryContainer {
  display: flex !important;
}
.applications-details .k-grid .glyphicons {
  font-size: 15px;
}
.application_container {
  height: 100%;
}
