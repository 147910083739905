.slider {
  display: flex;
  flex-direction: row;
}
.slider__slider-container {
  flex: 1;
}
.slider__text-input-container {
  flex-shrink: 0;
}
.slider .k-slider .k-slider-selection {
  background-color: #1677FF;
}
.slider .k-slider .k-slider-track {
  border: 1px solid #d8d8d8;
  width: 100% !important;
}
