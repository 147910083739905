.refresh-timer {
  display: flex;
  flex-direction: row;
}
.refresh-timer__time-input {
  width: 40px;
}
.refresh-timer__time-input input {
  text-align: center;
}
.refresh-timer__spinner {
  width: 40px;
}
.refresh-timer__countdown {
  cursor: pointer;
}
.refresh-timer__current-mode-icon_mode_pause {
  color: var(--ceeview-validation-error-color);
}
