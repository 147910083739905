.autoLayout__container {
  line-height: 30px;
  margin-left: 20px;
}
.autoLayout__container input {
  margin-top: 8px;
  margin-right: 5px;
}
#cw_servicelog_time_selector {
  position: absolute;
  top: 4px;
  right: 51px;
  z-index: 99999;
}
#cw_servicelog_time_selector .multitoggle__item {
  padding: 0.4em;
}
.cw_show_acknowledged_viewer {
  position: relative;
  margin-top: -32px;
  margin-right: 804px;
  margin-left: auto;
  height: 31px;
  display: flex;
  align-items: center;
  top: 2px;
  z-index: 99999;
}
.cw_page_title__timezone {
  font-size: 13px;
  background: rgba(255, 255, 255, 0.6);
  padding: 3px 6px;
  vertical-align: bottom;
  border-radius: 5px;
  margin-left: 10px;
}
.active-widget-in-service-viewer {
  outline: 2px solid #00a8ff;
}
