.custom-metric-widget-title {
  display: flex;
}
.custom-metric-widget-title .title-text {
  flex-grow: 1;
}
.custom-metric-widget-title .multitoggle__item {
  text-indent: 0;
  line-height: 22px;
  padding: 0px 5px;
  font-size: 12px;
  font-weight: 400;
}
.custom_metric_widget__wrapper .cw_section_title {
  width: calc(100% - 50px);
}
.custom_metric_widget__wrapper .cw_toggle_actions {
  margin-top: 4px;
}
.custom_metric_widget__wrapper .cw_section_content {
  overflow-x: hidden;
}
.custom-metric-widget-configuration {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.custom-metric-widget-configuration .form-entry {
  padding: 7px 0;
  max-width: unset;
}
.display-settings-frame__nowrap {
  display: flex;
  flex-direction: row;
}
.display-settings-frame__buffer {
  flex-grow: 1;
}
.display-settings-frame .c-checkbox {
  margin-top: 3px;
}
.metric-window {
  height: 100%;
}
.metric-window__switchContainer {
  margin-left: 5px;
}
.metric-window__switcher {
  display: flex;
}
.metric-window .k-grid-content {
  overflow-y: visible;
}
.metric-window__grid-wrapper {
  height: calc(100% - 80px);
  overflow-y: hidden;
}
.metric-window__grid {
  height: 100%;
}
.metric-window__grid .k-grid-content-locked {
  height: 388px;
}
.metric-window__grid-container {
  height: 100%;
}
.metric-window__toolbar {
  height: 40px;
}
