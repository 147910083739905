.react-app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.react-app_menu-pinned.react-app_menu-orientation_vertical {
  flex-direction: row;
}
.react-app_menu-pinned.react-app_menu-orientation_vertical .react-app__content {
  flex: 1 0;
}
.react-app_menu-pinned.react-app_menu-orientation_vertical .react-app__navigation {
  width: 200px;
}
.react-app_menu-pinned.react-app_menu-orientation_horizontal .react-app__menu-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
}
.react-app_menu-pinned.react-app_menu-orientation_horizontal .react-app__user-info {
  flex: 0 0 auto;
}
.react-app_menu-pinned.react-app_menu-orientation_horizontal .react-app__toolbar {
  padding-right: 12px;
}
.react-app__user-info {
  display: flex;
}
.react-app__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.react-app__toolbar {
  margin-left: 20px;
}
.react-app__header {
  flex: 0 0;
  display: flex;
  align-items: center;
}
.react-app__toolbar {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0;
}
.react-app__main-content {
  flex: 1 0;
  padding: 10px;
  position: relative;
}
.react-app__breadcrumb {
  max-width: max-content;
}
.include-subaccounts-menu__icon {
  width: 16px;
  height: 100%;
}
.include-subaccounts-menu__icon_include {
  background: url("~styles/shared/images/app_theme//cw_include_account.png") 50% 50% no-repeat;
}
.include-subaccounts-menu__icon_exclude {
  background: url("~styles/shared/images/app_theme//cw_include_subaccount.png") 50% 50% no-repeat;
}
.site-header__navigation {
  flex: 1 0;
  overflow: hidden;
  line-height: 40px;
  padding: 5px 0;
}
.site-header .page-help-button {
  display: block;
  height: 30px;
  font-size: 24px;
  color: #aaa;
  padding: 3px 0px;
}
