.metric-barchart__barchart {
  position: relative;
  height: 158px;
  padding: 0;
  display: flex;
  flex-flow: column;
}
.metric-barchart__barchart-bar {
  width: 100%;
  position: relative;
  top: 5px;
  min-height: 20px;
  max-height: 80px;
  height: 40%;
  border-radius: 3px;
  flex-grow: 1;
  background-color: var(--ceeview-severity-critical);
}
.metric-barchart__barchart-bar_inverted {
  background-color: var(--ceeview-severity-none);
}
.metric-barchart__bar {
  height: 100%;
  border-radius: 3px 0 0 3px;
  position: relative;
  background-color: var(--ceeview-severity-none);
}
.metric-barchart__bar_inverted {
  background-color: var(--ceeview-severity-critical);
}
.metric-barchart__breach {
  right: 0;
  margin-right: -10px;
  top: 118%;
}
.metric-barchart__min,
.metric-barchart__max,
.metric-barchart__breach,
.metric-barchart__currentText {
  position: absolute;
  text-align: center;
}
.metric-barchart__min p,
.metric-barchart__max p,
.metric-barchart__breach p,
.metric-barchart__currentText p {
  margin: 0 0 5px;
  font-weight: bold;
  font-size: 1.4em;
}
.metric-barchart__min {
  left: 0;
}
.metric-barchart__max {
  right: 0;
}
.metric-barchart__min,
.metric-barchart__max {
  top: 118%;
}
.metric-barchart__current {
  position: absolute;
  top: 0;
  height: 100%;
  text-align: center;
}
.metric-barchart__currentLine {
  height: 110%;
  top: -5%;
  width: 4px;
  position: absolute;
  background: #010101;
}
.metric-barchart__currentText {
  top: 100%;
  left: -18px;
}
.metric-barchart__legend-container {
  text-align: center;
  margin-top: 67px;
}
.metric-barchart__legend-container span {
  cursor: pointer;
  font-weight: bold;
  font-size: 10px;
  color: #333333;
  text-overflow: ellipsis;
}
.metric-barchart__legend-container_noLabel {
  margin-top: 37px;
}
.metric-barchart__legend-container_noNumbers {
  margin-top: 32px;
}
.metric-barchart__legend-container_noLabelAndNumber {
  margin-top: 15px;
}
.metric-barchart__info-icon {
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
}
