.ant-modal-root .ant-modal-wrap .ant-modal.calendar-event .ant-modal-body__wrapper .ant-modal-content .ant-modal-footer {
  margin: 0;
  padding: 0;
}
.ant-modal-root .ant-modal-wrap .ant-modal.calendar-event .ant-modal-body__wrapper .ant-modal-content .ant-modal-footer .toolbar {
  padding: 10px 15px;
  height: 50px;
}
.calendar-event__period {
  justify-content: space-between;
}
.recurring-event__every-input {
  min-width: 150px;
}
.recurring-event .section__content {
  gap: 10px;
}
.recurring-event .section__content .form-entry-new {
  flex-grow: 1;
}
.recurring-event .section__content .form-entry-new__field {
  gap: 10px;
  align-items: stretch;
  line-height: 38px;
}
.recurring-event .section__content .form-entry-new__field .ant-radio-group {
  padding: 0;
}
.recurring-event .section__content .form-entry-new__field .ant-radio-group .ant-radio-wrapper span:nth-child(2) {
  display: flex;
  flex: 1;
}
.recurring-event .section__content .form-entry-new__field .ant-radio-group .ant-radio-wrapper span:nth-child(2) .form-entry-new {
  width: 100%;
}
.recurring-event__days-of-week {
  padding: 0 15px;
}
