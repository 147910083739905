.k-animation-container .sla-state-icon.status_icon:not(.cw_status_indicator) {
  margin: 0 5px 0 -3px;
  font-size: 21px;
  height: 21px;
  width: 21px;
}
.k-animation-container .sla-state-icon.cw_status_indicator {
  margin: 0 5px 0 0;
}
.k-animation-container .k-chip-label {
  display: flex;
  align-items: center;
}
.k-animation-container .k-chip-label .sla-state-icon.status_icon:not(.cw_status_indicator) {
  margin: 0 3px 0 -2px;
  font-size: 21px;
  height: 21px;
  width: 21px;
}
.cw_sla-timezone-info {
  padding: 20px 20px 0;
}
.cw_sla-timezone-info .question-sign {
  padding: 0 5px;
  font-size: 15px;
  line-height: 18px;
}
