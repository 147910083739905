.icon-button_embedded {
  border: none;
  background: transparent;
}
.icon-button_disabled {
  opacity: 0.5;
}
.icon-button_clickable {
  padding: 0.4em;
  cursor: pointer;
}
.icon-button_clickable:hover {
  color: #333333;
  border-color: #aeaeae;
  background-color: #ebebeb;
}
