.cw_custom_text_widget ul,
.cw_custom_text_widget ol {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-left: 2.5em;
}
.cw_custom_text_widget ul > li {
  list-style-type: disc;
}
.cw_custom_text_widget ol > li {
  list-style-type: decimal;
}
