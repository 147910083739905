.k-notification {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10000;
  padding: 0;
  box-shadow: none;
  border: none;
}
.k-notification .content,
.k-notification .content-icon {
  float: left;
  width: 100%;
  min-height: 50px;
  line-height: 20px;
  padding: 15px;
  text-align: center;
  box-sizing: border-box;
  white-space: break-spaces;
}
.k-notification .content-icon {
  padding-left: 50px;
}
.k-notification .title {
  font-size: 18px;
  margin-bottom: 15px;
}
.k-notification .message {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.k-notification .info {
  background-color: #f4f7f9;
  color: #267fb8;
}
.k-notification .error {
  border: none;
  background-color: var(--ceeview-validation-error-color);
  color: #fff;
}
.k-notification .success {
  border: 1px solid #b2dba1;
  border-bottom-color: #a3d48e;
  background-color: #DFF0D8;
  color: #529138;
}
.k-notification .warning {
  border: 1px solid #f7ecb5;
  border-bottom-color: #f5e79e;
  background-color: #FCF8E3;
  color: #caad16;
}
.k-notification .exclamation-sign {
  margin: 4px;
}
