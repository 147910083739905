.selective-service__filtering-panel__remove-icon {
  margin-top: 7px;
}
.selective-service__filtering-panel__input {
  max-width: 128px;
}
.selective-service__filtering-panel__input .drop-down-list {
  max-width: 128px;
  width: 128px;
}
.selective-service__filtering-panel__input .k-numerictextbox,
.selective-service__filtering-panel__input .numeric-textbox__wrapper {
  width: 128px;
}
.selective-service__filtering-panel__small {
  max-width: 70px;
}
.selective-service__filtering-panel__small .k-numerictextbox,
.selective-service__filtering-panel__small .numeric-textbox__wrapper {
  max-width: 70px;
}
.selective-service__filtering-panel__item {
  display: flex;
  margin-bottom: 10px;
}
.selective-service__filtering-panel__item:last-child {
  margin-bottom: 0;
}
.selective-service__filtering-panel__item > * {
  margin-right: 10px;
}
.selective-service__filtering-panel__item > *:last-child {
  margin-right: 0;
}
.selective-service__filtering-panel__label {
  width: 120px;
  border-radius: 4px 0 0 4px;
  margin-right: 7px;
  height: 30px;
  line-height: 30px;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 10px;
}
.selective-service__filtering-panel__reset-field {
  max-width: unset;
  flex-grow: 1;
}
.selective-service__filtering-panel__reset-field {
  max-width: unset;
  flex-grow: 1;
}
.selective-service__filtering-panel__reset-field .drop-down-list {
  max-width: 100%;
}
.selective-service__filtering-panel__reset-field .k-numerictextbox,
.selective-service__filtering-panel__reset-field .numeric-textbox__wrapper {
  max-width: 100%;
  width: 100%;
}
.selective-service__filtering-panel__unit {
  max-width: 140px;
}
.selective-service__filtering-panel__unit .drop-down-list {
  max-width: 140px;
}
