.query-builder-group {
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 15px 5px 15px 15px;
  margin-bottom: 5px;
}
.query-builder-group .query-builder-group {
  background: #eee;
}
.query-builder-group .query-builder-group .query-builder-group {
  background: white;
}
.query-builder-group .query-builder-group .query-builder-group .query-builder-group {
  background: #eee;
}
.query-builder-group .query-builder-group .query-builder-group .query-builder-group .query-builder-group {
  background: white;
}
.query-builder-group .query-builder-group .query-builder-group .query-builder-group .query-builder-group .query-builder-group {
  background: #eee;
}
.query-builder-group__buttons-toolbar {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
}
.query-builder-group__not-switch {
  margin-right: 10px;
}
.query-builder-group__and-or-switch {
  margin-right: auto;
}
.query-builder-group__add-rule-button {
  margin-right: 10px;
}
.query-builder-group__add-group-button {
  margin-right: 10px;
}
.query-builder-rule {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.query-builder-rule__field,
.query-builder-rule__operator,
.query-builder-rule__value {
  margin-right: 10px;
}
.query-builder-rule__field {
  flex: 0 0 150px;
}
.query-builder-rule__operator {
  flex: 0 0 100px;
}
.query-builder-rule__value {
  flex: 1 0 100px;
}
.query-builder-rule__delete {
  flex-shrink: 0;
}
.query-builder-rule-field {
  width: 100%;
}
.query-builder-rule-operator {
  width: 100%;
}
.query-builder-rule-value {
  width: 100%;
}
