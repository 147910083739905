#screen_options {
  background: transparent;
  border: none;
  height: 30px;
}
#screen_options > span {
  display: flex;
  flex-flow: row nowrap;
  gap: 5px;
  align-items: center;
  color: #1890ff;
  height: 100%;
  font-weight: bold;
  font-size: 14px;
}
.home-view-settings__item .ant-checkbox-wrapper {
  width: 100%;
  padding: 7px 15px;
}
.home-view-settings__item:hover {
  background-color: #e6f7ff;
}
.home-view-settings__dropdown-icon {
  font-size: 10px;
  margin-left: 10px;
}
