.ant-input:not(.ant-input-sm) {
  padding: 2px 11px;
}
.text-area-auto-size {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.57142857;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  resize: vertical;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-block;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  outline: none;
}
