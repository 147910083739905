.account-selector {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
}
.account-selector__name {
  line-height: 24px;
  font-size: 15px;
  font-weight: 700;
  color: #257cba;
}
.account-selector__header {
  display: flex;
  gap: 10px;
}
.account-selector__filter {
  width: 100%;
  flex-grow: 1;
}
.account-selector__popover {
  min-height: 400px;
}
.account-selector__actions-toolbar {
  margin-top: auto;
}
.account-selector__actions {
  padding-right: 0;
}
.account-selector__search-highlight {
  color: #257cba;
  font-weight: bold;
}
