.notification {
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}
.notification_info {
  border: 1px solid #caeaff;
  background-color: #e4f4ff;
  color: #4bb6ff;
}
.notification_critical {
  border: 1px solid #ebcccc;
  background-color: #f2dede;
  color: #c77070;
}
