.with-required {
  display: flex;
}
.with-required .drop-down-list {
  width: 100%;
}
.with-required__wildcard {
  position: absolute;
  color: red;
  left: 2px;
}
.with-required .k-input::placeholder {
  padding-left: 8px;
  font-size: 12px;
  color: #bfbfbf;
}
