.custom-metric-script-window__reference {
  padding: 5px 20px;
}
.custom-metric-script-window__content {
  width: 100%;
  height: calc(100% - 64px);
}
.custom-metric-script-window__buttons {
  height: 50px;
}
.custom-metric-script-window .k-window-content {
  overflow: hidden;
}
