.glyphicons.k-sprite,
.glyphicons.k-sprite::before {
  font-size: 14px;
  line-height: 10px;
  vertical-align: middle;
  color: black;
}
.k-treeview span.k-in {
  color: black;
}
.k-in.k-disabled {
  color: #555 !important;
}
.k-checkbox:disabled + .k-checkbox-label:before {
  background-color: #fafafa;
  color: #999 !important;
}
.k-in.k-disabled .glyphicons.k-sprite:before {
  color: grey !important;
}
