.metric-table-widget_withoutHeader .metric-table-widget__time-selector-wrapper {
  position: absolute;
  right: 23px;
  top: 6px;
  z-index: 1;
  display: flex;
  min-height: 24px;
}
.metric-table-widget__time-selector-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.metric-table-widget__time-selector-wrapper .cw_widget_info_sign {
  position: initial;
  order: 0;
}
.metric-table-widget__time-selector-container {
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
}
.metric-table-widget__time-selector-container .cw_table_info_multitoggle_time_selector {
  position: initial;
  top: initial;
  float: none;
  order: 1;
}
