.filter-panel .cw_view_button {
  float: right;
  margin: 0 !important;
}
.filter-panel .k-button-icon {
  margin-left: 5px;
  height: 30px;
  width: 30px;
  text-align: center !important;
}
