.cost-threshold-window__header {
  display: flex;
  padding-left: 150px;
  padding-right: 60px;
  margin-top: 10px;
}
.cost-threshold-window__entry {
  display: flex;
}
.cost-threshold-window__input + .cost-threshold-window__input {
  margin-left: 10px;
}
.cost-threshold-window__input_cost,
.cost-threshold-window__input_severity {
  width: 50%;
}
.cost-threshold-window__input_enabled {
  width: 50px;
  display: flex;
  align-items: center;
}
.cost-threshold-window__h {
  font-weight: bold;
  width: 50%;
  margin-left: 10px;
}
