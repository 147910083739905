.ceeview-grid-header {
  overflow: hidden;
  position: relative;
  flex: 0 0 40px;
}
.ceeview-grid-header_inline-filters-active {
  flex-basis: 80px;
}
.ceeview-grid-header__container {
  height: 100%;
  display: flex;
  flex-direction: row;
}
.ceeview-grid-header__container_scrollable {
  position: absolute;
}
.ceeview-grid-header-cell {
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.ceeview-grid-header-cell__title-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  height: 40px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  font-weight: bold;
  padding: 0 8px;
  background-color: #fafafa;
  transition: background-color 0.3s;
  position: relative;
}
.ceeview-grid-header-cell__title-section_draggable {
  cursor: pointer;
}
.ceeview-grid-header-cell__title-section_filtered {
  background-color: #d9eafb;
}
.ceeview-grid-header-cell__title-section:hover {
  background-color: #f5f5f5;
}
.ceeview-grid-header-cell__title-section:before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: #0000000f;
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: "";
}
.ceeview-grid-header-cell__inline-filter-section {
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
}
.ceeview-grid-header-cell_over {
  border-right: 1px dotted red;
}
.ceeview-grid-header-cell__filter {
  color: #bfbfbf;
  flex-shrink: 0;
  font-size: 12px;
  margin: 0 -4px 0 4px;
}
.ceeview-grid-header-cell__filter:hover {
  color: rgba(0, 0, 0, 0.65);
  background: rgba(0, 0, 0, 0.04);
}
.ceeview-grid-header-cell__filter_active {
  color: #1677ff;
}
.ceeview-grid-header-cell__title {
  flex: 1;
  overflow: hidden;
}
.ceeview-grid-header-cell__resize-handler {
  position: absolute;
  user-select: none;
  width: 10px;
  height: 100%;
  top: 0px;
  right: -5px;
  cursor: col-resize;
}
.ceeview-grid-header-cell_fixed_left,
.ceeview-grid-header-cell_fixed_right {
  background-color: white;
  position: sticky;
  z-index: 5;
}
