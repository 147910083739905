.cw_table_info_multitoggle_time_selector {
  position: absolute;
  top: 3px;
}
.cw_table_info_dropdown_time_selector {
  position: absolute;
  top: 0;
  width: 70px;
  background-color: #fbfbfb;
}
.no-data-placeholder {
  height: 100%;
  background-color: #f4f7f9;
  padding: 15px 0;
  color: #267fb8;
  text-align: center;
}
.metric-table-data-window .period_multi_toggle {
  float: none;
  display: flex;
  justify-content: end;
  margin: 3px 10px;
  margin-right: 80px;
}
.metric-table-data-window .k-window-content {
  margin-top: 0px;
}
.metric-table-data-window__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.metric-table-data-window__grid-container {
  flex: 1 1 auto;
  overflow: hidden;
}
.metric-table-data-window__legend {
  flex: 0 0 auto;
}
