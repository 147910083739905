.osm-severity-indicator-overlay {
  position: relative;
  transform: translate(-8px, -50%);
}
.osm-severity-indicator-overlay__asset-name {
  margin-top: 2px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2px 5px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.osm-severity-indicator-overlay .severity-indicator {
  border: 1px solid black;
}
