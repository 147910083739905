@charset "UTF-8";

@font-face {
  font-family: "ceesoft-sort";
  src:url("shared/fonts/ceesoft-sort.eot");
  src:url("shared/fonts/ceesoft-sort.eot?#iefix") format("embedded-opentype"),
    url("shared/fonts/ceesoft-sort.woff") format("woff"),
    url("shared/fonts/ceesoft-sort.ttf") format("truetype"),
    url("shared/fonts/ceesoft-sort.svg#ceesoft-sort") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "ceesoft-sort" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="cw_sort_icon_"]:before,
[class*=" cw_sort_icon_"]:before {
  font-family: "ceesoft-sort" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cw_sort_icon_4:before {
  content: "a";
}
.cw_sort_icon_3:before {
  content: "b";
}
.cw_sort_icon_2:before {
  content: "c";
}
.cw_sort_icon_1:before {
  content: "d";
}
.cw_sort_icon_arrow_down:before {
  content: "e";
}
.cw_sort_icon_arrow_up:before {
  content: "f";
}
.handCursor{
  cursor: pointer;
}