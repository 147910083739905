.tree_grid {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
}
.tree_grid__cell {
  display: flex;
  padding: 10px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.tree_grid__cell:nth-child(3n-2) {
  border-left: 1px solid #ddd;
}
.tree_grid__cell:nth-child(6n+4) {
  background-color: #eee;
}
.tree_grid__cell:nth-child(6n+5) {
  background-color: #eee;
}
.tree_grid__cell:nth-child(6n+6) {
  background-color: #eee;
}
.tree_grid__icon {
  width: 17px;
  height: 12px;
}
.tree_grid .tree_grid__cell.tree_grid__selected {
  background-color: cornflowerblue;
}
.tree_grid_container .c-textbox__wrapper {
  display: flex;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}
.tree_grid_container__search {
  margin: 5px;
}
.tree_grid_container__header {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
}
.tree_grid_container__header div {
  padding: 10px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.tree_grid_container__header div:nth-child(3n-2) {
  border-left: 1px solid #ddd;
}
