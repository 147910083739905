#cw_asset_form {
  overflow-y: auto;
}
#cw_asset_form .form_content {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
}
#cw_asset_form .form_content .cw_field {
  margin: 0;
}
