.legend-color-settings__container.section__content {
  margin-top: 10px;
}
.legend-color-settings .anticon-plus-circle {
  font-size: 16px;
  color: #257cba;
}
.legend-color-settings__row {
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
}
.legend-color-settings__row .form-entry-new {
  min-width: 200px;
}
.legend-color-settings__row .sketch-color-picker {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.legend-color-settings__row .sketch-color-picker__picker-btn {
  height: 29px;
}
