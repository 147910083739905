.input-with-action {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}
.input-with-action__input {
  flex: 1;
}
.input-with-action__input > * {
  width: 100%;
}
.input-with-action__action {
  font-size: 16px;
  margin-left: 5px;
}
