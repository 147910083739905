.service-recalculation__info-row {
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
}
.service-recalculation__info-row label {
  font-weight: bold;
}
.service-recalculation__details-header {
  font-weight: bold;
}
