.grid-view-selector {
  min-width: 150px;
}
.grid-view-selector__divider {
  margin: 8px 0;
}
.grid-view-selector__add-button {
  padding: 2px 5px;
  display: block;
  text-align: center;
}
.grid-view-selector__properties {
  min-width: 700px;
}
.grid-view-selector__properties .toolbar__entry_title {
  max-width: 500px;
}
.grid-view-selector__columns {
  display: grid;
  padding: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
}
.grid-view-selector__views-list {
  width: 200px;
}
.grid-view-selector__column_hidden .ant-checkbox-wrapper {
  color: rgba(0, 0, 0, 0.5);
}
