#auth_form .password-placeholder .form-entry_horizontal .form-entry__label {
  margin-right: 0;
}
#auth_form .password-placeholder .ant-input-affix-wrapper {
  height: 30px;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.auth-form__password-unlock {
  color: black;
  transition: color 0.25s ease;
}
.auth-form__password-unlock:hover {
  color: rgba(0, 0, 0, 0.65);
}
.auth-form__password-unlock:active {
  transform: scale(0.95);
  transform-origin: 50% 50%;
}
