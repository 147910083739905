.drop-down-list {
  display: flex;
  font-size: 13px;
  max-width: 100%;
}
.drop-down-list > * {
  flex-grow: 1;
}
.drop-down-list .k-dropdown {
  width: 100%;
}
.drop-down-list > .glyphicons {
  width: 20px;
  flex-grow: unset;
  font-size: 20px;
  line-height: 30px;
  margin-left: 5px;
}
.drop-down-list.invalid input {
  background: #F2DEDE;
  color: #B94A48;
  text-shadow: 0 1px 0 #fff;
}
.drop-down-list.invalid .k-input {
  text-shadow: 0 1px 0 #fff;
}
