.layout-splitter__collapse,
.layout-splitter__expand {
  position: absolute;
  left: -8px;
  font-size: 22px;
  top: 75px;
  z-index: 10;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
}
.splitter-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;
}
.splitter-layout .layout-pane {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  min-width: 0;
}
.splitter-layout .layout-pane > * {
  min-height: 100%;
}
.splitter-layout .layout-pane.layout-pane-primary {
  width: 0;
}
.splitter-layout-vertical.splitter-layout .layout-pane.layout-pane-primary {
  width: unset;
}
.splitter-layout .layout-pane {
  position: relative;
  flex: 0 0 auto;
  overflow: auto;
}
.splitter-layout .layout-pane.layout-pane-primary {
  flex: 1 1 auto;
}
.splitter-layout > .layout-splitter {
  flex: 0 0 auto;
  width: 4px;
  height: 100%;
  cursor: col-resize;
  background-color: #ccc;
  position: relative;
}
.splitter-layout .layout-splitter:hover {
  background-color: #bbb;
}
.splitter-layout.layout-changing {
  cursor: col-resize;
}
.splitter-layout.layout-changing > .layout-splitter {
  background-color: #aaa;
}
.splitter-layout.splitter-layout-vertical {
  flex-direction: column;
}
.splitter-layout.splitter-layout-vertical.layout-changing {
  cursor: row-resize;
}
.splitter-layout.splitter-layout-vertical > .layout-splitter {
  width: 100%;
  height: 4px;
  cursor: row-resize;
}
