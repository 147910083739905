.designer-toolbar {
  height: 34px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
}
.designer-toolbar__item {
  margin-right: 8px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  display: flex;
}
.designer-toolbar__item_height_full {
  align-self: stretch;
}
.designer-toolbar__item_type_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  height: 30px;
  width: 34px;
  cursor: pointer;
}
.designer-toolbar__item_type_icon > .anticon {
  font-size: 16px;
}
.designer-toolbar__item_type_icon:hover:hover {
  color: #fff;
  background-color: #1677ff;
}
.designer-toolbar__item_type_separator {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.06);
  height: 70%;
  margin-right: 8px;
}
.designer-toolbar__item_toggled {
  color: #fff;
  background-color: #69b1ff;
}
.geEditor .designer-toolbar * {
  box-sizing: border-box;
}
.scale-selector {
  width: 60px;
  border-bottom: none;
}
.scale-selector.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.scale-selector.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.scale-selector.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.scale-selector.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.scale-selector.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.scale-selector.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.scale-selector.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.scale-selector.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: none;
}
