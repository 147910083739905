.bubble-chart-toolbar__toolbar-radio-group {
  vertical-align: middle;
  margin-right: 10px;
}
.bubble-chart-toolbar__toolbar-radio-group .ant-radio-button-wrapper {
  font-size: 12px;
  height: 25px;
  color: #54595E;
  padding: 0 5px;
  line-height: 22px;
}
.bubble-chart-toolbar__toolbar-radio-group label:first-child {
  border-radius: 5px 0 0 5px;
}
.bubble-chart-toolbar__toolbar-radio-group label:last-child {
  border-radius: 0 5px 5px 0;
}
.bubble-chart-toolbar__toolbar-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
.bubble-chart-toolbar__toolbar-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover,
.bubble-chart-toolbar__toolbar-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:focus-within {
  background-color: #cfe6f5;
  color: black;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  box-shadow: none;
  transition: none;
}
.bubble-chart-toolbar__toolbar-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:before {
  content: none;
}
.bubble-chart-toolbar__toolbar-number-box {
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
  height: 23px;
  margin-top: 0px;
  width: 100px;
}
.bubble-chart-toolbar__toolbar-dropdown-monthSelect {
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
}
.bubble-chart-toolbar__toolbar-dropdown-monthSelect .k-dropdown {
  width: 98px;
}
.bubble-chart-toolbar__info-icon {
  display: inline-block;
  margin-top: 5px;
}
.bubble-chart-toolbar__info-icon_dd {
  margin-top: 8px;
}
