.grid-menu-button {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}
.grid-menu-button__menu-container > .ant-menu-vertical.ant-menu-sub {
  min-width: 220px;
}
