.grid-menu {
  margin-left: 15px;
  position: relative;
}
.grid-menu > .ant-btn-icon-only {
  width: 30px;
  height: 28px;
}
.k-widget .grid-menu * {
  box-sizing: border-box;
}
.cw_global_actions .grid-menu {
  margin-top: 0.3em;
}
.cw_section_title .cw_global_actions .grid-menu {
  margin-top: unset;
}
.menu-button .grid-menu {
  margin-left: unset;
}
