.shutdown {
  overflow-x: hidden;
  overflow-y: auto;
}
.shutdown__logo {
  float: left;
  width: 115px;
  height: 25px;
  margin: 23px 0 22px 30px;
  background-image: url('../../../main/webapp/assets-static/logo-no-bg.png');
}
.shutdown__banner {
  background-image: url('../../../main/webapp/assets-static/banner.jpg');
  background-size: 100% 100%;
  width: auto;
  height: 40vh;
  margin: auto;
}
.shutdown__title {
  font-size: 70px;
  color: #7a94b7;
  position: relative;
  top: -250px;
  left: 20px;
  text-align: center;
  z-index: 99999;
}
.shutdown__subtitle {
  background-color: white;
  position: relative;
  top: 10px;
  width: auto;
  font-size: 40px;
  height: 20vh;
  text-align: center;
  color: black;
}
.shutdown__progress {
  font-size: 30px;
  position: relative;
  left: 25px;
  white-space: pre-wrap;
}
.shutdown__text {
  background-color: lightgrey;
  position: relative;
  top: 50px;
  width: auto;
  font-size: 20px;
  height: 30vh;
  padding: 25px;
  color: black;
  white-space: pre-wrap;
}
