.grid-view-sorting-editor {
  display: flex;
  flex-direction: column;
}
.grid-view-sorting-editor__entry {
  height: 35px;
  padding: 2px 10px;
  transition: background-color 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.grid-view-sorting-editor__entry:hover {
  background-color: #fafafa;
}
.grid-view-sorting-editor__entry_over {
  border-top: 1px dotted red;
}
.grid-view-sorting-editor__sorting-indicator {
  margin-left: 10px;
  margin-right: auto;
}
