.dashboard-form-list-item {
  text-align: left;
}
.dashboard-form-list-item label {
  margin-left: 10px;
}
.application-info-message {
  position: absolute;
  width: 23%;
  left: 400px;
  top: 160px;
}
