.time-period-selector {
  display: flex;
}
.time-period-selector_appearance_dropdown {
  flex-direction: column;
}
.time-period-selector_appearance_buttons {
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.time-period-selector__cnt-switch-button {
  padding-top: 14px;
  padding-bottom: 6px;
}
.time-period-selector__cnt-switch-button_small {
  padding-top: 0;
  padding-bottom: 0;
}
.time-period-selector__cnt-editor {
  padding-top: 14px;
  padding-bottom: 6px;
}
.time-period-selector__cnt-editor_small {
  padding-top: 0;
  padding-bottom: 0;
}
.time-period-selector__hoverable-wrapper {
  min-height: 22px;
  display: flex;
  align-items: center;
  gap: 7px;
}
.time-period-selector__hoverable-content {
  padding: 5px;
}
.time-period-selector__custom-interval-selector {
  display: flex;
  flex-direction: row;
  padding: 8px;
}
.time-period-selector__custom-interval-value {
  flex: 1 1 70px;
}
.time-period-selector__custom-interval-type {
  flex: 1 1 100px;
}
.time-period-selector__custom-interval-item {
  margin-left: 3px;
}
.time-period-selector__custom-count-selector {
  text-align: right;
}
.time-period-selector_nowrap .ant-radio-group {
  white-space: nowrap;
}
