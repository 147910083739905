.page {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding-bottom: 15px;
}
.page_no-paddings {
  padding-bottom: 0;
}
.page__header.toolbar {
  flex: 0 0 65px;
  padding: 0 15px;
  background-color: initial;
  border: none;
}
.page__header.toolbar .toolbar__entry_title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  color: #3B3E42;
}
.page__content {
  display: flex;
  flex: 1;
  min-height: 0;
  height: 100%;
  padding: 0 15px;
}
.page__content_scrollable {
  height: 0;
  overflow: auto;
}
.page__content_no-paddings {
  padding: 0;
}
.page__content > * {
  flex: 1;
}
