.draggable_placeholder {
  position: absolute;
  margin: 10px 0 0 5px;
}
.item-container {
  cursor: move;
  margin: 2px 10px 5px 2px;
  padding: 5px 10px 5px 5px;
  color: #FFFFFF;
  border-color: #6CA5D6;
  background-color: #5C97CA;
  display: inline-block;
  border-radius: 2px;
  float: left;
}
.empty-container {
  height: 40px;
}
.item-input {
  width: 100px;
}
.container {
  border: 1px solid #d8d8d8;
  list-style-type: none;
}
.disabled-container {
  pointer-events: none;
  background-color: #fafafa;
}
.pencil-icon {
  margin-left: 10px;
  cursor: pointer;
}
.close-icon {
  margin-left: 3px;
  cursor: pointer;
}
.required-icon {
  width: 0;
  right: 20px;
  position: relative;
  float: right;
  color: #ff0000;
}
