.widgets-wizard__menu {
  margin-left: -15px;
  flex-shrink: 0;
}
.widgets-wizard__menu .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.widgets-wizard__menu .ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height: 25px;
  line-height: 25px;
}
.widgets-wizard__menu .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title .icon-button,
.widgets-wizard__menu .ant-menu-sub.ant-menu-inline > .ant-menu-item .icon-button {
  line-height: 25px;
}
.widgets-wizard__form {
  flex: 1 0 0;
  position: relative;
}
.widgets-wizard__form .cw_checkbox {
  margin-left: 140px;
  margin-top: 6px;
}
.widgets-wizard__form .cw_field > .cw_multi_toggle {
  margin-left: 130px;
  float: left;
}
.widgets-wizard__preview-container {
  position: relative;
  flex: 0 0 200px;
  align-items: center;
}
.widgets-wizard__preview-container > .section__content {
  align-items: center;
}
.widgets-wizard__expand-preview-button {
  position: absolute;
  bottom: 0;
  right: 0;
}
.widgets-wizard__preview-container {
  width: 200px;
  height: 200px;
  display: flex;
  border: 1px solid #ccc;
}
.widgets-wizard__preview {
  min-width: 0;
  margin: auto;
  max-width: 200px;
}
.widgets-wizard .advanced_metrics_toggle {
  border: 1px solid #cccccc;
  font-size: 15px;
}
.widgets-wizard .advanced_metrics_toggle_legacy {
  border: 1px solid #cccccc;
  font-size: 15px;
  padding: 6px;
  border-radius: 3px;
}
.widgets-wizard .selected_advanced_metrics_toggle {
  background-color: #cfe6f5;
}
