.columns-visibility {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.columns-visibility__columns-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.columns-visibility__column {
  padding: 5px 15px;
}
