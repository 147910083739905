.cost-status-cell {
  display: flex;
  align-items: center;
}
.cost-status-cell__item {
  display: flex;
  align-items: center;
  width: 15px;
  position: relative;
}
.cost-status-cell__item + .cost-status-cell__item {
  margin-left: 5px;
}
.cost-status-cell__item_type_metric .material-icons {
  font-size: 18px;
}
.cost-status-cell__item_type_expression .material-icons {
  font-size: 36px;
  position: absolute;
  top: -10px;
}
