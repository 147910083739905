.sketch-color-picker {
  vertical-align: middle;
}
.sketch-color-picker__picker-btn {
  cursor: pointer;
  margin-right: 10px;
  border: 5px solid white;
  height: 30px;
  width: 130px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}
