.chart_aggregation_wrapper {
  clear: both;
}
.chart_aggregation {
  margin-top: 10px;
}
.ant-modal-content .cw_multi_toggle {
  float: left;
}
.ant-popover.chart-tool-tip {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  font-size: 12px;
  white-space: nowrap;
  color: #333333;
}
.ant-popover.chart-tool-tip .ant-popover-inner-content {
  padding: 8px 30px;
}
