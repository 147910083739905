.service-summary-grid-widget .target-label {
  font-weight: bold;
}
.service-summary-grid-widget .cell-link {
  cursor: pointer;
}
.service-summary-grid-widget__state-cell {
  display: flex;
  justify-content: center;
}
.service-summary-grid-widget__order-cell {
  justify-content: center;
}
.service-summary-grid-widget__order-cell .form-entry-new {
  width: 100%;
}
.service-summary-grid-widget__order-cell .form-entry-new__field {
  min-height: 100%;
}
.service-summary-grid-widget__order-cell .form-entry-new__field .ant-input-number {
  padding: 0;
  height: 100%;
}
.service-summary-grid-widget__order-cell .form-entry-new__field .ant-input-number input {
  padding: 3px;
  text-align: center;
}
.service-summary-grid-widget__order-cell .form-entry-new__field .ant-input-number-handler-wrap {
  display: none;
}
