.favorites-button {
  font-size: 20px;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 4px;
}
.favorites-button:hover {
  color: #333333;
  background-color: #ebebeb;
}
.favorites-button_added,
.favorites-button_added:hover {
  color: #ecc814;
}
