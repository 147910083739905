.cw-numeric__container {
  outline: none;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid #ccc;
  display: inline;
  padding: 0px 5px;
}
.cw-numeric__invalid {
  background: #F2DEDE;
  color: #B94A48;
  text-shadow: 0 1px 0 #fff;
}
.cw-numeric__input {
  border: none;
  outline: none;
  height: 28px;
  background: transparent;
  width: 100%;
}
