.users-ruleset__control-section {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}
.users-ruleset__control-section .cw_actions_trigger {
  width: 30px;
  height: 30px;
  font-size: 19px;
  margin: 2px 0 0 10px;
  padding: 5px;
}
.users-ruleset__buffer {
  display: flex;
  flex-grow: 1;
}
.common-layout__content .fullHeight {
  flex-grow: 1;
}
.channel_schedule__toolbar {
  display: flex;
  font-size: 15px;
  font-weight: bold;
  line-height: 43px;
}
.channel_schedule__toolbar_bin {
  font-size: 18px;
  margin-left: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
}
