.sorting-panel .draggable-icon {
  background: url("../../../../styles/shared/images/app_theme/drag.png") no-repeat;
  width: 15px;
  height: 25px;
  display: block;
  margin-top: 2px;
  margin-right: 5px;
}
.sorting-panel > div {
  display: flex;
}
.sorting-panel__label {
  width: 120px;
  border-radius: 4px 0 0 4px;
  margin-right: 7px;
  height: 30px;
  line-height: 30px;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 10px;
}
.sorting-panel__sorting-item {
  display: flex;
  padding-bottom: 10px;
}
.sorting-panel__sorting-item:last-child {
  padding-bottom: 0;
}
.sorting-panel__input {
  flex-grow: 1;
  width: 100px;
}
.sorting-panel__padding {
  margin-left: 5px;
}
.sorting-panel__remove-icon {
  margin-top: 9px;
  margin-left: 5px;
  width: 12px;
  height: 21px;
  cursor: pointer;
}
