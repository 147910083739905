.ant-tree-select_hide-max-tags .ant-select-selection-overflow-item-rest {
  display: none;
}
.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-node-content-wrapper {
  background-color: #d6f4ff;
}
.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  font-weight: bold;
}
.ant-tree-select[error="warning-error"] .ant-select-selector {
  background-color: #fff1f0;
}
