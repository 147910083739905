.ant-confirm-modal__icon {
  font-size: 28px;
}
.ant-confirm-modal__icon_info {
  color: #4096ff;
}
.ant-confirm-modal__icon_warning {
  color: #fdce3e;
}
.ant-confirm-modal__icon_error {
  color: var(--ceeview-validation-error-color);
}
.ant-confirm-modal__icon_question {
  color: #4096ff;
}
.ant-confirm-modal .ant-modal-header {
  padding: 4px 24px;
  background: #f5f5f5;
  font-size: 13px;
  margin-bottom: 0;
}
.ant-confirm-modal .ant-modal-footer {
  background: #f5f5f5;
  padding: 12px;
  margin-top: 0;
  margin-bottom: 0 !important;
}
.ant-confirm-modal .section__content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 15px;
}
.ant-confirm-modal .ant-modal-close {
  top: 7px;
  inset-inline-end: 5px;
  width: 30px;
}
.ant-confirm-modal .ant-modal-close .ant-modal-close-x {
  line-height: 30px;
  width: 30px;
}
