@charset "UTF-8";
/*!
 *
 *  Project:  GLYPHICONS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @jankovarik
 *
 */
@font-face {
  font-family: 'Glyphicons Regular';
  src: url('shared/fonts/glyphicons-regular.eot');
  src: url('shared/fonts/glyphicons-regular.eot?#iefix') format('embedded-opentype'), url('shared/fonts/glyphicons-regular.woff') format('woff'), url('shared/fonts/glyphicons-regular.ttf') format('truetype'), url('shared/fonts/glyphicons-regular.svg#glyphicons-regular') format('svg');
}
.glyphicons {
  display: inline-block;
  font-family: 'Glyphicons Regular';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicons:before {
  display: inline-block;
}
.glyphicons.large {
  font-size: 18px;
}
.avatar .glyphicons {
  float: none !important;
  font-size: 36px;
  margin: 0 auto;
  width: 64px;
  height: 64px;
  text-align: center;
  color: #333;
  padding: 0;
  line-height: 64px;
}
#modal .avatar .glyphicons {
  font: 36px/1.7 'Glyphicons Regular';
}
.avatar .glyphicons:hover {
  background: none !important;
}
.node .glyphicons {
  font: 24px/1 'Glyphicons Regular';
}
.node .glyphicons:hover {
  background: none !important;
}
.glyphicons.inactive {
  color: #999 !important;
}
.glyphicons.white:before {
  color: #fff;
}
.glyphicons.x05 {
  font-size: 12px;
}
.glyphicons.x2 {
  font-size: 48px;
}
.glyphicons.x2:before {
  padding: 8px 12px;
}
.glyphicons.x3 {
  font-size: 72px;
}
.glyphicons.x3:before {
  padding: 16px 20px;
}
.glyphicons.x4 {
  font-size: 96px;
}
.glyphicons.x4:before {
  padding: 20px 28px;
}
.glyphicons.x5 {
  font-size: 120px;
}
.glyphicons.x5:before {
  padding: 28px 36px;
}
.glyphicons.light:before {
  color: #f2f2f2;
}
.glyphicons.drop:before {
  text-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3);
}
.glyphicons.flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.glyphicons.flipv {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}
.glyphicons.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.glyphicons.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.glyphicons.rotate270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.glyphicons.access-point:before {
  content: '\e800';
}
/* '' */
.glyphicons.adjust:before,
.glyphicons.adjust-alt:before {
  content: '\e801';
}
/* '' */
.glyphicons.airplane:before {
  content: '\e802';
}
/* '' */
.glyphicons.alarm:before {
  content: '\e803';
}
/* '' */
.glyphicons.align-bottom:before {
  content: '\e804';
}
/* '' */
.glyphicons.align-horizontal:before,
.glyphicons.object-align-horizontal:before {
  content: '\e805';
}
/* '' */
.glyphicons.align-left:before {
  content: '\e806';
}
/* '' */
.glyphicons.align-right:before {
  content: '\e807';
}
/* '' */
.glyphicons.align-top:before {
  content: '\e808';
}
/* '' */
.glyphicons.align-vertical:before,
.glyphicons.align-center:before {
  content: '\e809';
}
/* '' */
.glyphicons.ambulance:before {
  content: '\e80a';
}
/* '' */
.glyphicons.anchor:before {
  content: '\e80b';
}
/* '' */
.glyphicons.announcement:before,
.glyphicons.bullhorn:before {
  content: '\e80c';
}
/* '' */
.glyphicons.apple:before {
  content: '\e80d';
}
/* '' */
.glyphicons.archive:before {
  content: '\e80e';
}
/* '' */
.glyphicons.arrow-down:before {
  content: '\e80f';
}
/* '' */
.glyphicons.arrow-left:before {
  content: '\e810';
}
/* '' */
.glyphicons.arrow-right:before {
  content: '\e811';
}
/* '' */
.glyphicons.arrow-thin-down:before {
  content: '\e812';
}
/* '' */
.glyphicons.arrow-thin-left:before {
  content: '\e813';
}
/* '' */
.glyphicons.arrow-thin-right:before {
  content: '\e814';
}
/* '' */
.glyphicons.arrow-thin-up:before {
  content: '\e815';
}
/* '' */
.glyphicons.arrow-up:before {
  content: '\e816';
}
/* '' */
.glyphicons.artificial-intelligence:before {
  content: '\e817';
}
/* '' */
.glyphicons.asian-food:before {
  content: '\e818';
}
/* '' */
.glyphicons.audio-description:before {
  content: '\e819';
}
/* '' */
.glyphicons.audio-description-d:before {
  content: '\e81a';
}
/* '' */
.glyphicons.ax:before {
  content: '\e81b';
}
/* '' */
.glyphicons.axes-three-dimensional:before {
  content: '\e81c';
}
/* '' */
.glyphicons.axes-two-dimensional:before {
  content: '\e81d';
}
/* '' */
.glyphicons.backpack:before {
  content: '\e81e';
}
/* '' */
.glyphicons.badminton:before {
  content: '\e81f';
}
/* '' */
.glyphicons.bag:before {
  content: '\e820';
}
/* '' */
.glyphicons.balance-scales:before {
  content: '\e821';
}
/* '' */
.glyphicons.balloon:before {
  content: '\e822';
}
/* '' */
.glyphicons.balloons:before {
  content: '\e823';
}
/* '' */
.glyphicons.bank:before {
  content: '\e824';
}
/* '' */
.glyphicons.banknotes:before,
.glyphicons.money:before {
  content: '\e825';
}
/* '' */
.glyphicons.barbed-wire:before {
  content: '\e826';
}
/* '' */
.glyphicons.barcode:before {
  content: '\e827';
}
/* '' */
.glyphicons.barrel:before {
  content: '\e828';
}
/* '' */
.glyphicons.bars:before,
.glyphicons.signal:before {
  content: '\e829';
}
/* '' */
.glyphicons.baseball:before {
  content: '\e82a';
}
/* '' */
.glyphicons.basket:before {
  content: '\e82b';
}
/* '' */
.glyphicons.basketball:before {
  content: '\e82c';
}
/* '' */
.glyphicons.basket-check:before {
  content: '\e82d';
}
/* '' */
.glyphicons.basket-checkout:before {
  content: '\e82e';
}
/* '' */
.glyphicons.basket-in:before {
  content: '\e82f';
}
/* '' */
.glyphicons.basket-out:before {
  content: '\e830';
}
/* '' */
.glyphicons.bath:before,
.glyphicons.bath-bathtub:before,
.glyphicons.bath-shower:before {
  content: '\e831';
}
/* '' */
.glyphicons.battery-charged:before,
.glyphicons.battery-full:before {
  content: '\e832';
}
/* '' */
.glyphicons.battery-charging:before {
  content: '\e833';
}
/* '' */
.glyphicons.battery-half:before,
.glyphicons.battery-50:before {
  content: '\e834';
}
/* '' */
.glyphicons.battery-low:before,
.glyphicons.battery-10:before {
  content: '\e835';
}
/* '' */
.glyphicons.battery-quarter:before,
.glyphicons.battery-25:before {
  content: '\e836';
}
/* '' */
.glyphicons.battery-three-quarters:before,
.glyphicons.battery-75:before {
  content: '\e837';
}
/* '' */
.glyphicons.beach-umbrella:before {
  content: '\e838';
}
/* '' */
.glyphicons.bed:before {
  content: '\e839';
}
/* '' */
.glyphicons.bed-single:before {
  content: '\e83a';
}
/* '' */
.glyphicons.bed-sleeping:before,
.glyphicons.bed-alt:before {
  content: '\e83b';
}
/* '' */
.glyphicons.beer:before {
  content: '\e83c';
}
/* '' */
.glyphicons.beer-alt:before {
  content: '\e83d';
}
/* '' */
.glyphicons.bell:before {
  content: '\e83e';
}
/* '' */
.glyphicons.bell-off:before {
  content: '\e83f';
}
/* '' */
.glyphicons.bell-ringing:before {
  content: '\e840';
}
/* '' */
.glyphicons.bicycle:before {
  content: '\e841';
}
/* '' */
.glyphicons.bin:before {
  content: '\e842';
}
/* '' */
.glyphicons.binoculars:before {
  content: '\e843';
}
/* '' */
.glyphicons.biohazard:before {
  content: '\e844';
}
/* '' */
.glyphicons.bishop:before {
  content: '\e845';
}
/* '' */
.glyphicons.bitcoin:before {
  content: '\e846';
}
/* '' */
.glyphicons.blackboard:before {
  content: '\e847';
}
/* '' */
.glyphicons.blacksmith:before {
  content: '\e848';
}
/* '' */
.glyphicons.blender:before {
  content: '\e849';
}
/* '' */
.glyphicons.block-move:before {
  content: '\e84a';
}
/* '' */
.glyphicons.boat:before {
  content: '\e84b';
}
/* '' */
.glyphicons.bold:before {
  content: '\e84c';
}
/* '' */
.glyphicons.bomb:before {
  content: '\e84d';
}
/* '' */
.glyphicons.book:before,
.glyphicons.blog:before {
  content: '\e84e';
}
/* '' */
.glyphicons.book-address:before,
.glyphicons.address-book:before {
  content: '\e84f';
}
/* '' */
.glyphicons.book-library:before {
  content: '\e850';
}
/* '' */
.glyphicons.book-library-search:before {
  content: '\e851';
}
/* '' */
.glyphicons.book-log:before,
.glyphicons.log-book:before {
  content: '\e852';
}
/* '' */
.glyphicons.bookmark:before {
  content: '\e853';
}
/* '' */
.glyphicons.book-open:before {
  content: '\e854';
}
/* '' */
.glyphicons.book-open-text:before {
  content: '\e855';
}
/* '' */
.glyphicons.book-play:before {
  content: '\e856';
}
/* '' */
.glyphicons.bowling:before {
  content: '\e857';
}
/* '' */
.glyphicons.brain:before {
  content: '\e858';
}
/* '' */
.glyphicons.brain-ai:before {
  content: '\e859';
}
/* '' */
.glyphicons.brick-wall:before {
  content: '\e85a';
}
/* '' */
.glyphicons.briefcase:before,
.glyphicons.suitcase:before {
  content: '\e85b';
}
/* '' */
.glyphicons.briefcase-alt:before {
  content: '\e85c';
}
/* '' */
.glyphicons.brightness-down:before,
.glyphicons.brightness-reduce:before {
  content: '\e85d';
}
/* '' */
.glyphicons.brightness-up:before,
.glyphicons.brightness-increase:before {
  content: '\e85e';
}
/* '' */
.glyphicons.bring-forward:before {
  content: '\e85f';
}
/* '' */
.glyphicons.bring-to-front:before {
  content: '\e860';
}
/* '' */
.glyphicons.brush:before {
  content: '\e861';
}
/* '' */
.glyphicons.bug:before {
  content: '\e862';
}
/* '' */
.glyphicons.building:before {
  content: '\e863';
}
/* '' */
.glyphicons.buildings-city:before {
  content: '\e864';
}
/* '' */
.glyphicons.buildings-mixed:before {
  content: '\e865';
}
/* '' */
.glyphicons.buildings-park:before {
  content: '\e866';
}
/* '' */
.glyphicons.bullets:before {
  content: '\e867';
}
/* '' */
.glyphicons.buoy:before {
  content: '\e868';
}
/* '' */
.glyphicons.bus:before {
  content: '\e869';
}
/* '' */
.glyphicons.cableway:before {
  content: '\e86a';
}
/* '' */
.glyphicons.cake:before,
.glyphicons.birthday-cake:before {
  content: '\e86b';
}
/* '' */
.glyphicons.calculator:before {
  content: '\e86c';
}
/* '' */
.glyphicons.calendar:before {
  content: '\e86d';
}
/* '' */
.glyphicons.call:before,
.glyphicons.earphone:before {
  content: '\e86e';
}
/* '' */
.glyphicons.call-data:before {
  content: '\e86f';
}
/* '' */
.glyphicons.call-incoming:before {
  content: '\e870';
}
/* '' */
.glyphicons.call-ip:before {
  content: '\e871';
}
/* '' */
.glyphicons.call-lock:before {
  content: '\e872';
}
/* '' */
.glyphicons.call-music:before {
  content: '\e873';
}
/* '' */
.glyphicons.call-outgoing:before {
  content: '\e874';
}
/* '' */
.glyphicons.call-pause:before {
  content: '\e875';
}
/* '' */
.glyphicons.call-record:before {
  content: '\e876';
}
/* '' */
.glyphicons.call-redirect:before {
  content: '\e877';
}
/* '' */
.glyphicons.call-video:before {
  content: '\e878';
}
/* '' */
.glyphicons.camera:before {
  content: '\e879';
}
/* '' */
.glyphicons.campfire:before {
  content: '\e87a';
}
/* '' */
.glyphicons.candle:before {
  content: '\e87b';
}
/* '' */
.glyphicons.canister:before {
  content: '\e87c';
}
/* '' */
.glyphicons.car:before {
  content: '\e87d';
}
/* '' */
.glyphicons.car-battery:before {
  content: '\e87e';
}
/* '' */
.glyphicons.card-clubs:before {
  content: '\e87f';
}
/* '' */
.glyphicons.card-diamonds:before {
  content: '\e880';
}
/* '' */
.glyphicons.card-hearts:before {
  content: '\e881';
}
/* '' */
.glyphicons.card-spades:before {
  content: '\e882';
}
/* '' */
.glyphicons.car-rental:before {
  content: '\e883';
}
/* '' */
.glyphicons.carrot:before {
  content: '\e884';
}
/* '' */
.glyphicons.cars:before,
.glyphicons.car-hov:before {
  content: '\e885';
}
/* '' */
.glyphicons.cart:before,
.glyphicons.shopping-cart:before {
  content: '\e886';
}
/* '' */
.glyphicons.cart-check:before,
.glyphicons.cart-tick:before {
  content: '\e887';
}
/* '' */
.glyphicons.cart-checkout:before {
  content: '\e888';
}
/* '' */
.glyphicons.cart-in:before {
  content: '\e889';
}
/* '' */
.glyphicons.cart-out:before {
  content: '\e88a';
}
/* '' */
.glyphicons.car-wheel:before {
  content: '\e88b';
}
/* '' */
.glyphicons.car-wheel-defect:before {
  content: '\e88c';
}
/* '' */
.glyphicons.car-wheel-wrench:before {
  content: '\e88d';
}
/* '' */
.glyphicons.car-wheel-wrench-alt:before {
  content: '\e88e';
}
/* '' */
.glyphicons.cash-register:before {
  content: '\e88f';
}
/* '' */
.glyphicons.cash-register-alt:before {
  content: '\e890';
}
/* '' */
.glyphicons.cat:before {
  content: '\e891';
}
/* '' */
.glyphicons.cd:before {
  content: '\e892';
}
/* '' */
.glyphicons.celeriac:before {
  content: '\e893';
}
/* '' */
.glyphicons.celery:before {
  content: '\e894';
}
/* '' */
.glyphicons.certificate:before {
  content: '\e895';
}
/* '' */
.glyphicons.charging-station:before {
  content: '\e896';
}
/* '' */
.glyphicons.chat-conversation:before,
.glyphicons.conversation:before {
  content: '\e897';
}
/* '' */
.glyphicons.chat-conversation-lock:before {
  content: '\e898';
}
/* '' */
.glyphicons.chat-conversation-off:before {
  content: '\e899';
}
/* '' */
.glyphicons.chat-message:before,
.glyphicons.chat:before {
  content: '\e89a';
}
/* '' */
.glyphicons.chat-message-check:before {
  content: '\e89b';
}
/* '' */
.glyphicons.chat-message-lock:before {
  content: '\e89c';
}
/* '' */
/*.glyphicons.check:before, */
.glyphicons.ok:before {
  content: '\e89d';
}
/* '' */
.glyphicons.chemistry:before,
.glyphicons.lab-alt:before {
  content: '\e89e';
}
/* '' */
.glyphicons.chemistry-alt:before,
.glyphicons.lab:before {
  content: '\e89f';
}
/* '' */
.glyphicons.cheque:before {
  content: '\e8a0';
}
/* '' */
.glyphicons.cheque-off:before {
  content: '\e8a1';
}
/* '' */
.glyphicons.chevron-down:before {
  content: '\e8a2';
}
/* '' */
.glyphicons.chevron-last-down:before {
  content: '\e8a3';
}
/* '' */
.glyphicons.chevron-last-left:before {
  content: '\e8a4';
}
/* '' */
.glyphicons.chevron-last-right:before {
  content: '\e8a5';
}
/* '' */
.glyphicons.chevron-last-up:before {
  content: '\e8a6';
}
/* '' */
.glyphicons.chevron-left:before {
  content: '\e8a7';
}
/* '' */
.glyphicons.chevron-right:before {
  content: '\e8a8';
}
/* '' */
.glyphicons.chevron-up:before {
  content: '\e8a9';
}
/* '' */
.glyphicons.circle-alert:before,
.glyphicons.exclamation-sign:before {
  content: '\e8aa';
}
/* '' */
.glyphicons.circle-check:before,
.glyphicons.ok-sign:before {
  content: '\e8ab';
}
/* '' */
.glyphicons.circle-down:before,
.glyphicons.circle-arrow-down:before {
  content: '\e8ac';
}
/* '' */
.glyphicons.circle-empty:before {
  content: '\e8ad';
}
/* '' */
.glyphicons.circle-empty-alert:before {
  content: '\e8ae';
}
/* '' */
.glyphicons.circle-empty-check:before,
.glyphicons.ok-circle:before {
  content: '\e8af';
}
/* '' */
.glyphicons.circle-empty-down:before,
.glyphicons.download:before {
  content: '\e8b0';
}
/* '' */
.glyphicons.circle-empty-info:before {
  content: '\e8b1';
}
/* '' */
.glyphicons.circle-empty-left:before {
  content: '\e8b2';
}
/* '' */
.glyphicons.circle-empty-minus:before {
  content: '\e8b3';
}
/* '' */
.glyphicons.circle-empty-play:before,
.glyphicons.play-button:before {
  content: '\e8b4';
}
/* '' */
.glyphicons.circle-empty-plus:before {
  content: '\e8b5';
}
/* '' */
.glyphicons.circle-empty-question:before {
  content: '\e8b6';
}
/* '' */
.glyphicons.circle-empty-remove:before,
.glyphicons.remove-circle:before {
  content: '\e8b7';
}
/* '' */
.glyphicons.circle-empty-right:before {
  content: '\e8b8';
}
/* '' */
.glyphicons.circle-empty-up:before,
.glyphicons.upload:before {
  content: '\e8b9';
}
/* '' */
.glyphicons.circle-hospital:before {
  content: '\e8ba';
}
/* '' */
.glyphicons.circle-info:before,
.glyphicons.info-sign:before {
  content: '\e8bb';
}
/* '' */
.glyphicons.circle-left:before,
.glyphicons.circle-arrow-left:before {
  content: '\e8bc';
}
/* '' */
.glyphicons.circle-menu:before {
  content: '\e8bd';
}
/* '' */
.glyphicons.circle-minus:before,
.glyphicons.minus-sign:before {
  content: '\e8be';
}
/* '' */
.glyphicons.circle-more:before {
  content: '\e8bf';
}
/* '' */
.glyphicons.circle-play:before {
  content: '\e8c0';
}
/* '' */
.glyphicons.circle-plus:before,
.glyphicons.plus-sign:before {
  content: '\e8c1';
}
/* '' */
.glyphicons.circle-question:before,
.glyphicons.question-sign:before {
  content: '\e8c2';
}
/* '' */
.glyphicons.circle-red-cross:before {
  content: '\e8c3';
}
/* '' */
.glyphicons.circle-remove:before,
.glyphicons.remove-sign:before {
  content: '\e8c4';
}
/* '' */
.glyphicons.circle-right:before,
.glyphicons.circle-arrow-right:before {
  content: '\e8c5';
}
/* '' */
.glyphicons.circle-selected:before,
.glyphicons.record:before {
  content: '\e8c6';
}
/* '' */
.glyphicons.circle-up:before,
.glyphicons.circle-arrow-top:before {
  content: '\e8c7';
}
/* '' */
.glyphicons.claw-hammer:before {
  content: '\e8c8';
}
/* '' */
.glyphicons.clean:before,
.glyphicons.cleaning:before {
  content: '\e8c9';
}
/* '' */
.glyphicons.clipboard:before,
.glyphicons.notes-2:before {
  content: '\e8ca';
}
/* '' */
.glyphicons.clock:before {
  content: '\e8cb';
}
/* '' */
.glyphicons.cloud:before {
  content: '\e8cc';
}
/* '' */
.glyphicons.cloud-alert:before {
  content: '\e8cd';
}
/* '' */
.glyphicons.cloud-check:before {
  content: '\e8ce';
}
/* '' */
.glyphicons.cloud-download:before,
.glyphicons.cloud-minus:before {
  content: '\e8cf';
}
/* '' */
.glyphicons.cloud-off:before {
  content: '\e8d0';
}
/* '' */
.glyphicons.cloud-pause:before {
  content: '\e8d1';
}
/* '' */
.glyphicons.cloud-refresh:before {
  content: '\e8d2';
}
/* '' */
.glyphicons.cloud-upload:before,
.glyphicons.cloud-plus:before {
  content: '\e8d3';
}
/* '' */
.glyphicons.cluster:before,
.glyphicons.git-merge:before {
  content: '\e8d4';
}
/* '' */
.glyphicons.code:before,
.glyphicons.embed-close:before {
  content: '\e8d5';
}
/* '' */
.glyphicons.coffee-beans:before {
  content: '\e8d6';
}
/* '' */
.glyphicons.coffee-mug:before,
.glyphicons.coffee-cup:before {
  content: '\e8d7';
}
/* '' */
.glyphicons.coffee-to-go:before {
  content: '\e8d8';
}
/* '' */
.glyphicons.cogwheel:before {
  content: '\e8d9';
}
/* '' */
.glyphicons.cogwheels:before {
  content: '\e8da';
}
/* '' */
.glyphicons.coins:before {
  content: '\e8db';
}
/* '' */
.glyphicons.comments:before {
  content: '\e8dc';
}
/* '' */
.glyphicons.comments-lock:before {
  content: '\e8dd';
}
/* '' */
.glyphicons.comments-off:before {
  content: '\e8de';
}
/* '' */
.glyphicons.compass:before {
  content: '\e8df';
}
/* '' */
.glyphicons.compound:before {
  content: '\e8e0';
}
/* '' */
.glyphicons.computer:before {
  content: '\e8e1';
}
/* '' */
.glyphicons.computer-all-in-one:before {
  content: '\e8e2';
}
/* '' */
.glyphicons.computer-network:before,
.glyphicons.engineering-networks:before {
  content: '\e8e3';
}
/* '' */
.glyphicons.computer-network-alt:before,
.glyphicons.more-items:before,
.glyphicons.flowchart:before {
  content: '\e8e4';
}
/* '' */
.glyphicons.computer-pc-tower:before {
  content: '\e8e5';
}
/* '' */
.glyphicons.construction-cone:before {
  content: '\e8e6';
}
/* '' */
.glyphicons.contactless:before {
  content: '\e8e7';
}
/* '' */
.glyphicons.contactless-lock:before {
  content: '\e8e8';
}
/* '' */
.glyphicons.contrast:before {
  content: '\e8e9';
}
/* '' */
.glyphicons.cookie:before {
  content: '\e8ea';
}
/* '' */
.glyphicons.cookie-alt:before {
  content: '\e8eb';
}
/* '' */
.glyphicons.cookies:before {
  content: '\e8ec';
}
/* '' */
.glyphicons.cooks-hat:before {
  content: '\e8ed';
}
/* '' */
.glyphicons.copy:before {
  content: '\e8ee';
}
/* '' */
.glyphicons.copy-duplicate:before,
.glyphicons.duplicate:before {
  content: '\e8ef';
}
/* '' */
.glyphicons.corn:before {
  content: '\e8f0';
}
/* '' */
.glyphicons.correction-auto:before {
  content: '\e8f1';
}
/* '' */
.glyphicons.correction-skewed:before {
  content: '\e8f2';
}
/* '' */
.glyphicons.correction-skewed-alt:before {
  content: '\e8f3';
}
/* '' */
.glyphicons.correction-tilted:before {
  content: '\e8f4';
}
/* '' */
.glyphicons.cot:before {
  content: '\e8f5';
}
/* '' */
.glyphicons.court-gavel:before {
  content: '\e8f6';
}
/* '' */
.glyphicons.court-gavel-alt:before {
  content: '\e8f7';
}
/* '' */
.glyphicons.credit:before {
  content: '\e8f8';
}
/* '' */
.glyphicons.credit-card:before {
  content: '\e8f9';
}
/* '' */
.glyphicons.credit-card-off:before {
  content: '\e8fa';
}
/* '' */
.glyphicons.credit-empty:before {
  content: '\e8fb';
}
/* '' */
.glyphicons.crop:before {
  content: '\e8fc';
}
/* '' */
.glyphicons.crown:before {
  content: '\e8fd';
}
/* '' */
.glyphicons.cup:before {
  content: '\e8fe';
}
/* '' */
.glyphicons.curves:before {
  content: '\e8ff';
}
/* '' */
.glyphicons.cutlery:before {
  content: '\e900';
}
/* '' */
.glyphicons.dashboard:before {
  content: '\e901';
}
/* '' */
.glyphicons.database:before {
  content: '\e902';
}
/* '' */
.glyphicons.database-minus:before,
.glyphicons.database-ban:before {
  content: '\e903';
}
/* '' */
.glyphicons.database-plus:before {
  content: '\e904';
}
/* '' */
.glyphicons.database-search:before {
  content: '\e905';
}
/* '' */
.glyphicons.diamond:before {
  content: '\e906';
}
/* '' */
.glyphicons.dice:before,
.glyphicons.playing-dices:before {
  content: '\e907';
}
/* '' */
.glyphicons.dice-five:before,
.glyphicons.dice-5:before {
  content: '\e908';
}
/* '' */
.glyphicons.dice-four:before,
.glyphicons.dice-4:before {
  content: '\e909';
}
/* '' */
.glyphicons.dice-one:before,
.glyphicons.dice-1:before {
  content: '\e90a';
}
/* '' */
.glyphicons.dice-six:before,
.glyphicons.dice-6:before {
  content: '\e90b';
}
/* '' */
.glyphicons.dice-three:before,
.glyphicons.dice-3:before {
  content: '\e90c';
}
/* '' */
.glyphicons.dice-two:before,
.glyphicons.dice-2:before {
  content: '\e90d';
}
/* '' */
.glyphicons.direction:before {
  content: '\e90e';
}
/* '' */
.glyphicons.direction-empty:before {
  content: '\e90f';
}
/* '' */
.glyphicons.direction-full:before {
  content: '\e910';
}
/* '' */
.glyphicons.directions:before {
  content: '\e911';
}
/* '' */
.glyphicons.directions-arrow:before {
  content: '\e912';
}
/* '' */
.glyphicons.directions-sign:before {
  content: '\e913';
}
/* '' */
.glyphicons.disappearing:before {
  content: '\e914';
}
/* '' */
.glyphicons.dishwasher:before {
  content: '\e915';
}
/* '' */
.glyphicons.diving-free:before,
.glyphicons.snorkel-diving:before {
  content: '\e916';
}
/* '' */
.glyphicons.diving-scuba:before,
.glyphicons.scuba-diving:before {
  content: '\e917';
}
/* '' */
.glyphicons.division:before,
.glyphicons.divide:before {
  content: '\e918';
}
/* '' */
.glyphicons.dna:before {
  content: '\e919';
}
/* '' */
.glyphicons.dog:before {
  content: '\e91a';
}
/* '' */
.glyphicons.dog-tags:before {
  content: '\e91b';
}
/* '' */
.glyphicons.dollar:before,
.glyphicons.usd:before {
  content: '\e91c';
}
/* '' */
.glyphicons.door:before,
.glyphicons.exit:before {
  content: '\e91d';
}
/* '' */
.glyphicons.door-close:before {
  content: '\e91e';
}
/* '' */
.glyphicons.door-open:before {
  content: '\e91f';
}
/* '' */
.glyphicons.dress:before {
  content: '\e920';
}
/* '' */
.glyphicons.drink:before {
  content: '\e921';
}
/* '' */
.glyphicons.drinking-water:before {
  content: '\e922';
}
/* '' */
.glyphicons.drinking-water-off:before {
  content: '\e923';
}
/* '' */
.glyphicons.drop:before,
.glyphicons.tint:before {
  content: '\e924';
}
/* '' */
.glyphicons.drop-down:before {
  content: '\e925';
}
/* '' */
.glyphicons.drop-off:before {
  content: '\e926';
}
/* '' */
.glyphicons.drop-plus:before {
  content: '\e927';
}
/* '' */
.glyphicons.dryer:before {
  content: '\e928';
}
/* '' */
.glyphicons.dumbbell:before {
  content: '\e929';
}
/* '' */
.glyphicons.dustbin:before {
  content: '\e92a';
}
/* '' */
.glyphicons.ear:before {
  content: '\e92b';
}
/* '' */
.glyphicons.ear-off:before {
  content: '\e92c';
}
/* '' */
.glyphicons.earphones:before,
.glyphicons.ear-plugs:before {
  content: '\e92d';
}
/* '' */
.glyphicons.education:before {
  content: '\e92e';
}
/* '' */
.glyphicons.egg:before {
  content: '\e92f';
}
/* '' */
.glyphicons.eject:before {
  content: '\e930';
}
/* '' */
.glyphicons.electric-cord:before {
  content: '\e931';
}
/* '' */
.glyphicons.email:before {
  content: '\e932';
}
/* '' */
.glyphicons.envelope:before {
  content: '\e933';
}
/* '' */
.glyphicons.envelope-down:before,
.glyphicons.message-in:before {
  content: '\e934';
}
/* '' */
.glyphicons.envelope-empty:before,
.glyphicons.message-empty:before {
  content: '\e935';
}
/* '' */
.glyphicons.envelope-flag:before,
.glyphicons.message-flag:before {
  content: '\e936';
}
/* '' */
.glyphicons.envelope-full:before,
.glyphicons.message-full:before {
  content: '\e937';
}
/* '' */
.glyphicons.envelope-lock:before,
.glyphicons.message-lock:before {
  content: '\e938';
}
/* '' */
.glyphicons.envelope-minus:before,
.glyphicons.message-minus:before {
  content: '\e939';
}
/* '' */
.glyphicons.envelope-no:before,
.glyphicons.message-ban:before {
  content: '\e93a';
}
/* '' */
.glyphicons.envelope-plus:before,
.glyphicons.message-plus:before {
  content: '\e93b';
}
/* '' */
.glyphicons.envelope-star:before,
.glyphicons.message-new:before {
  content: '\e93c';
}
/* '' */
.glyphicons.envelope-up:before,
.glyphicons.message-out:before {
  content: '\e93d';
}
/* '' */
.glyphicons.equalizer-bars:before,
.glyphicons.voice:before {
  content: '\e93e';
}
/* '' */
.glyphicons.equalizer-dots:before,
.glyphicons.equalizer:before {
  content: '\e93f';
}
/* '' */
.glyphicons.equals:before {
  content: '\e940';
}
/* '' */
.glyphicons.eraser:before,
.glyphicons.erase:before {
  content: '\e941';
}
/* '' */
.glyphicons.euro:before {
  content: '\e942';
}
/* '' */
.glyphicons.exchange:before,
.glyphicons.transfer:before {
  content: '\e943';
}
/* '' */
.glyphicons.export:before,
.glyphicons.disk-export:before,
.glyphicons.file-export:before {
  content: '\e944';
}
/* '' */
.glyphicons.eye:before,
.glyphicons.eye-open:before {
  content: '\e945';
}
/* '' */
.glyphicons.eyedropper:before {
  content: '\e946';
}
/* '' */
.glyphicons.eye-off:before,
.glyphicons.eye-close:before {
  content: '\e947';
}
/* '' */
.glyphicons.fabric:before {
  content: '\e948';
}
/* '' */
.glyphicons.face-scan:before {
  content: '\e949';
}
/* '' */
.glyphicons.factory:before {
  content: '\e94a';
}
/* '' */
.glyphicons.factory-alt:before {
  content: '\e94b';
}
/* '' */
.glyphicons.fan:before {
  content: '\e94c';
}
/* '' */
.glyphicons.fast-food:before {
  content: '\e94d';
}
/* '' */
.glyphicons.fax:before {
  content: '\e94e';
}
/* '' */
.glyphicons.feather:before {
  content: '\e94f';
}
/* '' */
.glyphicons.female:before {
  content: '\e950';
}
/* '' */
.glyphicons.fence:before {
  content: '\e951';
}
/* '' */
.glyphicons.file:before {
  content: '\e952';
}
/* '' */
.glyphicons.file-cloud:before {
  content: '\e953';
}
/* '' */
.glyphicons.file-lock:before,
.glyphicons.database-lock:before {
  content: '\e954';
}
/* '' */
.glyphicons.file-minus:before {
  content: '\e955';
}
/* '' */
.glyphicons.file-plus:before {
  content: '\e956';
}
/* '' */
.glyphicons.file-refresh:before {
  content: '\e957';
}
/* '' */
.glyphicons.files-queue:before {
  content: '\e958';
}
/* '' */
.glyphicons.fill:before,
.glyphicons.bucket:before {
  content: '\e959';
}
/* '' */
.glyphicons.film:before,
.glyphicons.theater:before {
  content: '\e95a';
}
/* '' */
.glyphicons.filter:before,
.glyphicons.filter-applied:before {
  content: '\e95b';
}
/* '' */
.glyphicons.filter-cogwheel:before {
  content: '\e95c';
}
/* '' */
.glyphicons.filter-minus:before,
.glyphicons.filter-remove:before {
  content: '\e95d';
}
/* '' */
.glyphicons.filter-off:before {
  content: '\e95e';
}
/* '' */
.glyphicons.filter-plus:before {
  content: '\e95f';
}
/* '' */
.glyphicons.fingerprint:before {
  content: '\e960';
}
/* '' */
.glyphicons.fingerprint-check:before,
.glyphicons.fingerprint-ok:before {
  content: '\e961';
}
/* '' */
.glyphicons.fingerprint-lock:before {
  content: '\e962';
}
/* '' */
.glyphicons.fingerprint-remove:before {
  content: '\e963';
}
/* '' */
.glyphicons.fingerprint-scan:before,
.glyphicons.fingerprint-reload:before {
  content: '\e964';
}
/* '' */
.glyphicons.finish-line:before {
  content: '\e965';
}
/* '' */
.glyphicons.fins:before {
  content: '\e966';
}
/* '' */
.glyphicons.fire:before {
  content: '\e967';
}
/* '' */
.glyphicons.firefighters:before {
  content: '\e968';
}
/* '' */
.glyphicons.fireplace:before {
  content: '\e969';
}
/* '' */
.glyphicons.fireworks:before,
.glyphicons.firework:before {
  content: '\e96a';
}
/* '' */
.glyphicons.fireworks-alt:before {
  content: '\e96b';
}
/* '' */
.glyphicons.fish:before {
  content: '\e96c';
}
/* '' */
.glyphicons.fishes:before {
  content: '\e96d';
}
/* '' */
.glyphicons.fishes-bait:before {
  content: '\e96e';
}
/* '' */
.glyphicons.flag:before {
  content: '\e96f';
}
/* '' */
.glyphicons.flag-triangle:before {
  content: '\e970';
}
/* '' */
.glyphicons.flag-waving:before {
  content: '\e971';
}
/* '' */
.glyphicons.flash:before,
.glyphicons.electricity:before {
  content: '\e972';
}
/* '' */
.glyphicons.flash-automatic:before {
  content: '\e973';
}
/* '' */
.glyphicons.flashlight:before {
  content: '\e974';
}
/* '' */
.glyphicons.flash-no:before {
  content: '\e975';
}
/* '' */
.glyphicons.flower:before {
  content: '\e976';
}
/* '' */
.glyphicons.folder:before,
.glyphicons.folder-closed:before {
  content: '\e977';
}
/* '' */
.glyphicons.folder-alert:before {
  content: '\e978';
}
/* '' */
.glyphicons.folder-check:before,
.glyphicons.folder-flag:before {
  content: '\e979';
}
/* '' */
.glyphicons.folder-cogwheel:before {
  content: '\e97a';
}
/* '' */
.glyphicons.folder-lock:before {
  content: '\e97b';
}
/* '' */
.glyphicons.folder-minus:before {
  content: '\e97c';
}
/* '' */
.glyphicons.folder-no:before {
  content: '\e97d';
}
/* '' */
.glyphicons.folder-open:before {
  content: '\e97e';
}
/* '' */
.glyphicons.folder-plus:before {
  content: '\e97f';
}
/* '' */
.glyphicons.folder-refresh:before {
  content: '\e980';
}
/* '' */
.glyphicons.folder-star:before,
.glyphicons.folder-new:before {
  content: '\e981';
}
/* '' */
.glyphicons.font:before,
.glyphicons.text-size:before {
  content: '\e982';
}
/* '' */
.glyphicons.food-service:before,
.glyphicons.plate-of-food:before {
  content: '\e983';
}
/* '' */
.glyphicons.football:before,
.glyphicons.soccer-ball:before {
  content: '\e984';
}
/* '' */
.glyphicons.footprints:before,
.glyphicons.shoe-steps:before {
  content: '\e985';
}
/* '' */
.glyphicons.forest:before {
  content: '\e986';
}
/* '' */
.glyphicons.forklift:before {
  content: '\e987';
}
/* '' */
.glyphicons.forward:before {
  content: '\e988';
}
/* '' */
.glyphicons.forward-email:before {
  content: '\e989';
}
/* '' */
.glyphicons.forward-fifteen:before {
  content: '\e98a';
}
/* '' */
.glyphicons.forward-five:before {
  content: '\e98b';
}
/* '' */
.glyphicons.forward-ten:before {
  content: '\e98c';
}
/* '' */
.glyphicons.forward-thirty:before {
  content: '\e98d';
}
/* '' */
.glyphicons.french-press:before {
  content: '\e98e';
}
/* '' */
.glyphicons.fullscreen:before,
.glyphicons.fit-image-to-frame:before {
  content: '\e98f';
}
/* '' */
.glyphicons.fullscreen-off:before,
.glyphicons.fit-frame-to-image:before {
  content: '\e990';
}
/* '' */
.glyphicons.game-controller:before,
.glyphicons.gamepad:before {
  content: '\e991';
}
/* '' */
.glyphicons.game-controller-wireless:before {
  content: '\e992';
}
/* '' */
.glyphicons.gas:before {
  content: '\e993';
}
/* '' */
.glyphicons.gas-station:before {
  content: '\e994';
}
/* '' */
.glyphicons.gay:before {
  content: '\e995';
}
/* '' */
.glyphicons.gift:before {
  content: '\e996';
}
/* '' */
.glyphicons.glass:before {
  content: '\e997';
}
/* '' */
.glyphicons.glass-wine:before {
  content: '\e998';
}
/* '' */
.glyphicons.globe:before,
.glyphicons.global:before {
  content: '\e999';
}
/* '' */
.glyphicons.globe-data:before,
.glyphicons.article:before {
  content: '\e99a';
}
/* '' */
.glyphicons.golf:before {
  content: '\e99b';
}
/* '' */
.glyphicons.golf-green:before,
.glyphicons.golf-course:before {
  content: '\e99c';
}
/* '' */
.glyphicons.government:before {
  content: '\e99d';
}
/* '' */
.glyphicons.graphic-tablet:before {
  content: '\e99e';
}
/* '' */
.glyphicons.graphic-tablet-wireless:before {
  content: '\e99f';
}
/* '' */
.glyphicons.grater:before {
  content: '\e9a0';
}
/* '' */
.glyphicons.gun:before {
  content: '\e9a1';
}
/* '' */
.glyphicons.gun-off:before {
  content: '\e9a2';
}
/* '' */
.glyphicons.hairdresser:before {
  content: '\e9a3';
}
/* '' */
.glyphicons.hammer:before,
.glyphicons.classic-hammer:before {
  content: '\e9a4';
}
/* '' */
.glyphicons.hand-dislike:before,
.glyphicons.thumbs-down:before {
  content: '\e9a5';
}
/* '' */
.glyphicons.hand-donate:before {
  content: '\e9a6';
}
/* '' */
.glyphicons.hand-heart:before {
  content: '\e9a7';
}
/* '' */
.glyphicons.hand-like:before,
.glyphicons.thumbs-up:before {
  content: '\e9a8';
}
/* '' */
.glyphicons.hand-ok:before {
  content: '\e9a9';
}
/* '' */
.glyphicons.hand-open:before {
  content: '\e9aa';
}
/* '' */
.glyphicons.hand-open-alt:before {
  content: '\e9ab';
}
/* '' */
.glyphicons.hand-pointing-down:before,
.glyphicons.hand-down:before {
  content: '\e9ac';
}
/* '' */
.glyphicons.hand-pointing-left:before,
.glyphicons.hand-left:before {
  content: '\e9ad';
}
/* '' */
.glyphicons.hand-pointing-right:before,
.glyphicons.hand-right {
  content: '\e9ae';
}
/* '' */
.glyphicons.hand-pointing-up:before,
.glyphicons.hand-up:before {
  content: '\e9af';
}
/* '' */
.glyphicons.hand-praying:before {
  content: '\e9b0';
}
/* '' */
.glyphicons.handshake:before {
  content: '\e9b1';
}
/* '' */
.glyphicons.hanger:before,
.glyphicons.coat-hanger:before {
  content: '\e9b2';
}
/* '' */
.glyphicons.hard-drive:before,
.glyphicons.hdd:before {
  content: '\e9b3';
}
/* '' */
.glyphicons.hard-drive-cogwheel:before {
  content: '\e9b4';
}
/* '' */
.glyphicons.hard-drive-eject:before {
  content: '\e9b5';
}
/* '' */
.glyphicons.hard-drive-lock:before {
  content: '\e9b6';
}
/* '' */
.glyphicons.hard-drive-no:before {
  content: '\e9b7';
}
/* '' */
.glyphicons.hash:before {
  content: '\e9b8';
}
/* '' */
.glyphicons.header:before {
  content: '\e9b9';
}
/* '' */
.glyphicons.headphones:before {
  content: '\e9ba';
}
/* '' */
.glyphicons.headset:before {
  content: '\e9bb';
}
/* '' */
.glyphicons.head-voice:before {
  content: '\e9bc';
}
/* '' */
.glyphicons.heart:before {
  content: '\e9bd';
}
/* '' */
.glyphicons.heartbeat:before {
  content: '\e9be';
}
/* '' */
.glyphicons.heart-broken:before {
  content: '\e9bf';
}
/* '' */
.glyphicons.heart-empty:before {
  content: '\e9c0';
}
/* '' */
.glyphicons.heating:before,
.glyphicons.heat:before {
  content: '\e9c1';
}
/* '' */
.glyphicons.hetero:before {
  content: '\e9c2';
}
/* '' */
.glyphicons.highlights:before {
  content: '\e9c3';
}
/* '' */
.glyphicons.highway:before {
  content: '\e9c4';
}
/* '' */
.glyphicons.history:before {
  content: '\e9c5';
}
/* '' */
.glyphicons.hob:before {
  content: '\e9c6';
}
/* '' */
.glyphicons.hockey:before {
  content: '\e9c7';
}
/* '' */
.glyphicons.home:before {
  content: '\e9c8';
}
/* '' */
.glyphicons.home-fire:before {
  content: '\e9c9';
}
/* '' */
.glyphicons.home-flag:before {
  content: '\e9ca';
}
/* '' */
.glyphicons.home-flood:before {
  content: '\e9cb';
}
/* '' */
.glyphicons.home-for-sale:before {
  content: '\e9cc';
}
/* '' */
.glyphicons.honey:before {
  content: '\e9cd';
}
/* '' */
.glyphicons.honeybee:before {
  content: '\e9ce';
}
/* '' */
.glyphicons.honeycombs:before {
  content: '\e9cf';
}
/* '' */
.glyphicons.hourglass:before {
  content: '\e9d0';
}
/* '' */
.glyphicons.ice-cream:before {
  content: '\e9d1';
}
/* '' */
.glyphicons.ice-cream-off:before,
.glyphicons.ice-cream-no:before {
  content: '\e9d2';
}
/* '' */
.glyphicons.ice-lolly:before {
  content: '\e9d3';
}
/* '' */
.glyphicons.ice-lolly-eaten:before,
.glyphicons.ice-lolly-tasted:before {
  content: '\e9d4';
}
/* '' */
.glyphicons.id:before {
  content: '\e9d5';
}
/* '' */
.glyphicons.id-badge:before,
.glyphicons.nameplate:before {
  content: '\e9d6';
}
/* '' */
.glyphicons.id-badge-lanyard:before {
  content: '\e9d7';
}
/* '' */
.glyphicons.id-badge-vertical:before {
  content: '\e9d8';
}
/* '' */
.glyphicons.id-binder:before,
.glyphicons.nameplate-alt:before,
.glyphicons.vcard:before {
  content: '\e9d9';
}
/* '' */
.glyphicons.import:before,
.glyphicons.disk-import:before,
.glyphicons.file-import:before {
  content: '\e9da';
}
/* '' */
.glyphicons.inbox:before {
  content: '\e9db';
}
/* '' */
.glyphicons.inbox-down:before,
.glyphicons.inbox-in:before {
  content: '\e9dc';
}
/* '' */
.glyphicons.inbox-lock:before {
  content: '\e9dd';
}
/* '' */
.glyphicons.inbox-minus:before {
  content: '\e9de';
}
/* '' */
.glyphicons.inbox-plus:before {
  content: '\e9df';
}
/* '' */
.glyphicons.inbox-up:before,
.glyphicons.inbox-out:before {
  content: '\e9e0';
}
/* '' */
.glyphicons.indent-left:before,
.glyphicons.left-indent:before {
  content: '\e9e1';
}
/* '' */
.glyphicons.indent-right:before,
.glyphicons.right-indent:before {
  content: '\e9e2';
}
/* '' */
.glyphicons.initial-letter:before {
  content: '\e9e3';
}
/* '' */
.glyphicons.intersex:before {
  content: '\e9e4';
}
/* '' */
.glyphicons.invoice:before {
  content: '\e9e5';
}
/* '' */
.glyphicons.invoice-alert:before {
  content: '\e9e6';
}
/* '' */
.glyphicons.invoice-check:before {
  content: '\e9e7';
}
/* '' */
.glyphicons.invoice-minus:before {
  content: '\e9e8';
}
/* '' */
.glyphicons.invoice-plus:before {
  content: '\e9e9';
}
/* '' */
.glyphicons.iris-scan:before {
  content: '\e9ea';
}
/* '' */
.glyphicons.italic:before {
  content: '\e9eb';
}
/* '' */
.glyphicons.kettle:before {
  content: '\e9ec';
}
/* '' */
.glyphicons.kettlebell:before {
  content: '\e9ed';
}
/* '' */
.glyphicons.key:before {
  content: '\e9ee';
}
/* '' */
.glyphicons.keyboard:before,
.glyphicons.keyboard-wired:before {
  content: '\e9ef';
}
/* '' */
.glyphicons.keyboard-wireless:before {
  content: '\e9f0';
}
/* '' */
.glyphicons.key-rounded:before,
.glyphicons.keys:before {
  content: '\e9f1';
}
/* '' */
.glyphicons.king:before {
  content: '\e9f2';
}
/* '' */
.glyphicons.kiosk:before {
  content: '\e9f3';
}
/* '' */
.glyphicons.kiosk-food:before {
  content: '\e9f4';
}
/* '' */
.glyphicons.kiosk-immobile:before,
.glyphicons.kiosk-light:before {
  content: '\e9f5';
}
/* '' */
.glyphicons.kiosk-wheels:before {
  content: '\e9f6';
}
/* '' */
.glyphicons.knight:before {
  content: '\e9f7';
}
/* '' */
.glyphicons.lamp:before,
.glyphicons.bedroom-lamp:before {
  content: '\e9f8';
}
/* '' */
.glyphicons.lamp-table:before {
  content: '\e9f9';
}
/* '' */
.glyphicons.lasso:before {
  content: '\e9fa';
}
/* '' */
.glyphicons.lawn-mower:before {
  content: '\e9fb';
}
/* '' */
.glyphicons.layers:before {
  content: '\e9fc';
}
/* '' */
.glyphicons.layers-cogwheel:before {
  content: '\e9fd';
}
/* '' */
.glyphicons.layers-down:before {
  content: '\e9fe';
}
/* '' */
.glyphicons.layers-lock:before {
  content: '\e9ff';
}
/* '' */
.glyphicons.layers-minus:before {
  content: '\ea00';
}
/* '' */
.glyphicons.layers-plus:before {
  content: '\ea01';
}
/* '' */
.glyphicons.layers-up:before {
  content: '\ea02';
}
/* '' */
.glyphicons.leaf:before {
  content: '\ea03';
}
/* '' */
.glyphicons.leather:before {
  content: '\ea04';
}
/* '' */
.glyphicons.legal-section-sign:before {
  content: '\ea05';
}
/* '' */
.glyphicons.lesbian:before {
  content: '\ea06';
}
/* '' */
.glyphicons.light-beacon:before {
  content: '\ea07';
}
/* '' */
.glyphicons.lightbulb:before {
  content: '\ea08';
}
/* '' */
.glyphicons.lighter:before {
  content: '\ea09';
}
/* '' */
.glyphicons.link:before {
  content: '\ea0a';
}
/* '' */
.glyphicons.link-remove:before {
  content: '\ea0b';
}
/* '' */
.glyphicons.list:before {
  content: '\ea0c';
}
/* '' */
.glyphicons.list-letters:before {
  content: '\ea0d';
}
/* '' */
.glyphicons.list-numbers:before {
  content: '\ea0e';
}
/* '' */
.glyphicons.lock:before {
  content: '\ea0f';
}
/* '' */
.glyphicons.lock-off:before {
  content: '\ea10';
}
/* '' */
.glyphicons.lock-open:before,
.glyphicons.unlock:before {
  content: '\ea11';
}
/* '' */
.glyphicons.log-in:before {
  content: '\ea12';
}
/* '' */
.glyphicons.log-out:before {
  content: '\ea13';
}
/* '' */
.glyphicons.lp:before {
  content: '\ea14';
}
/* '' */
.glyphicons.luggage:before {
  content: '\ea15';
}
/* '' */
.glyphicons.luggage-alt:before {
  content: '\ea16';
}
/* '' */
.glyphicons.lupin:before {
  content: '\ea17';
}
/* '' */
.glyphicons.magic-wand:before,
.glyphicons.magic:before {
  content: '\ea18';
}
/* '' */
.glyphicons.magnet:before {
  content: '\ea19';
}
/* '' */
.glyphicons.male:before {
  content: '\ea1a';
}
/* '' */
.glyphicons.map:before {
  content: '\ea1b';
}
/* '' */
.glyphicons.map-aerial:before {
  content: '\ea1c';
}
/* '' */
.glyphicons.map-cadastral:before {
  content: '\ea1d';
}
/* '' */
.glyphicons.map-marker:before {
  content: '\ea1e';
}
/* '' */
.glyphicons.map-satellite:before {
  content: '\ea1f';
}
/* '' */
.glyphicons.map-tourist:before {
  content: '\ea20';
}
/* '' */
.glyphicons.marker:before {
  content: '\ea21';
}
/* '' */
.glyphicons.marriage:before {
  content: '\ea22';
}
/* '' */
.glyphicons.mc:before {
  content: '\ea23';
}
/* '' */
.glyphicons.meat:before {
  content: '\ea24';
}
/* '' */
.glyphicons.medal:before {
  content: '\ea25';
}
/* '' */
.glyphicons.medicine:before {
  content: '\ea26';
}
/* '' */
.glyphicons.memory-card:before {
  content: '\ea27';
}
/* '' */
.glyphicons.memory-card-cogwheel:before {
  content: '\ea28';
}
/* '' */
.glyphicons.memory-card-eject:before {
  content: '\ea29';
}
/* '' */
.glyphicons.memory-card-lock:before {
  content: '\ea2a';
}
/* '' */
.glyphicons.memory-card-no:before {
  content: '\ea2b';
}
/* '' */
.glyphicons.menu:before,
.glyphicons.menu-hamburger:before,
.glyphicons.show-lines:before {
  content: '\ea2c';
}
/* '' */
.glyphicons.menu-close:before {
  content: '\ea2d';
}
/* '' */
.glyphicons.metro:before {
  content: '\ea2e';
}
/* '' */
.glyphicons.mic:before,
.glyphicons.microphone:before {
  content: '\ea2f';
}
/* '' */
.glyphicons.mic-off:before,
.glyphicons.mic-mute:before {
  content: '\ea30';
}
/* '' */
.glyphicons.microchip:before {
  content: '\ea31';
}
/* '' */
.glyphicons.microwave:before {
  content: '\ea32';
}
/* '' */
.glyphicons.milk:before {
  content: '\ea33';
}
/* '' */
.glyphicons.minus:before {
  content: '\ea34';
}
/* '' */
.glyphicons.missile:before {
  content: '\ea35';
}
/* '' */
.glyphicons.mobile-phone:before,
.glyphicons.iphone:before {
  content: '\ea36';
}
/* '' */
.glyphicons.mobile-phone-alert:before {
  content: '\ea37';
}
/* '' */
.glyphicons.mobile-phone-charger:before {
  content: '\ea38';
}
/* '' */
.glyphicons.mobile-phone-check:before {
  content: '\ea39';
}
/* '' */
.glyphicons.mobile-phone-cogwheel:before {
  content: '\ea3a';
}
/* '' */
.glyphicons.mobile-phone-lock:before {
  content: '\ea3b';
}
/* '' */
.glyphicons.mobile-phone-nfc:before {
  content: '\ea3c';
}
/* '' */
.glyphicons.mobile-phone-off:before {
  content: '\ea3d';
}
/* '' */
.glyphicons.mobile-phone-scan:before {
  content: '\ea3e';
}
/* '' */
.glyphicons.mobile-phone-shaking:before,
.glyphicons.iphone-shake:before {
  content: '\ea3f';
}
/* '' */
.glyphicons.mobile-phone-vibrations:before {
  content: '\ea40';
}
/* '' */
.glyphicons.modal-window:before {
  content: '\ea41';
}
/* '' */
.glyphicons.molluscs:before {
  content: '\ea42';
}
/* '' */
.glyphicons.monitor:before,
.glyphicons.display:before,
.glyphicons.imac:before {
  content: '\ea43';
}
/* '' */
.glyphicons.monitor-rotate:before {
  content: '\ea44';
}
/* '' */
.glyphicons.monitors:before,
.glyphicons.multiple-displays:before {
  content: '\ea45';
}
/* '' */
.glyphicons.monitor-vertical:before {
  content: '\ea46';
}
/* '' */
.glyphicons.moon:before {
  content: '\ea47';
}
/* '' */
.glyphicons.more:before,
.glyphicons.option-horizontal:before {
  content: '\ea48';
}
/* '' */
.glyphicons.more-vertical:before,
.glyphicons.option-vertical:before {
  content: '\ea49';
}
/* '' */
.glyphicons.motorbike:before,
.glyphicons.motorcycle:before {
  content: '\ea4a';
}
/* '' */
.glyphicons.motor-oil:before {
  content: '\ea4b';
}
/* '' */
.glyphicons.mouse:before {
  content: '\ea4c';
}
/* '' */
.glyphicons.mouse-wireless:before {
  content: '\ea4d';
}
/* '' */
.glyphicons.mountains:before {
  content: '\ea4e';
}
/* '' */
.glyphicons.multifunction-knife:before {
  content: '\ea4f';
}
/* '' */
.glyphicons.multifunction-printer:before {
  content: '\ea50';
}
/* '' */
.glyphicons.music:before {
  content: '\ea51';
}
/* '' */
.glyphicons.music-alt:before {
  content: '\ea52';
}
/* '' */
.glyphicons.nearby:before {
  content: '\ea53';
}
/* '' */
.glyphicons.mustard:before,
.glyphicons.baby-formula:before {
  content: '\ea54';
}
/* '' */
.glyphicons.nearby-alt:before {
  content: '\ea55';
}
/* '' */
.glyphicons.neighborhood:before {
  content: '\ea56';
}
/* '' */
.glyphicons.newspaper:before {
  content: '\ea57';
}
/* '' */
.glyphicons.no-symbol:before,
.glyphicons.ban-circle:before {
  content: '\ea58';
}
/* '' */
.glyphicons.notebook:before,
.glyphicons.macbook:before {
  content: '\ea59';
}
/* '' */
.glyphicons.notes:before,
.glyphicons.note:before {
  content: '\ea5a';
}
/* '' */
.glyphicons.nuts:before {
  content: '\ea5b';
}
/* '' */
.glyphicons.octagon-remove:before {
  content: '\ea5c';
}
/* '' */
.glyphicons.octagon-remove-empty:before {
  content: '\ea5d';
}
/* '' */
.glyphicons.open:before,
.glyphicons.disk-open:before {
  content: '\ea5e';
}
/* '' */
.glyphicons.other:before {
  content: '\ea5f';
}
/* '' */
.glyphicons.one-day-delivery:before {
  content: '\ea60';
}
/* '' */
.glyphicons.oven:before {
  content: '\ea61';
}
/* '' */
.glyphicons.oxygen-bottles:before,
.glyphicons.oxygen-bottle:before {
  content: '\ea62';
}
/* '' */
.glyphicons.package:before {
  content: '\ea63';
}
/* '' */
.glyphicons.paired:before {
  content: '\ea64';
}
/* '' */
.glyphicons.paired-off:before {
  content: '\ea65';
}
/* '' */
.glyphicons.palette:before {
  content: '\ea66';
}
/* '' */
.glyphicons.palette-package:before,
.glyphicons.cargo:before {
  content: '\ea67';
}
/* '' */
.glyphicons.paperclip:before {
  content: '\ea68';
}
/* '' */
.glyphicons.paragraph-center:before {
  content: '\ea69';
}
/* '' */
.glyphicons.paragraph-justify:before,
.glyphicons.justify:before,
.glyphicons.paragraph:before {
  content: '\ea6a';
}
/* '' */
.glyphicons.paragraph-left:before {
  content: '\ea6b';
}
/* '' */
.glyphicons.paragraph-right:before {
  content: '\ea6c';
}
/* '' */
.glyphicons.park:before {
  content: '\ea6d';
}
/* '' */
.glyphicons.parking:before {
  content: '\ea6e';
}
/* '' */
.glyphicons.parking-meter:before {
  content: '\ea6f';
}
/* '' */
.glyphicons.passport:before {
  content: '\ea70';
}
/* '' */
.glyphicons.passport-alt:before {
  content: '\ea71';
}
/* '' */
.glyphicons.paste:before {
  content: '\ea72';
}
/* '' */
.glyphicons.pause:before {
  content: '\ea73';
}
/* '' */
.glyphicons.pawn:before {
  content: '\ea74';
}
/* '' */
.glyphicons.payment:before {
  content: '\ea75';
}
/* '' */
.glyphicons.peanuts:before {
  content: '\ea76';
}
/* '' */
.glyphicons.pen:before {
  content: '\ea77';
}
/* '' */
.glyphicons.pencil:before {
  content: '\ea78';
}
/* '' */
.glyphicons.percent-sign:before {
  content: '\ea79';
}
/* '' */
.glyphicons.personal-hygiene:before,
.glyphicons.hygiene-kit:before {
  content: '\ea7a';
}
/* '' */
.glyphicons.phone-horizontal:before {
  content: '\ea7b';
}
/* '' */
.glyphicons.phone-old:before,
.glyphicons.phone-alt:before {
  content: '\ea7c';
}
/* '' */
.glyphicons.phone-rotate:before {
  content: '\ea7d';
}
/* '' */
.glyphicons.phone-to-horizontal:before {
  content: '\ea7e';
}
/* '' */
.glyphicons.picture:before,
.glyphicons.pictures:before {
  content: '\ea7f';
}
/* '' */
.glyphicons.piggy-bank:before {
  content: '\ea80';
}
/* '' */
.glyphicons.piggy-bank-coins:before {
  content: '\ea81';
}
/* '' */
.glyphicons.pills:before {
  content: '\ea82';
}
/* '' */
.glyphicons.pizza:before {
  content: '\ea83';
}
/* '' */
.glyphicons.plant:before {
  content: '\ea84';
}
/* '' */
.glyphicons.play:before {
  content: '\ea85';
}
/* '' */
.glyphicons.playlist:before {
  content: '\ea86';
}
/* '' */
.glyphicons.playlist-music:before,
.glyphicons.albums:before {
  content: '\ea87';
}
/* '' */
.glyphicons.playlist-video:before {
  content: '\ea88';
}
/* '' */
.glyphicons.plus:before {
  content: '\ea89';
}
/* '' */
.glyphicons.podcast:before {
  content: '\ea8a';
}
/* '' */
.glyphicons.police:before {
  content: '\ea8b';
}
/* '' */
.glyphicons.pool:before {
  content: '\ea8c';
}
/* '' */
.glyphicons.pot:before {
  content: '\ea8d';
}
/* '' */
.glyphicons.pound:before,
.glyphicons.gbp:before {
  content: '\ea8e';
}
/* '' */
.glyphicons.power:before {
  content: '\ea8f';
}
/* '' */
.glyphicons.power-cord-plug:before,
.glyphicons.electrical-plug:before {
  content: '\ea90';
}
/* '' */
.glyphicons.power-cord-plug-off:before {
  content: '\ea91';
}
/* '' */
.glyphicons.power-plant-solar:before {
  content: '\ea92';
}
/* '' */
.glyphicons.power-plant-water:before {
  content: '\ea93';
}
/* '' */
.glyphicons.power-plant-wind:before {
  content: '\ea94';
}
/* '' */
.glyphicons.presentation:before,
.glyphicons.keynote:before {
  content: '\ea95';
}
/* '' */
.glyphicons.print:before {
  content: '\ea96';
}
/* '' */
.glyphicons.progress:before {
  content: '\ea97';
}
/* '' */
.glyphicons.projector:before {
  content: '\ea98';
}
/* '' */
.glyphicons.pulse:before,
.glyphicons.cardio:before {
  content: '\ea99';
}
/* '' */
.glyphicons.push-pin:before,
.glyphicons.pushpin:before {
  content: '\ea9a';
}
/* '' */
.glyphicons.puzzle:before {
  content: '\ea9b';
}
/* '' */
.glyphicons.qr-code:before,
.glyphicons.qrcode:before {
  content: '\ea9c';
}
/* '' */
.glyphicons.quad-bike:before,
.glyphicons.atv-bike:before {
  content: '\ea9d';
}
/* '' */
.glyphicons.queen:before {
  content: '\ea9e';
}
/* '' */
.glyphicons.rabbit:before {
  content: '\ea9f';
}
/* '' */
.glyphicons.radar:before {
  content: '\eaa0';
}
/* '' */
.glyphicons.radio:before {
  content: '\eaa1';
}
/* '' */
.glyphicons.radioactive:before,
.glyphicons.hazard:before {
  content: '\eaa2';
}
/* '' */
.glyphicons.random:before {
  content: '\eaa3';
}
/* '' */
.glyphicons.receipt:before {
  content: '\eaa4';
}
/* '' */
.glyphicons.rechargeable:before {
  content: '\eaa5';
}
/* '' */
.glyphicons.recycling:before {
  content: '\eaa6';
}
/* '' */
.glyphicons.redo:before {
  content: '\eaa7';
}
/* '' */
.glyphicons.reflect-x:before {
  content: '\eaa8';
}
/* '' */
.glyphicons.reflect-y:before {
  content: '\eaa9';
}
/* '' */
.glyphicons.refresh:before {
  content: '\eaaa';
}
/* '' */
.glyphicons.reload:before {
  content: '\eaab';
}
/* '' */
.glyphicons.reload-alt:before {
  content: '\eaac';
}
/* '' */
.glyphicons.remote-control:before,
.glyphicons.remote-control-tv:before {
  content: '\eaad';
}
/* '' */
.glyphicons.repeat:before {
  content: '\eaae';
}
/* '' */
.glyphicons.repeat-once:before {
  content: '\eaaf';
}
/* '' */
.glyphicons.replay:before {
  content: '\eab0';
}
/* '' */
.glyphicons.reply:before,
.glyphicons.unshare:before {
  content: '\eab1';
}
/* '' */
.glyphicons.reply-all:before,
.glyphicons.message-forward:before {
  content: '\eab2';
}
/* '' */
.glyphicons.resize-horizontal:before {
  content: '\eab3';
}
/* '' */
.glyphicons.resize-vertical:before {
  content: '\eab4';
}
/* '' */
.glyphicons.restart:before {
  content: '\eab5';
}
/* '' */
.glyphicons.retweet:before,
.glyphicons.retweet-2:before {
  content: '\eab6';
}
/* '' */
.glyphicons.rewind:before {
  content: '\eab7';
}
/* '' */
.glyphicons.rewind-fifteen:before {
  content: '\eab8';
}
/* '' */
.glyphicons.rewind-five:before {
  content: '\eab9';
}
/* '' */
.glyphicons.rewind-ten:before {
  content: '\eaba';
}
/* '' */
.glyphicons.rewind-thirty:before {
  content: '\eabb';
}
/* '' */
.glyphicons.road:before {
  content: '\eabc';
}
/* '' */
.glyphicons.robot:before {
  content: '\eabd';
}
/* '' */
.glyphicons.rook:before,
.glyphicons.tower:before {
  content: '\eabe';
}
/* '' */
.glyphicons.rotate:before {
  content: '\eabf';
}
/* '' */
.glyphicons.rotate-horizontal:before {
  content: '\eac0';
}
/* '' */
.glyphicons.rotate-vertical:before {
  content: '\eac1';
}
/* '' */
.glyphicons.roundabout:before {
  content: '\eac2';
}
/* '' */
.glyphicons.route:before {
  content: '\eac3';
}
/* '' */
.glyphicons.router:before {
  content: '\eac4';
}
/* '' */
.glyphicons.ruble:before {
  content: '\eac5';
}
/* '' */
.glyphicons.rugby:before {
  content: '\eac6';
}
/* '' */
.glyphicons.ruler:before {
  content: '\eac7';
}
/* '' */
.glyphicons.rulers:before {
  content: '\eac8';
}
/* '' */
.glyphicons.safe:before {
  content: '\eac9';
}
/* '' */
.glyphicons.safe-digital:before {
  content: '\eaca';
}
/* '' */
.glyphicons.safe-open:before {
  content: '\eacb';
}
/* '' */
.glyphicons.sailboat:before {
  content: '\eacc';
}
/* '' */
.glyphicons.sampler:before {
  content: '\eacd';
}
/* '' */
.glyphicons.satellite:before {
  content: '\eace';
}
/* '' */
.glyphicons.satellite-dish:before {
  content: '\eacf';
}
/* '' */
.glyphicons.satellite-dish-alt:before {
  content: '\ead0';
}
/* '' */
.glyphicons.save:before,
.glyphicons.disk-save:before {
  content: '\ead1';
}
/* '' */
.glyphicons.save-as:before {
  content: '\ead2';
}
/* '' */
.glyphicons.saw:before,
.glyphicons.hand-saw:before {
  content: '\ead3';
}
/* '' */
.glyphicons.saw-blade:before {
  content: '\ead4';
}
/* '' */
.glyphicons.scale-kitchen:before {
  content: '\ead5';
}
/* '' */
.glyphicons.scale-personal:before,
.glyphicons.scale:before {
  content: '\ead6';
}
/* '' */
.glyphicons.scanner:before {
  content: '\ead7';
}
/* '' */
.glyphicons.scissors:before {
  content: '\ead8';
}
/* '' */
.glyphicons.scissors-cutting:before {
  content: '\ead9';
}
/* '' */
.glyphicons.scissors-horizontal:before,
.glyphicons.scissors-alt:before {
  content: '\eada';
}
/* '' */
.glyphicons.screwdriver:before {
  content: '\eadb';
}
/* '' */
.glyphicons.seafood:before {
  content: '\eadc';
}
/* '' */
.glyphicons.search:before {
  content: '\eadd';
}
/* '' */
.glyphicons.security-camera:before {
  content: '\eade';
}
/* '' */
.glyphicons.self-winding-cord:before {
  content: '\eadf';
}
/* '' */
.glyphicons.send:before {
  content: '\eae0';
}
/* '' */
.glyphicons.send-backward:before {
  content: '\eae1';
}
/* '' */
.glyphicons.send-to-back:before {
  content: '\eae2';
}
/* '' */
.glyphicons.server:before {
  content: '\eae3';
}
/* '' */
.glyphicons.server-check:before {
  content: '\eae4';
}
/* '' */
.glyphicons.server-cogwheel:before,
.glyphicons.server-new:before {
  content: '\eae5';
}
/* '' */
.glyphicons.server-flag:before {
  content: '\eae6';
}
/* '' */
.glyphicons.server-lock:before {
  content: '\eae7';
}
/* '' */
.glyphicons.server-minus:before {
  content: '\eae8';
}
/* '' */
.glyphicons.server-no:before,
.glyphicons.server-ban:before {
  content: '\eae9';
}
/* '' */
.glyphicons.server-plus:before {
  content: '\eaea';
}
/* '' */
.glyphicons.server-refresh:before {
  content: '\eaeb';
}
/* '' */
.glyphicons.sesame:before {
  content: '\eaec';
}
/* '' */
.glyphicons.set:before {
  content: '\eaed';
}
/* '' */
.glyphicons.set-down:before {
  content: '\eaee';
}
/* '' */
.glyphicons.settings:before {
  content: '\eaef';
}
/* '' */
.glyphicons.set-wine:before,
.glyphicons.celebration:before {
  content: '\eaf0';
}
/* '' */
.glyphicons.shadows:before {
  content: '\eaf1';
}
/* '' */
.glyphicons.share:before,
.glyphicons.share-alt:before {
  content: '\eaf2';
}
/* '' */
.glyphicons.shield-check:before {
  content: '\eaf3';
}
/* '' */
.glyphicons.shield-empty-star:before {
  content: '\eaf4';
}
/* '' */
.glyphicons.shield-half:before {
  content: '\eaf5';
}
/* '' */
.glyphicons.shield-quarter:before,
.glyphicons.shield:before {
  content: '\eaf6';
}
/* '' */
.glyphicons.shield-star:before {
  content: '\eaf7';
}
/* '' */
.glyphicons.shirt:before {
  content: '\eaf8';
}
/* '' */
.glyphicons.shoes:before {
  content: '\eaf9';
}
/* '' */
.glyphicons.shoes-off:before {
  content: '\eafa';
}
/* '' */
.glyphicons.shop-door:before,
.glyphicons.shop:before {
  content: '\eafb';
}
/* '' */
.glyphicons.shopping-bag:before {
  content: '\eafc';
}
/* '' */
.glyphicons.shop-window:before {
  content: '\eafd';
}
/* '' */
.glyphicons.shower:before {
  content: '\eafe';
}
/* '' */
.glyphicons.shower-alt:before {
  content: '\eaff';
}
/* '' */
.glyphicons.shredder:before {
  content: '\eb00';
}
/* '' */
.glyphicons.simple-trolley:before {
  content: '\eb01';
}
/* '' */
.glyphicons.skip-to-end:before,
.glyphicons.fast-forward:before {
  content: '\eb02';
}
/* '' */
.glyphicons.skip-to-next:before,
.glyphicons.step-forward:before {
  content: '\eb03';
}
/* '' */
.glyphicons.skip-to-previous:before,
.glyphicons.step-backward:before {
  content: '\eb04';
}
/* '' */
.glyphicons.skip-to-start:before,
.glyphicons.fast-backward:before {
  content: '\eb05';
}
/* '' */
.glyphicons.skull:before {
  content: '\eb06';
}
/* '' */
.glyphicons.skull-crossbones:before {
  content: '\eb07';
}
/* '' */
.glyphicons.slightly-frowning:before {
  content: '\eb08';
}
/* '' */
.glyphicons.slightly-smiling:before {
  content: '\eb09';
}
/* '' */
.glyphicons.smart-bracelet:before {
  content: '\eb0a';
}
/* '' */
.glyphicons.snowflake:before {
  content: '\eb0b';
}
/* '' */
.glyphicons.sort:before,
.glyphicons.sorting:before {
  content: '\eb0c';
}
/* '' */
.glyphicons.sort-alphabetically:before,
.glyphicons.sort-by-alphabet:before {
  content: '\eb0d';
}
/* '' */
.glyphicons.sort-alphabetically-alt:before,
.glyphicons.sort-by-alphabet-alt:before {
  content: '\eb0e';
}
/* '' */
.glyphicons.sort-attributes:before,
.glyphicons.sort-by-attributes:before {
  content: '\eb0f';
}
/* '' */
.glyphicons.sort-attributes-alt:before,
.glyphicons.sort-by-attributes-alt:before {
  content: '\eb10';
}
/* '' */
.glyphicons.sort-numerically:before,
.glyphicons.sort-by-order:before {
  content: '\eb11';
}
/* '' */
.glyphicons.sort-numerically-alt:before,
.glyphicons.sort-by-order-alt:before {
  content: '\eb12';
}
/* '' */
.glyphicons.sound-surround:before {
  content: '\eb13';
}
/* '' */
.glyphicons.soya:before {
  content: '\eb14';
}
/* '' */
.glyphicons.spade:before {
  content: '\eb15';
}
/* '' */
.glyphicons.speakers:before {
  content: '\eb16';
}
/* '' */
.glyphicons.speech-bubble-alert:before {
  content: '\eb17';
}
/* '' */
.glyphicons.speech-bubble-info:before {
  content: '\eb18';
}
/* '' */
.glyphicons.speech-bubble-question:before {
  content: '\eb19';
}
/* '' */
.glyphicons.spell-check:before {
  content: '\eb1a';
}
/* '' */
.glyphicons.spell-check-check:before {
  content: '\eb1b';
}
/* '' */
.glyphicons.spray:before {
  content: '\eb1c';
}
/* '' */
.glyphicons.square-alert:before {
  content: '\eb1d';
}
/* '' */
.glyphicons.square-check:before {
  content: '\eb1e';
}
/* '' */
.glyphicons.square-checkbox:before,
.glyphicons.check:before {
  content: '\eb1f';
}
/* '' */
.glyphicons.square-down:before {
  content: '\eb20';
}
/* '' */
.glyphicons.square-download:before {
  content: '\eb21';
}
/* '' */
.glyphicons.square-edit:before,
.glyphicons.edit:before {
  content: '\eb22';
}
/* '' */
.glyphicons.square-email:before {
  content: '\eb23';
}
/* '' */
.glyphicons.square-empty:before,
.glyphicons.unchecked:before {
  content: '\eb24';
}
/* '' */
.glyphicons.square-empty-alert:before {
  content: '\eb25';
}
/* '' */
.glyphicons.square-empty-check:before,
.glyphicons.disk-saved:before {
  content: '\eb26';
}
/* '' */
.glyphicons.square-empty-download:before,
.glyphicons.download-alt:before {
  content: '\eb27';
}
/* '' */
.glyphicons.square-empty-info:before {
  content: '\eb28';
}
/* '' */
.glyphicons.square-empty-left:before {
  content: '\eb29';
}
/* '' */
.glyphicons.square-empty-minus:before {
  content: '\eb2a';
}
/* '' */
.glyphicons.square-empty-play:before {
  content: '\eb2b';
}
/* '' */
.glyphicons.square-empty-plus:before {
  content: '\eb2c';
}
/* '' */
.glyphicons.square-empty-question:before {
  content: '\eb2d';
}
/* '' */
.glyphicons.square-empty-remove:before,
.glyphicons.disk-remove:before {
  content: '\eb2e';
}
/* '' */
.glyphicons.square-empty-right:before {
  content: '\eb2f';
}
/* '' */
.glyphicons.square-empty-upload:before {
  content: '\eb30';
}
/* '' */
.glyphicons.square-hospital:before,
.glyphicons.hospital-h:before {
  content: '\eb31';
}
/* '' */
.glyphicons.square-indeterminate:before {
  content: '\eb32';
}
/* '' */
.glyphicons.square-info:before {
  content: '\eb33';
}
/* '' */
.glyphicons.square-left:before {
  content: '\eb34';
}
/* '' */
.glyphicons.square-menu:before {
  content: '\eb35';
}
/* '' */
.glyphicons.square-minus:before {
  content: '\eb36';
}
/* '' */
.glyphicons.square-more:before {
  content: '\eb37';
}
/* '' */
.glyphicons.square-new-window:before,
.glyphicons.new-window:before,
.glyphicons.new-window-alt:before {
  content: '\eb38';
}
/* '' */
.glyphicons.square-play:before {
  content: '\eb39';
}
/* '' */
.glyphicons.square-plus:before,
.glyphicons.hospital:before {
  content: '\eb3a';
}
/* '' */
.glyphicons.square-question:before {
  content: '\eb3b';
}
/* '' */
.glyphicons.square-red-cross:before {
  content: '\eb3c';
}
/* '' */
.glyphicons.square-remove:before {
  content: '\eb3d';
}
/* '' */
.glyphicons.square-right:before {
  content: '\eb3e';
}
/* '' */
.glyphicons.squares:before,
.glyphicons.more-windows:before {
  content: '\eb3f';
}
/* '' */
.glyphicons.square-selected:before {
  content: '\eb40';
}
/* '' */
.glyphicons.square-triangle-down:before,
.glyphicons.expand:before {
  content: '\eb41';
}
/* '' */
.glyphicons.square-triangle-left:before {
  content: '\eb42';
}
/* '' */
.glyphicons.square-triangle-right:before,
.glyphicons.collapse:before {
  content: '\eb43';
}
/* '' */
.glyphicons.square-triangle-up:before,
.glyphicons.collapse-up:before {
  content: '\eb44';
}
/* '' */
.glyphicons.square-up:before {
  content: '\eb45';
}
/* '' */
.glyphicons.square-upload:before {
  content: '\eb46';
}
/* '' */
.glyphicons.star:before {
  content: '\eb47';
}
/* '' */
.glyphicons.star-empty:before {
  content: '\eb48';
}
/* '' */
.glyphicons.star-half:before {
  content: '\eb49';
}
/* '' */
.glyphicons.star-of-life:before,
.glyphicons.asterisk:before {
  content: '\eb4a';
}
/* '' */
.glyphicons.star-of-life-empty:before {
  content: '\eb4b';
}
/* '' */
.glyphicons.start:before {
  content: '\eb4c';
}
/* '' */
.glyphicons.stats:before {
  content: '\eb4d';
}
/* '' */
.glyphicons.stats-bars:before,
.glyphicons.charts:before {
  content: '\eb4e';
}
/* '' */
.glyphicons.stats-circle:before,
.glyphicons.pie-chart:before {
  content: '\eb4f';
}
/* '' */
.glyphicons.step-back:before {
  content: '\eb50';
}
/* '' */
.glyphicons.step-forward:before {
  content: '\eb51';
}
/* '' */
.glyphicons.stop:before {
  content: '\eb52';
}
/* '' */
.glyphicons.stopwatch:before {
  content: '\eb53';
}
/* '' */
.glyphicons.strikethrough:before,
.glyphicons.text-strike:before {
  content: '\eb54';
}
/* '' */
.glyphicons.stroller:before {
  content: '\eb55';
}
/* '' */
.glyphicons.subscript:before {
  content: '\eb56';
}
/* '' */
.glyphicons.subtitles:before {
  content: '\eb57';
}
/* '' */
.glyphicons.subtitles-minus:before {
  content: '\eb58';
}
/* '' */
.glyphicons.subtitles-off:before {
  content: '\eb59';
}
/* '' */
.glyphicons.subtitles-plus:before {
  content: '\eb5a';
}
/* '' */
.glyphicons.suitcase-doctor:before {
  content: '\eb5b';
}
/* '' */
.glyphicons.sun:before {
  content: '\eb5c';
}
/* '' */
.glyphicons.superscript:before {
  content: '\eb5d';
}
/* '' */
.glyphicons.swimming-pool:before {
  content: '\eb5e';
}
/* '' */
.glyphicons.switch:before {
  content: '\eb5f';
}
/* '' */
.glyphicons.switch-off:before {
  content: '\eb60';
}
/* '' */
.glyphicons.switch-on:before {
  content: '\eb61';
}
/* '' */
.glyphicons.sync:before {
  content: '\eb62';
}
/* '' */
.glyphicons.sync-alert:before {
  content: '\eb63';
}
/* '' */
.glyphicons.sync-check:before {
  content: '\eb64';
}
/* '' */
.glyphicons.syringe:before {
  content: '\eb65';
}
/* '' */
.glyphicons.syringe-empty:before {
  content: '\eb66';
}
/* '' */
.glyphicons.table:before {
  content: '\eb67';
}
/* '' */
.glyphicons.tablet:before,
.glyphicons.ipad:before {
  content: '\eb68';
}
/* '' */
.glyphicons.table-tennis:before {
  content: '\eb69';
}
/* '' */
.glyphicons.tag:before {
  content: '\eb6a';
}
/* '' */
.glyphicons.tags:before {
  content: '\eb6b';
}
/* '' */
.glyphicons.target:before {
  content: '\eb6c';
}
/* '' */
.glyphicons.tasks:before,
.glyphicons.list-alt:before {
  content: '\eb6d';
}
/* '' */
.glyphicons.taxi:before {
  content: '\eb6e';
}
/* '' */
.glyphicons.tea-leaves:before {
  content: '\eb6f';
}
/* '' */
.glyphicons.tea-mug:before {
  content: '\eb70';
}
/* '' */
.glyphicons.tea-pot:before,
.glyphicons.tea-kettle:before {
  content: '\eb71';
}
/* '' */
.glyphicons.temperature:before {
  content: '\eb72';
}
/* '' */
.glyphicons.temperature-alert:before {
  content: '\eb73';
}
/* '' */
.glyphicons.temperature-high:before {
  content: '\eb74';
}
/* '' */
.glyphicons.temperature-low:before {
  content: '\eb75';
}
/* '' */
.glyphicons.temperature-set:before {
  content: '\eb76';
}
/* '' */
.glyphicons.tennis:before {
  content: '\eb77';
}
/* '' */
.glyphicons.tent:before {
  content: '\eb78';
}
/* '' */
.glyphicons.terminal:before {
  content: '\eb79';
}
/* '' */
.glyphicons.terminal-empty:before {
  content: '\eb7a';
}
/* '' */
.glyphicons.terminal-isolated:before {
  content: '\eb7b';
}
/* '' */
.glyphicons.text:before {
  content: '\eb7c';
}
/* '' */
.glyphicons.text-background:before {
  content: '\eb7d';
}
/* '' */
.glyphicons.text-bigger:before {
  content: '\eb7e';
}
/* '' */
.glyphicons.text-height:before {
  content: '\eb7f';
}
/* '' */
.glyphicons.text-resize:before {
  content: '\eb80';
}
/* '' */
.glyphicons.text-smaller:before {
  content: '\eb81';
}
/* '' */
.glyphicons.text-underline:before {
  content: '\eb82';
}
/* '' */
.glyphicons.text-width:before {
  content: '\eb83';
}
/* '' */
.glyphicons.theater-masks:before {
  content: '\eb84';
}
/* '' */
.glyphicons.this-side-up:before {
  content: '\eb85';
}
/* '' */
.glyphicons.three-dimensional:before,
.glyphicons.cube-empty:before {
  content: '\eb86';
}
/* '' */
.glyphicons.three-dimensional-full:before {
  content: '\eb87';
}
/* '' */
.glyphicons.thumbnails:before,
.glyphicons.show-big-thumbnails:before {
  content: '\eb88';
}
/* '' */
.glyphicons.thumbnails-list:before,
.glyphicons.show-thumbnails-with-lines:before {
  content: '\eb89';
}
/* '' */
.glyphicons.thumbnails-small:before,
.glyphicons.show-thumbnails:before {
  content: '\eb8a';
}
/* '' */
.glyphicons.ticket:before {
  content: '\eb8b';
}
/* '' */
.glyphicons.ticket-parking:before {
  content: '\eb8c';
}
/* '' */
.glyphicons.tie:before {
  content: '\eb8d';
}
/* '' */
.glyphicons.timer:before {
  content: '\eb8e';
}
/* '' */
.glyphicons.times:before,
.glyphicons.remove:before {
  content: '\eb8f';
}
/* '' */
.glyphicons.toilet:before {
  content: '\eb90';
}
/* '' */
.glyphicons.tornado:before {
  content: '\eb91';
}
/* '' */
.glyphicons.tractor:before {
  content: '\eb92';
}
/* '' */
.glyphicons.traffic-lights:before {
  content: '\eb93';
}
/* '' */
.glyphicons.traffic-lights-off:before {
  content: '\eb94';
}
/* '' */
.glyphicons.train:before {
  content: '\eb95';
}
/* '' */
.glyphicons.tram:before {
  content: '\eb96';
}
/* '' */
.glyphicons.transgender:before {
  content: '\eb97';
}
/* '' */
.glyphicons.translate:before {
  content: '\eb98';
}
/* '' */
.glyphicons.translate-alt:before {
  content: '\eb99';
}
/* '' */
.glyphicons.transport-mixed:before,
.glyphicons.transport:before {
  content: '\eb9a';
}
/* '' */
.glyphicons.transport-trolley:before {
  content: '\eb9b';
}
/* '' */
.glyphicons.tree-conifer:before {
  content: '\eb9c';
}
/* '' */
.glyphicons.tree-deciduous:before {
  content: '\eb9d';
}
/* '' */
.glyphicons.trending:before {
  content: '\eb9e';
}
/* '' */
.glyphicons.triangle-alert:before,
.glyphicons.alert:before {
  content: '\eb9f';
}
/* '' */
.glyphicons.triangle-empty-alert:before,
.glyphicons.warning-sign:before {
  content: '\eba0';
}
/* '' */
.glyphicons.triangle-radioactive:before,
.glyphicons.hazard-sign:before {
  content: '\eba1';
}
/* '' */
.glyphicons.trousers:before,
.glyphicons.pants:before {
  content: '\eba2';
}
/* '' */
.glyphicons.truck:before {
  content: '\eba3';
}
/* '' */
.glyphicons.t-shirt:before {
  content: '\eba4';
}
/* '' */
.glyphicons.t-shirt-sleeves:before {
  content: '\eba5';
}
/* '' */
.glyphicons.t-shirt-v:before {
  content: '\eba6';
}
/* '' */
.glyphicons.turtle:before {
  content: '\eba7';
}
/* '' */
.glyphicons.tv:before {
  content: '\eba8';
}
/* '' */
.glyphicons.tv-streaming:before {
  content: '\eba9';
}
/* '' */
.glyphicons.umbrella:before {
  content: '\ebaa';
}
/* '' */
.glyphicons.underline:before {
  content: '\ebab';
}
/* '' */
.glyphicons.underwear:before {
  content: '\ebac';
}
/* '' */
.glyphicons.undo:before {
  content: '\ebad';
}
/* '' */
.glyphicons.unknown:before {
  content: '\ebae';
}
/* '' */
.glyphicons.usb-device:before {
  content: '\ebaf';
}
/* '' */
.glyphicons.usb-device-cogwheel:before {
  content: '\ebb0';
}
/* '' */
.glyphicons.usb-device-eject:before {
  content: '\ebb1';
}
/* '' */
.glyphicons.usb-device-lock:before {
  content: '\ebb2';
}
/* '' */
.glyphicons.usb-device-no:before {
  content: '\ebb3';
}
/* '' */
.glyphicons.user:before {
  content: '\ebb4';
}
/* '' */
.glyphicons.user-alert:before {
  content: '\ebb5';
}
/* '' */
.glyphicons.user-boy:before {
  content: '\ebb6';
}
/* '' */
.glyphicons.user-chat:before,
.glyphicons.user-conversation:before {
  content: '\ebb7';
}
/* '' */
.glyphicons.user-check:before {
  content: '\ebb8';
}
/* '' */
.glyphicons.user-child:before,
.glyphicons.child:before {
  content: '\ebb9';
}
/* '' */
.glyphicons.user-family:before,
.glyphicons.family:before {
  content: '\ebba';
}
/* '' */
.glyphicons.user-female:before,
.glyphicons.woman:before {
  content: '\ebbb';
}
/* '' */
.glyphicons.user-flag:before {
  content: '\ebbc';
}
/* '' */
.glyphicons.user-girl:before,
.glyphicons.girl:before {
  content: '\ebbd';
}
/* '' */
.glyphicons.user-group:before,
.glyphicons.group:before {
  content: '\ebbe';
}
/* '' */
.glyphicons.user-incognito:before,
.glyphicons.old-man:before {
  content: '\ebbf';
}
/* '' */
.glyphicons.user-lock:before,
.glyphicons.user-key:before {
  content: '\ebc0';
}
/* '' */
.glyphicons.user-minus:before,
.glyphicons.user-remove:before {
  content: '\ebc1';
}
/* '' */
.glyphicons.user-no:before,
.glyphicons.user-ban:before {
  content: '\ebc2';
}
/* '' */
.glyphicons.user-parents:before,
.glyphicons.parents:before {
  content: '\ebc3';
}
/* '' */
.glyphicons.user-parents-alt:before {
  content: '\ebc4';
}
/* '' */
.glyphicons.user-plus:before,
.glyphicons.user-add:before {
  content: '\ebc5';
}
/* '' */
.glyphicons.user-rounded:before {
  content: '\ebc6';
}
/* '' */
.glyphicons.users:before {
  content: '\ebc7';
}
/* '' */
.glyphicons.user-scam:before {
  content: '\ebc8';
}
/* '' */
.glyphicons.user-scam-alt:before {
  content: '\ebc9';
}
/* '' */
.glyphicons.user-squared:before {
  content: '\ebca';
}
/* '' */
.glyphicons.user-star:before,
.glyphicons.user-asterisk:before {
  content: '\ebcb';
}
/* '' */
.glyphicons.user-voice:before {
  content: '\ebcc';
}
/* '' */
.glyphicons.user-vr:before {
  content: '\ebcd';
}
/* '' */
.glyphicons.user-vr-minus:before {
  content: '\ebce';
}
/* '' */
.glyphicons.user-vr-plus:before {
  content: '\ebcf';
}
/* '' */
.glyphicons.user-worker:before {
  content: '\ebd0';
}
/* '' */
.glyphicons.vases:before {
  content: '\ebd1';
}
/* '' */
.glyphicons.vector-path:before,
.glyphicons.vector-path-all:before,
.glyphicons.vector-path-square:before {
  content: '\ebd2';
}
/* '' */
.glyphicons.vector-path-circle:before {
  content: '\ebd3';
}
/* '' */
.glyphicons.vector-path-curve:before {
  content: '\ebd4';
}
/* '' */
.glyphicons.vector-path-edit:before {
  content: '\ebd5';
}
/* '' */
.glyphicons.vector-path-line:before {
  content: '\ebd6';
}
/* '' */
.glyphicons.vector-path-pentagon:before,
.glyphicons.vector-path-polygon:before {
  content: '\ebd7';
}
/* '' */
.glyphicons.verify-check:before {
  content: '\ebd8';
}
/* '' */
.glyphicons.verify-empty:before {
  content: '\ebd9';
}
/* '' */
.glyphicons.video:before,
.glyphicons.facetime-video:before {
  content: '\ebda';
}
/* '' */
.glyphicons.video-camera:before,
.glyphicons.camera-small:before {
  content: '\ebdb';
}
/* '' */
.glyphicons.video-off:before {
  content: '\ebdc';
}
/* '' */
.glyphicons.video-play:before {
  content: '\ebdd';
}
/* '' */
.glyphicons.video-play-empty:before {
  content: '\ebde';
}
/* '' */
.glyphicons.view-full:before {
  content: '\ebdf';
}
/* '' */
.glyphicons.view-half:before {
  content: '\ebe0';
}
/* '' */
.glyphicons.vignetting:before {
  content: '\ebe1';
}
/* '' */
.glyphicons.virgin-female:before {
  content: '\ebe2';
}
/* '' */
.glyphicons.voicemail:before {
  content: '\ebe3';
}
/* '' */
.glyphicons.voice-scan:before {
  content: '\ebe4';
}
/* '' */
.glyphicons.volleyball:before {
  content: '\ebe5';
}
/* '' */
.glyphicons.volume-bluetooth:before,
.glyphicons.bluetooth:before {
  content: '\ebe6';
}
/* '' */
.glyphicons.volume-down:before {
  content: '\ebe7';
}
/* '' */
.glyphicons.volume-max:before {
  content: '\ebe8';
}
/* '' */
.glyphicons.volume-off:before,
.glyphicons.mute:before {
  content: '\ebe9';
}
/* '' */
.glyphicons.volume-up:before {
  content: '\ebea';
}
/* '' */
.glyphicons.voting-urn:before {
  content: '\ebeb';
}
/* '' */
.glyphicons.vr:before {
  content: '\ebec';
}
/* '' */
.glyphicons.vr-inside:before {
  content: '\ebed';
}
/* '' */
.glyphicons.wallet:before {
  content: '\ebee';
}
/* '' */
.glyphicons.wallet-check:before {
  content: '\ebef';
}
/* '' */
.glyphicons.wallet-cogwheel:before {
  content: '\ebf0';
}
/* '' */
.glyphicons.wallet-lock:before {
  content: '\ebf1';
}
/* '' */
.glyphicons.wallet-minus:before {
  content: '\ebf2';
}
/* '' */
.glyphicons.wallet-plus:before {
  content: '\ebf3';
}
/* '' */
.glyphicons.warehouse:before {
  content: '\ebf4';
}
/* '' */
.glyphicons.washing-machine:before {
  content: '\ebf5';
}
/* '' */
.glyphicons.waste-pipe:before {
  content: '\ebf6';
}
/* '' */
.glyphicons.waste-pipe-end:before {
  content: '\ebf7';
}
/* '' */
.glyphicons.watch:before {
  content: '\ebf8';
}
/* '' */
.glyphicons.watch-squared:before {
  content: '\ebf9';
}
/* '' */
.glyphicons.water:before {
  content: '\ebfa';
}
/* '' */
.glyphicons.webcam:before {
  content: '\ebfb';
}
/* '' */
.glyphicons.wheat:before {
  content: '\ebfc';
}
/* '' */
.glyphicons.wifi:before,
.glyphicons.wifi-alt:before {
  content: '\ebfd';
}
/* '' */
.glyphicons.wind:before {
  content: '\ebfe';
}
/* '' */
.glyphicons.winners:before {
  content: '\ebff';
}
/* '' */
.glyphicons.winners-stars:before {
  content: '\ec00';
}
/* '' */
.glyphicons.wireless-charging:before {
  content: '\ec01';
}
/* '' */
.glyphicons.world-east:before,
.glyphicons.globe-af:before {
  content: '\ec02';
}
/* '' */
.glyphicons.world-west:before {
  content: '\ec03';
}
/* '' */
.glyphicons.wrench:before {
  content: '\ec04';
}
/* '' */
.glyphicons.yen:before {
  content: '\ec05';
}
/* '' */
.glyphicons.folder-heart:before {
  content: '\ec06';
}
/* '' */
/* Orphan entries due to update to 2.0 */
.glyphicons.activity:before,
.glyphicons.bathrobe:before,
.glyphicons.commodities:before,
.glyphicons.compressed:before,
.glyphicons.copyright-mark:before,
.glyphicons.currency-conversion:before,
.glyphicons.delete:before,
.glyphicons.electrical-socket-eu:before,
.glyphicons.electrical-socket-us:before,
.glyphicons.embed:before,
.glyphicons.eye-minus:before,
.glyphicons.eye-plus:before,
.glyphicons.floppy-disk:before,
.glyphicons.floppy-open:before,
.glyphicons.floppy-remove:before,
.glyphicons.floppy-save:before,
.glyphicons.floppy-saved:before,
.glyphicons.git-branch:before,
.glyphicons.git-commit:before,
.glyphicons.git-compare:before,
.glyphicons.git-create:before,
.glyphicons.git-delete:before,
.glyphicons.git-private:before,
.glyphicons.git-pull-request:before,
.glyphicons.glasses:before,
.glyphicons.iphone-exchange:before,
.glyphicons.iphone-transfer:before,
.glyphicons.ipod:before,
.glyphicons.ipod-shuffle:before,
.glyphicons.life-preserver:before,
.glyphicons.mms:before,
.glyphicons.move:before,
.glyphicons.nails:before,
.glyphicons.none-color-swatch:before,
.glyphicons.person:before,
.glyphicons.person-running:before,
.glyphicons.person-walking:before,
.glyphicons.person-wheelchair:before,
.glyphicons.piano:before,
.glyphicons.pin:before,
.glyphicons.pin-flag:before,
.glyphicons.pipe:before,
.glyphicons.podium:before,
.glyphicons.quote:before,
.glyphicons.registration-mark:before,
.glyphicons.resize-full:before,
.glyphicons.resize-small:before,
.glyphicons.riflescope:before,
.glyphicons.ring:before,
.glyphicons.rotation-lock:before,
.glyphicons.screenshot:before,
.glyphicons.sheriffs-star:before,
.glyphicons.skateboard:before,
.glyphicons.smoking:before,
.glyphicons.sms:before,
.glyphicons.sound-5-1:before,
.glyphicons.sound-6-1:before,
.glyphicons.sound-7-1:before,
.glyphicons.sound-stereo:before,
.glyphicons.sound-dolby:before,
.glyphicons.stethoscope:before,
.glyphicons.stop-sign:before,
.glyphicons.sunglasses:before,
.glyphicons.sweater:before,
.glyphicons.temple-buddhist:before,
.glyphicons.temple-christianity-church:before,
.glyphicons.temple-hindu:before,
.glyphicons.temple-islam:before,
.glyphicons.text-color:before,
.glyphicons.uk-rat-12:before,
.glyphicons.uk-rat-12a:before,
.glyphicons.uk-rat-15:before,
.glyphicons.uk-rat-18:before,
.glyphicons.uk-rat-pg:before,
.glyphicons.uk-rat-r18:before,
.glyphicons.uk-rat-u:before,
.glyphicons.underground:before,
.glyphicons.us-rat-g:before,
.glyphicons.us-rat-no-one-17:before,
.glyphicons.us-rat-pg:before,
.glyphicons.us-rat-pg-13:before,
.glyphicons.us-rat-restricted:before,
.glyphicons.zoom-in:before,
.glyphicons.zoom-out:before {
  content: '\e952';
}
/* '' */
