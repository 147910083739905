.custom-metric-editor__script-title {
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  height: 30px;
  line-height: 30px;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  flex-shrink: 0;
}
.custom-metric-editor__script-title .question-sign {
  margin-left: 5px;
  font-size: 20px;
  margin-top: 4px;
}
.custom-metric-editor__buffer {
  flex-grow: 1;
}
