.state-widget {
  display: flex;
  flex-direction: column;
  min-height: 137px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  background-color: #fbfbfb;
}
.state-widget_highlight {
  border-width: 2px;
}
.state-widget_highlight-critical {
  border-color: var(--ceeview-severity-critical);
}
.state-widget_highlight-major {
  border-color: var(--ceeview-severity-major);
}
.state-widget_highlight-minor {
  border-color: var(--ceeview-severity-minor);
}
.state-widget_highlight-none {
  border-color: var(--ceeview-severity-none);
}
.state-widget_highlight-invalid {
  border-color: var(--ceeview-severity-invalid);
}
.state-widget__text-block {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}
.state-widget__main-value {
  font-size: 32px;
  color: #333;
}
.state-widget__main-value_clickable {
  cursor: pointer;
}
.state-widget__sub-value {
  color: #999;
  text-align: center;
}
.state-widget__status-line {
  display: flex;
}
.state-widget__status-line-entry {
  height: 30px;
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  text-transform: uppercase;
}
.state-widget__status-line-entry_clickable {
  cursor: pointer;
}
.state-widget__status-line-entry_critical {
  background-color: var(--ceeview-severity-critical);
}
.state-widget__status-line-entry_major {
  background-color: var(--ceeview-severity-major);
}
.state-widget__status-line-entry_minor {
  background-color: var(--ceeview-severity-minor);
}
.state-widget__status-line-entry_none {
  background-color: var(--ceeview-severity-none);
}
.state-widget__status-line-entry_invalid {
  background-color: var(--ceeview-severity-invalid);
}
