.grid-view-manager {
  min-width: 150px;
}
.grid-view-manager__properties .toolbar__entry_title {
  max-width: 500px;
}
.grid-view-manager__columns {
  display: grid;
  padding: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
}
.grid-view-manager__views-list {
  width: 200px;
}
.grid-view-manager__column_hidden .ant-checkbox-wrapper {
  color: rgba(0, 0, 0, 0.5);
}
.grid-view-manager__grouping {
  max-width: 670px;
}
