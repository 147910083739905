.link-cell__content {
  width: 100%;
  gap: 8px;
  display: inline-flex;
  align-items: center;
}
.link-cell__link,
.link-cell__text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
a.link-cell__link:hover,
a.link-cell__text:hover {
  font-weight: normal;
}
.link-cell__popover-content {
  width: fit-content;
}
.link-cell__description {
  min-width: 230px;
  width: min-content;
}
.link-cell__popover-actions {
  text-align: right;
}
