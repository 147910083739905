.cloud-services-cost-bar-chart-widget__chart-container {
  flex: 1;
}
.cloud-services-cost-bar-chart-widget .c-link_clickable {
  position: absolute;
  bottom: 0;
}
.cloud-services-cost-bar-chart-config .form-entry__field .ant-checkbox-wrapper {
  flex-basis: 33%;
}
.cost-bar-chart-configuration__checkboxes {
  display: flex;
  flex-direction: row;
}
.cost-bar-chart-configuration__checkboxes-column {
  display: flex;
  flex-direction: column;
}
.cost-bar-chart-configuration__checkboxes-column .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
  margin-top: 5px;
}
