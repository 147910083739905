.ant-message.ant-message-top {
  position: absolute;
  z-index: 999999;
}
.ant-message.ant-message-top .ant-message-notice {
  padding: 8px 15px;
}
.ant-message.ant-message-top .ant-message-notice-content {
  padding: 11px 15px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-message.ant-message-top .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  align-items: flex-start;
}
