.cost-profile-widget {
  overflow: auto;
  height: 100%;
}
.cost-profile-widget_no-background .ant-table {
  background: transparent;
}
.cost-profile-widget-configuration__display-settings,
.cost-profile-widget-configuration__columns-editor {
  display: grid;
  grid-row-gap: 10px;
}
.cost-profile-widget-configuration__display-settings .ant-checkbox-wrapper + .ant-checkbox-wrapper,
.cost-profile-widget-configuration__columns-editor .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.cost-profile-widget-configuration__display-settings {
  grid-auto-flow: row dense;
  grid-template-columns: repeat(2, 1fr);
}
.cost-profile-widget-configuration__columns-editor {
  grid-auto-flow: column dense;
  grid-template-rows: repeat(4, 1fr);
}
