.highcharts-column-series.highcharts-data-labels.highcharts-tracker[visibility="hidden"],
.highcharts-column-series.highcharts-series.highcharts-tracker[visibility="hidden"] {
  display: none;
}
.highcharts-drilldown-axis-label {
  color: black !important;
  fill: black !important;
  text-decoration: none !important;
  font-weight: normal !important;
  cursor: default !important;
  pointer-events: none !important;
}
