.ceeview-grid {
  --ceeview-grid-default-row-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: white;
  min-width: 0;
  min-height: 0;
}
.ceeview-grid_transparent {
  background-color: transparent;
}
.ceeview-grid_fit {
  flex: 1;
}
.ceeview-grid__wrapper {
  flex: 1;
  display: flex;
  min-height: 0;
}
.ceeview-grid__row-group {
  background-color: #eeeeee;
  display: flex;
  flex-direction: row;
  height: var(--ceeview-grid-default-row-size);
  min-height: var(--ceeview-grid-default-row-size);
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid white;
  cursor: pointer;
}
.ceeview-grid__row-group:hover {
  background-color: #e9e9e9;
}
.ceeview-grid__row-group-field-title {
  font-weight: bold;
}
.ceeview-grid__row-group-field-value {
  margin-left: 10px;
}
.ceeview-grid__row-entry,
.ceeview-grid__row-loading {
  display: flex;
  flex-direction: row;
  height: var(--ceeview-grid-default-row-size);
  min-height: var(--ceeview-grid-default-row-size);
  transition: background 0.2s;
}
.ceeview-grid__row-entry_free-size,
.ceeview-grid__row-loading_free-size {
  height: auto;
}
.ceeview-grid__row-entry_free-size .ceeview-grid__cell_nowrap,
.ceeview-grid__row-loading_free-size .ceeview-grid__cell_nowrap {
  white-space: initial;
}
.ceeview-grid__row-entry_free-size .ceeview-grid__cell_nowrap > a,
.ceeview-grid__row-loading_free-size .ceeview-grid__cell_nowrap > a,
.ceeview-grid__row-entry_free-size .ceeview-grid__cell_nowrap > span,
.ceeview-grid__row-loading_free-size .ceeview-grid__cell_nowrap > span {
  overflow: visible;
  text-overflow: initial;
  word-wrap: break-word;
  width: 100%;
}
.ceeview-grid__row-entry_free-size .ceeview-grid__cell_align_right.ceeview-grid__cell_nowrap > span,
.ceeview-grid__row-loading_free-size .ceeview-grid__cell_align_right.ceeview-grid__cell_nowrap > span {
  text-align: right;
}
.ceeview-grid__row-entry:hover,
.ceeview-grid__row-loading:hover {
  background-color: #fafafa;
}
.ceeview-grid__row-entry_selected,
.ceeview-grid__row-loading_selected {
  background-color: #eaeaea;
}
.ceeview-grid__cell {
  overflow: hidden;
  flex: 0 0 auto;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 4px;
}
.ceeview-grid__cell_disabled,
.ceeview-grid__cell:has(.grid-editable-cell_disabled) {
  background-color: var(--ceeview-background-color-disabled);
}
.ceeview-grid__cell_nowrap {
  white-space: nowrap;
}
.ceeview-grid__cell_nowrap a,
.ceeview-grid__cell_nowrap span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ceeview-grid__cell_clickable {
  cursor: pointer;
}
.ceeview-grid__cell_id {
  padding: 0 8px;
}
.ceeview-grid__cell_fixed_left,
.ceeview-grid__cell_fixed_right {
  position: sticky;
  background-color: white;
}
.ceeview-grid__cell_align_right {
  justify-content: right;
}
.ceeview-grid__cell_align_center {
  justify-content: center;
}
.ceeview-grid__cell_align_left {
  justify-content: left;
}
.ceeview-grid__no-data-message {
  text-align: center;
  flex: 1;
  background-color: #f4f7f9;
  color: #267fb8;
  padding: 15px 0;
}
.ceeview-grid_view_light .ceeview-grid-header-cell {
  background-color: white;
  border: none;
}
.ceeview-grid_view_light .ceeview-grid-header-cell:before {
  background-color: transparent;
}
.ceeview-grid_view_light .ceeview-grid__cell {
  border-bottom: none;
  border-top: none;
}
