.ai-query-widget-chat__message-section {
  position: relative;
}
.ai-query-widget-chat__send-button {
  z-index: 2;
  position: absolute;
  right: 20px;
  top: 20px;
}
.ai-query-widget-chat__alert-message {
  margin-left: var(--ceeview-section-content-padding);
  margin-right: var(--ceeview-section-content-padding);
  margin-top: var(--ceeview-section-content-padding);
}
