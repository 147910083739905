.context-menu-component {
  position: absolute;
  z-index: 20000;
  min-width: 220px;
  border: 1px solid #ccc;
  border-radius: 0 3px 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}
.context-menu-component div {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 5px;
  line-height: 30px;
  border-bottom: 1px solid #ddd;
  list-style: none;
  background-color: #fff;
  border-radius: 0;
  cursor: pointer;
}
.context-menu-component div:hover {
  background-color: #cfe6f5;
}
.context-menu-component div .glyphicons {
  line-height: 30px;
  margin-right: 5px;
}
.create-team {
  background: initial;
  border: initial;
}
