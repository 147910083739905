.grid-editable-cell {
  display: flex;
  flex: 1 1 auto;
  cursor: pointer;
  height: 100%;
  align-items: center;
}
.grid-editable-cell_disabled {
  cursor: default;
}
.grid-editable-cell > .ant-select {
  width: 100%;
}
