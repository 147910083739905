.bars-states-widget {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
}
.bars-states-widget__bars {
  flex: 1 0 calc(100% - 24px);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 10px;
}
.bars-states-widget__bar {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}
.bars-states-widget__bar_empty {
  cursor: default;
}
.bars-states-widget__bar_empty .bars-states-widget__value {
  background: none;
  cursor: default;
  color: #555555;
  text-shadow: none;
}
.bars-states-widget__value {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  border-width: 1px 1px;
  border-style: solid;
  border-radius: 5px;
}
.bars-states-widget__value:hover {
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
}
.bars-states-widget__value_severity_critical {
  border-color: var(--ceeview-severity-critical-lighter);
  background: linear-gradient(var(--ceeview-severity-critical-lighter) 0%, var(--ceeview-severity-critical) 50%, var(--ceeview-severity-critical-darker) 100%);
}
.bars-states-widget__value_severity_major {
  border-color: var(--ceeview-severity-major-darker);
  background: linear-gradient(var(--ceeview-severity-major-lighter) 0%, var(--ceeview-severity-major) 50%, var(--ceeview-severity-major-darker) 100%);
}
.bars-states-widget__value_severity_minor {
  border-color: var(--ceeview-severity-minor-darker);
  background: linear-gradient(var(--ceeview-severity-minor-lighter) 0%, var(--ceeview-severity-minor) 50%, var(--ceeview-severity-minor-darker) 100%);
}
.bars-states-widget__value_severity_none {
  border-color: var(--ceeview-severity-none-darker);
  background: linear-gradient(var(--ceeview-severity-none-lighter) 0%, var(--ceeview-severity-none) 50%, var(--ceeview-severity-none-darker) 100%);
}
.bars-states-widget__value_severity_invalid {
  border-color: var(--ceeview-severity-invalid-darker);
  background: linear-gradient(var(--ceeview-severity-invalid-lighter) 0%, var(--ceeview-severity-invalid) 50%, var(--ceeview-severity-invalid-darker) 100%);
}
.bars-states-widget__label {
  flex: 0 0 20px;
  margin-top: 5px;
  color: #888888;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
}
.bars-states-widget__label-small {
  flex: 0 0 20px;
  margin-top: 5px;
  color: #888888;
  font-size: 9px;
  text-transform: uppercase;
  text-align: center;
}
.bars-states-widget__warning-icon {
  position: absolute;
  right: 3px;
  top: 3px;
  opacity: 0.9;
  height: 16px;
  width: 16px;
  background: url("~styles/shared/images/app_theme//cw_exclamation_white_circle.png") no-repeat center center;
  background-size: cover;
  border-radius: 20px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3);
}
.bars-states-widget__progress-container {
  flex: 1 1;
  padding: 2px;
  margin: 1px 0;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  min-height: 24px;
}
.bars-states-widget__progress-bar {
  min-height: 18px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), transparent);
  cursor: pointer;
}
.bars-states-widget__progress-bar:hover {
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
}
.bars-states-widget__progress-bar:first-child {
  border-radius: 20px 0 0 20px;
}
.bars-states-widget__progress-bar:last-child {
  border-radius: 0 20px 20px 0;
}
.bars-states-widget__progress-bar:only-child {
  border-radius: 20px;
}
.bars-states-widget__progress-bar_severity_critical {
  background-color: var(--ceeview-severity-critical);
}
.bars-states-widget__progress-bar_severity_major {
  background-color: var(--ceeview-severity-major);
}
.bars-states-widget__progress-bar_severity_minor {
  background-color: var(--ceeview-severity-minor);
}
.bars-states-widget__progress-bar_severity_none {
  background-color: var(--ceeview-severity-none);
}
.bars-states-widget__progress-bar_severity_invalid {
  background-color: var(--ceeview-severity-invalid);
}
