.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.6;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay > .wrapper1 {
  max-width: 50%;
}
.overlay_hidden {
  display: none;
}
.overlay__message {
  margin-bottom: 10px;
}
.overlay__spinner {
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #00aeef #00aeef #fff;
  border-radius: 15px;
  margin: 0 auto;
  -webkit-animation: spin 0.5s infinite;
  -moz-animation: spin 0.5s infinite;
  -o-animation: spin 0.5s infinite;
  animation: spin 0.5s infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
